import {useSelector} from 'react-redux'
import {RootState} from '@app/store'
import {Modal, Spin} from 'antd'
import styles from './index.module.scss'

const MainLoader = (): JSX.Element => {
  const loading = useSelector((state: RootState) => state.share.loading)
  return (
    <Modal
      visible={typeof loading === 'string' ? loading.length > 0 : loading}
      zIndex={2000}
      footer={null}
      closeIcon={() => null}
      closable={false}
      maskStyle={{backgroundColor: 'transparent'}}
      centered={true}
      className={styles.root}
      getContainer={() => document.getElementById('__next')}
    >
      <div className={'inline-flex p-4 bg-[#010000]/[0.85] rounded-lg'}>
        <Spin tip={typeof loading === 'string' ? loading : 'Loading...'}/>
      </div>
    </Modal>
  )
}

export default MainLoader
