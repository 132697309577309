import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@app/store";
import AccountDTO from "@features/share/models/api/AccountDTO";

interface profileState {
  profile: AccountDTO,
}

const initialState: profileState = {
  profile: null,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state: profileState, action: PayloadAction<AccountDTO>){
      state.profile = action.payload
    },
  }
})

export function getProfile(state: RootState){
  return state.profile.profile
}

export const { setProfile } = profileSlice.actions

export const selectProfile = (state: RootState) => state.profile.profile

const profileReducer = profileSlice.reducer

export default profileReducer