import moment from "moment";
import {
  BEFORE_1_DAY,
  BEFORE_30_DAYS,
  BEFORE_6_DAYS,
  DateValues,
  DATE_FORMAT_DEFAULT,
} from "../configs/components/datetime-picker-advanced";

interface ResponseGetDateRange {
  startDate: string;
  endDate: string;
}

export const getDateRange = (type: DateValues): ResponseGetDateRange => {
  switch (type) {
    case "today":
      return {
        startDate: moment().format(DATE_FORMAT_DEFAULT),
        endDate: moment().format(DATE_FORMAT_DEFAULT),
      };
    case "yesterday":
      return {
        startDate: moment()
          .subtract(BEFORE_1_DAY, "day")
          .format(DATE_FORMAT_DEFAULT),
        endDate: moment()
          .subtract(BEFORE_1_DAY, "day")
          .format(DATE_FORMAT_DEFAULT),
      };
    case "this_week":
      return {
        startDate: moment()
          .startOf("week")
          .add(BEFORE_1_DAY, "day")
          .format(DATE_FORMAT_DEFAULT),
        endDate: moment().format(DATE_FORMAT_DEFAULT),
      };
    case "last_week":
      return {
        startDate: moment()
          .subtract(BEFORE_1_DAY, "week")
          .startOf("isoWeek")
          .format(DATE_FORMAT_DEFAULT),
        endDate: moment().startOf("week").format(DATE_FORMAT_DEFAULT),
      };
    case "last_7_day":
      return {
        startDate: moment()
          .subtract(BEFORE_6_DAYS, "days")
          .format(DATE_FORMAT_DEFAULT),
        endDate: moment().format(DATE_FORMAT_DEFAULT),
      };
    case "last_30_day":
      return {
        startDate: moment()
          .subtract(BEFORE_30_DAYS, "days")
          .format(DATE_FORMAT_DEFAULT),
        endDate: moment().format(DATE_FORMAT_DEFAULT),
      };
    case "this_month":
      return {
        startDate: moment().startOf("month").format(DATE_FORMAT_DEFAULT),
        endDate: moment().format(DATE_FORMAT_DEFAULT),
      };
    default:
      return {
        startDate: moment().format(DATE_FORMAT_DEFAULT),
        endDate: moment().format(DATE_FORMAT_DEFAULT),
      };
  }
};
