import { bindActionCreators, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import BreadCrumbInterFace from "@features/share/models/BreadCrumbInterFace";

type TitleActionPayload = {
  text: string,
  useBackButton: boolean,
  urlBack?: string,
  useBreadcrumb?: boolean,
  showIntroShoutout?: boolean
}

type ShareStateType = {
  title: TitleActionPayload,
  sidebarOpen: boolean,
  quickActionOpen: boolean
  loading: boolean | string
  locale: string,
  breadcrumbs: BreadCrumbInterFace[],
  jumpingToLdp: boolean,
  formDirty: boolean
}

const initialState: ShareStateType = {
  title: { text: '', useBackButton: false, urlBack: null, useBreadcrumb: false, showIntroShoutout: false },
  sidebarOpen: false,
  quickActionOpen: false,
  loading: false,
  locale: 'en',
  breadcrumbs: [],
  jumpingToLdp: false,
  formDirty: false
}

const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    setBreadcrumbs: (state: Draft<ShareStateType>, action: PayloadAction<BreadCrumbInterFace[]>) => {
      state.breadcrumbs = action.payload
    },
    setTitle: (state: Draft<ShareStateType>, action: PayloadAction<TitleActionPayload>) => {
      state.title = action.payload
    },
    setSidebarOpen: (state: Draft<ShareStateType>, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload
    },
    setQuickActionOpen: (state: Draft<ShareStateType>, action: PayloadAction<boolean>) => {
      state.quickActionOpen = action.payload
    },
    setLoading: (state: Draft<ShareStateType>, action: PayloadAction<boolean | string>) => {
      state.loading = action.payload
    },
    setLocale: (state: Draft<ShareStateType>, action: PayloadAction<string>) => {
      state.locale = action.payload
    },
    setStatusJumpToLandingPage: (state: Draft<ShareStateType>, action: PayloadAction<boolean>) => {
      state.jumpingToLdp = action.payload
    },
    setFormDirty: (state: Draft<ShareStateType>, action: PayloadAction<boolean>) => {
      state.formDirty = action.payload
    }
  }
})

const shareReducer = shareSlice.reducer

export const shareActions = shareSlice.actions

export const useShareActions = () => {
  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(shareActions, dispatch), [dispatch])
}

export default shareReducer

