import '../styles/globals.css'
import '../styles/ant.less'
import '../styles/antd-custom.css'
import '../styles/ext-lib.scss'
import 'react-calendar/dist/Calendar.css';
import { Provider } from 'react-redux'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import store from '../app/store'
import React from "react"
import MainLoader from '@shares/MainLoader'
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import i18n from "@app/i18n";
import SwiperCore, { Autoplay } from 'swiper';
i18n.changeLanguage('en').then()
SwiperCore.use([Autoplay]);

const queryClient = new QueryClient()

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Provider store={store}>
          <Head>
            <title />
            <meta
              key={'meta_viewport'}
              name="viewport"
              content="minimum-scale=1, initial-scale=1, user-scalable=0, width=device-width, shrink-to-fit=no, maximum-scale=1.0"
            />
          </Head>
          <Component {...pageProps} />
          <MainLoader />
        </Provider>
      </Hydrate>
    </QueryClientProvider>
  )
}
