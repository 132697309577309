import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@app/store";

interface shoutoutState {
  loadingShoutout: boolean,
  from: string
}

const initialState: shoutoutState = {
    loadingShoutout: false,
    from: ''
}

const shoutoutSlice = createSlice({
  name: 'shoutout',
  initialState,
  reducers: {
    setLoadingShoutout(state: shoutoutState, action: PayloadAction<boolean>){
      state.loadingShoutout = action.payload
    },
    setFrom(state: shoutoutState, action: PayloadAction<string>){
      state.from = action.payload
    }
  }
})

export function getLoadingShoutout(state: RootState){
  return state.shoutout.loadingShoutout
}

export const { setLoadingShoutout, setFrom } = shoutoutSlice.actions

export const selectShoutout = (state: RootState) => state.shoutout.loadingShoutout

export const selectFrom = (state: RootState) => state.shoutout.from

const shoutoutReducer = shoutoutSlice.reducer

export default shoutoutReducer