const  vi =  {
 translation: {
  "6_40_characters_are_allowed": "Vui lòng nhập từ 6 đến 40 kí tự",
  "The_interval_must_be_less_than_days": "The interval must be less than {{max}} days",
  "accepted": "Đã đạt",
  "action_has_not_been_performed": "Thao tác này chưa được thực hiện. Vui lòng thử lại",
  "add": "Thêm",
  "add_channel": "Thêm kênh",
  "add_cover_image": "Thêm ảnh bìa",
  "add_image": "Thêm ảnh {{n}}",
  "add_new_product": "Tạo sản phẩm",
  "add_product": "Thêm sản phẩm",
  "add_store_new_section_group": "Thêm mục mới",
  "atc_config": "Config",
  "atc_confirm": "Confirm",
  "atc_pause": "Pause",
  "atc_start": "Start",
  "atc_title": "Set up auto crawl",
  "campaign_detail_page_breadcumb": "Chi tiết",
  "campaign_drawer_base_commission": "Mức hoa hồng có thể nhận được, khi khách hàng truy cập vào link tiếp thị liên kết của bạn. Sau đó hoàn tất thanh toán bất kỳ sản phẩm nào trên sàn/ website thương mại điện tử đó.",
  "campaign_drawer_brand_commission": "Mức hoa hồng có thể nhận được, khi khách hàng truy cập vào link tiếp thị liên kết của bạn. Sau đó hoàn tất thanh toán bất kỳ sản phẩm nào tại gian hàng của Thương hiệu đó trên sàn thương mại điện tử.",
  "campaign_drawer_exclusive_commission": "Mức hoa hồng có thể nhận được, khi khách hàng truy cập vào link tiếp thị liên kết của bạn. Sau đó hoàn tất thanh toán bất kỳ sản phẩm nào tại gian hàng của Thương hiệu đó trên sàn thương mại điện tử. (Độc quyền từ Ecomobi)",
  "campaign_ecommerce_campaign": "Chiến dịch sàn TMĐT",
  "campaign_ecommerce_campaign_breadcumb": "Chiến dịch sàn TMĐT",
  "campaign_ecommerce_campaign_tooltip": "Chiến dịch tiếp thị liên kết tới các sàn thương mại điện tử (Shopee/ Lazada/...)",
  "campaign_empty_campaign_content": "Nhanh tay 👉 \"Xem tất cả\" để khám phá nào 🙌 🙌 🙌",
  "campaign_empty_campaign_title": "Tất cả chiến dịch hấp dẫn đang chờ bạn!",
  "campaign_exclusive_commission": "Hoa hồng độc quyền",
  "campaign_filter": "Bộ lọc",
  "campaign_filter_Indonesia": "Indonesia",
  "campaign_filter_Lazada": "Lazada",
  "campaign_filter_Malaysia": "Malaysia",
  "campaign_filter_Philippines": "Philippines",
  "campaign_filter_Shopee": "Shopee",
  "campaign_filter_Singapore": "Singapore",
  "campaign_filter_Thailand": "Thái Lan",
  "campaign_filter_Vietnam": "Việt Nam",
  "campaign_filter_find_campaign": "Tìm kiếm chiến dịch...",
  "campaign_filter_label.promotion_kit_all": "Tất cả",
  "campaign_filter_label.promotion_kit_cash": "Tiền thưởng",
  "campaign_filter_label.promotion_kit_cpc": "Thưởng click",
  "campaign_filter_label.promotion_kit_product": "Sản phẩm mẫu",
  "campaign_filter_label.promotion_kit_voucher": "Mã giảm giá",
  "campaign_filter_text": "Bộ lọc:",
  "campaign_for_membership": "Dành cho thành viên",
  "campaign_for_membership_tooltip": "Chiến dịch tiếp thị dành riêng cho các Creators đã đăng ký gói Pro",
  "campaign_gift": "Gói ưu đãi",
  "campaign_gift_exclusive_click": "Thưởng click độc quyền",
  "campaign_giif_commission": "Điều kiện nhận gói ưu đãi",
  "campaign_has_not_config_setting_onair_rules": "Liên kết không hợp lệ",
  "campaign_inventory_number": "Còn {{number}} suất",
  "campaign_list": "Danh sách chiến dịch",
  "campaign_max_address_input": "Vui lòng chỉ nhập tối đa {{word}} ký tự",
  "campaign_max_character": "Tối đa nhập {{word}} ký tự",
  "campaign_max_phone_input": "Số điện thoại không hợp lệ",
  "campaign_monopoly": "Độc quyền",
  "campaign_no_campaign": "Chưa có chiến dịch phù hợp 🤗",
  "campaign_not_exist": "Chiến dịch này không tồn tại",
  "campaign_noti_is_pending_campaign": "Yêu cầu tham gia chiến dịch của bạn đang chờ duyệt",
  "campaign_number_per_unique_click": "{{number}} {{currency}} / Unique click",
  "campaign_other": "Khác",
  "campaign_policy": "Điều khoản",
  "campaign_promotion": "Gói ưu đãi",
  "campaign_promotion_kit_field_required": "Vui lòng nhập thông tin này",
  "campaign_promotion_kit_max_selected": "Chọn tối đa {amount} ưu đãi",
  "campaign_promotion_kit_notification": "Bạn chưa đủ điều kiện nhận gói ưu đãi của chiến dịch này. Vui lòng kiểm tra lại điều kiện, nếu cần hỗ trợ liên hệ tới bộ phận chăm sóc khách hàng của Ecomobi.",
  "campaign_promotion_kit_selected": "Đã chọn",
  "campaign_receive_gift_from_brand": "Chọn quà tặng từ thương hiệu",
  "campaign_receive_info_sample_product": "Nhập thông tin nhận sản phẩm mẫu 👇👇",
  "campaign_redirect_link_shop": "Xem thương hiệu",
  "campaign_request_offer_pending": "Chờ duyệt",
  "campaign_request_offer_reject": "Đăng ký chiến dịch",
  "campaign_request_offer_success": "Genlink",
  "campaign_request_policy_first": "Vui lòng đọc kỹ thông tin trước khi hoàn thiện bản đăng ký nhận ưu đãi từ Nhãn hàng. <br>(Bạn không thể tiếp tục thao tác nếu như không đồng ý với những quy định dưới đây):<br/><br/>",
  "campaign_request_policy_second": "1. Số lượng ưu đãi cho chiến dịch này có hạn, vui lòng đọc kỹ yêu cầu chiến dịch và đăng ký để có cơ hội nhận ưu đãi từ phía Nhãn hàng.<br/>2. Bạn cần lên đủ số lượng bài trong thời gian đã cam kết và gửi nghiệm thu qua hệ thống để hoàn tất chiến dịch.<br/>3. Với ưu đãi CPC, bạn cần gắn đúng link sản phẩm yêu cầu đã tạo qua hệ thống Ecomobi khi lên bài. Đối với KOL kênh Tiktok cần đảm bảo đã gắn đúng link sản phẩm đã tạo qua hệ thống Ecomobi vào link bio hoặc Landing page của Ecomobi. Không xoá hoặc đặt chế độ riêng tư cho bài đăng chiến dịch trong vòng 03 tháng (trừ phi có lý do phù hợp và thông báo trước cho đội ngũ quản lý KOL/Publisher về trường hợp của bạn).<br/>4. Nếu KOL không hoàn tất đúng và đủ các yêu cầu trước thời hạn cam kết, KOL có trách nhiệm phải thanh toán TOÀN BỘ phí phát sinh, bao gồm giá trị sản phẩm/voucher, phí lên bài, phí vận chuyển và các phí phát sinh khác (nếu có).<br/>5. Bài đăng của bạn có thể được sử dụng cho mục đích quảng bá tuỳ thuộc vào yêu cầu của nhãn hàng, Nếu phát sinh phí sử dụng hình ảnh, vui lòng ghi rõ.<br/>6. Không bán lại sản phẩm/voucher, chuyển nhượng hoặc huỷ đăng ký trong thời gian diễn ra chiến dịch.",
  "campaign_request_promotion_kit": "Gửi yêu cầu",
  "campaign_reset": "Đặt lại",
  "campaign_sample_product": "Sản phẩm mẫu",
  "campaign_search_no_campaign": "Không tìm thấy kết quả nào",
  "campaign_send_quest_confirm_button": "Xác nhận",
  "campaign_send_request_confirm": "Tôi đã đọc kỹ toàn bộ thông tin trên. Tôi xin xác nhận đăng ký nhận sản phẩm mẫu, và cung cấp thông tin như cam kết.",
  "campaign_send_request_policy": "Vui lòng đọc kỹ thông tin trước khi hoàn thiện bản đăng ký nhận ưu đãi từ Nhãn hàng. <br>(Bạn không thể tiếp tục thao tác nếu như không đồng ý với những quy định dưới đây):<br/><br/> 1. Số lượng ưu đãi cho chiến dịch này có hạn, vui lòng đọc kỹ yêu cầu chiến dịch và đăng ký để có cơ hội nhận ưu đãi từ phía Nhãn hàng.<br/>2. Bạn cần lên đủ số lượng bài trong thời gian đã cam kết và gửi nghiệm thu qua hệ thống để hoàn tất chiến dịch.<br/>3. Với ưu đãi CPC, bạn cần gắn đúng link sản phẩm yêu cầu đã tạo qua hệ thống Ecomobi khi lên bài. Đối với KOL kênh Tiktok cần đảm bảo đã gắn đúng link sản phẩm đã tạo qua hệ thống Ecomobi vào link bio hoặc Landing page của Ecomobi. Không xoá hoặc đặt chế độ riêng tư cho bài đăng chiến dịch trong vòng 03 tháng (trừ phi có lý do phù hợp và thông báo trước cho đội ngũ quản lý KOL/Publisher về trường hợp của bạn).<br/>4. Nếu KOL không hoàn tất đúng và đủ các yêu cầu trước thời hạn cam kết, KOL có trách nhiệm phải thanh toán TOÀN BỘ phí phát sinh, bao gồm giá trị sản phẩm/voucher, phí lên bài, phí vận chuyển và các phí phát sinh khác (nếu có).<br/>5. Bài đăng của bạn có thể được sử dụng cho mục đích quảng",
  "campaign_shop_campaign": "Chiến dịch Shop",
  "campaign_shop_campaign_breadcumb": "Shop Campaign",
  "campaign_shop_campaign_max_input": "Tối đa {{number}} unique click",
  "campaign_shop_campaign_tooltip": "Chiến dịch tiếp thị liên kết tới gian hàng của các Thương hiệu trên sàn thương mại điện tử (Shop trên Shopee/ Lazada/...)",
  "campaign_showing": "Đang hiển thị {{current}} / {{total}}",
  "campaign_sold_out": "Đã hết",
  "campaign_time_active": "{{start_date}} - {{end_date}}",
  "campaign_toastr_failed_promotion_kit": "Rất tiếc! Các ưu đãi bạn yêu cầu đã hết suất",
  "campaign_toastr_success_promotion_kit": "Yêu cầu đã gửi đến nhãn hàng để chờ duyệt",
  "campaign_toastr_warning_promotion_kit": "{{successItem}}/{{all}} ưu đãi đã gửi đến nhãn hàng để chờ duyệt",
  "campaign_total_campaign_show": "Có {{number}} chiến dịch được tìm thấy",
  "campaign_unlimited_campaign": "Vô thời hạn",
  "campaign_upcoming": "Sắp diễn ra",
  "campaign_upto": "lên tới",
  "campaign_upto_item_campaign": "Lên tới",
  "campaign_upto_tooltip": "upto {{commission}}",
  "campaign_validate_xss_message": "Thông tin nhập không hợp lệ",
  "campaign_view_all_list": "Xem tất cả",
  "campaign_web_campaign_breadcumb": "Chiến dịch Website",
  "campaign_website_campaign": "Chiến dịch Website",
  "campaign_website_campaign_tooltip": "Chiến dịch tiếp thị liên kết tới website của các Thương hiệu.",
  "cancel": "Huỷ",
  "cancel_create_product": "Bạn có muốn hủy thêm mới sản phẩm ?",
  "cancel_edit_product": "Bạn có muốn hủy bỏ thao tác đã chỉnh sửa?",
  "cannot_read_content_from_clipboard": "Không thể đọc nội dung từ clipboard",
  "channel_follower": "Lượt theo dõi",
  "channel_no_link": "Nền tảng không có kênh",
  "channel_not_enough_condition": "Nền tảng không đủ điều kiện",
  "check_condition_setting_traffic_source_is_invalid": "Liên kết không hợp lệ",
  "check_detail_report": "Xem báo cáo chi tiết",
  "check_onair_rule_traffic_source_is_invalid": "Liên kết không hợp lệ",
  "choose_a_form_to_post_experience": "Chọn hình thức lên bài nghiệm thu",
  "choose_a_form_to_post_experience_description": "Chọn kênh bạn sẽ lên bài nghiệm thu. Nếu kênh bạn cọn chưa có thông tin, vui lòng nhập thông tin kênh. Nếu kênh bạn chọn đã có dữ liệu, vui lòng bỏ qua.",
  "choose_another_channel": "Vui lòng thêm kênh hoặc chọn nền tảng khác",
  "choose_social": "Chọn kênh chính của bạn",
  "clicks": "Clicks",
  "commission": "Hoa hồng",
  "commission_passio": "Commission",
  "commission_tooltip": "Tổng tiền hoa hồng phát sinh từ các link tiếp thị liên kết của bạn",
  "commission_upto": "Hoa hồng lên tới",
  "confirm_cancel": "Changes you made may not be saved.",
  "confirm_new_password": "Xác nhận mật khẩu mới",
  "content_reject_reason": "Nội dung",
  "conversion_rate_brand_gift": "Tỷ lệ chuyển đổi",
  "conversion_rate_tooltip_brand_gift": "Tỷ lệ chuyển đổi được tính bằng Số đơn thành công trên tổng lượt xem màn Brand Gift",
  "copied": "Đã sao chép",
  "copy_link": "Copy link",
  "copy_link_fail": "Copy link thất bại",
  "copy_link_success": "Copy link thành công",
  "course_manager": "Quản lý học viên",
  "course_member": "Tiến độ học viên",
  "cover_image": "Ảnh bìa",
  "create_order_label": "Ngày tạo:",
  "create_product": "Tạo sản phẩm",
  "create_product_choose": "Chọn loại sản phẩm bạn muốn thêm",
  "create_product_successfull": "Đã tạo sản phẩm thành công",
  "create_voucher_title": "Tạo mã giảm giá",
  "delete_product": "Xoá sản phẩm",
  "delete_product_success": "Xóa sản phẩm thành công",
  "delete_product_title": "Bạn có muốn xóa sản phẩm không?",
  "delete_product_upload_title": "Bạn có muốn xóa dữ liệu sản phẩm không?",
  "digital_product": "Sản phẩm số",
  "digital_product_home": "Trang chủ",
  "digital_product_order_detail": "Chi tiết đơn hàng",
  "digital_product_order_report": "Đơn hàng sản phẩm số",
  "digital_product_overview": "Tổng quan",
  "digital_product_report": "Đơn hàng sản phẩm số",
  "digital_product_ss_payment_history": "Lịch sử thanh toán",
  "digital_product_total_income": "Tổng thu nhập",
  "digital_product_total_income_tooltip": "Toàn bộ khoản tiền (trước khi khấu trừ thuế nếu có) bạn sẽ nhận được sau khi lấy tổng doanh thu trừ đi tổng các khoản chi phí trong khoảng thời gian bạn đã chọn.",
  "digital_product_total_products_sold": "Tổng sản phẩm đã bán",
  "digital_products": "Sản phẩm số",
  "donated": "đã tặng một",
  "donation": "Ủng hộ",
  "done": "Xong",
  "dont_have_gift": "Không có quà tặng nào trong thời gian này",
  "dont_have_order": "Không có đơn hàng nào trong thời gian này",
  "down_to_bottom_label": "Xuống dưới cùng",
  "dp_add_product": "Tạo sản phẩm",
  "dp_all": "Tất cả",
  "dp_avantage_course_product": "Ưu điểm của sản phẩm Khóa học tại Passio:",
  "dp_buyer": "Người mua",
  "dp_cancel_delete": "Huỷ bỏ",
  "dp_cat_course": "Khóa học / Tutorial",
  "dp_cat_course_tutorial": "Khóa học / Tutorial",
  "dp_cat_exclusive_ebook": "Ebook/Tài liệu",
  "dp_cat_online_consultion": "Tư vấn online 1-1",
  "dp_cat_photo": "Ảnh",
  "dp_cat_random_product": "Sản phẩm bất kỳ",
  "dp_cat_ticket_account": "Vé/ Tài khoản",
  "dp_cat_video": "Video độc quyền",
  "dp_close_dialog": "Đóng",
  "dp_confirm_delete": "Xác nhận xoá",
  "dp_content_cant_download": "Tính năng này chỉ được hỗ trợ trên website",
  "dp_continue_editing": "Tiếp tục thêm sản phẩm",
  "dp_course_avantage_1": "Cấu hình một lần và sử dụng cho nhiều Fan",
  "dp_course_avantage_2": "Tạo bài giảng nhanh chóng",
  "dp_course_avantage_3": "Hạn chế download bài giảng",
  "dp_course_avantage_4": "Dễ dàng theo dõi đơn hàng, thu nhập",
  "dp_default_title_block": "Sản phẩm",
  "dp_delele_items": "Xóa sản phẩm",
  "dp_deleting_warning": "Bạn có chắc chắn muốn xóa sản phẩm này ?",
  "dp_edit_escape": "Thoát",
  "dp_edit_set_up": "Chỉnh sửa hiển thị",
  "dp_error_price": "Giá khuyến mại phải nhỏ hơn giá gốc",
  "dp_exit_deleting_warning": "Các thay đổi của bạn sẽ không được lưu. Bạn có chắc chắn muốn thoát không?",
  "dp_exit_editing": "Hủy thêm sản phẩm ?",
  "dp_explain_course_product": "Là giải pháp cho Creator, Fan có thể dạy và học online giúp tiết chi phí và tiết kiệm thời gian",
  "dp_home": "Trang chủ",
  "dp_income": "Thu nhập",
  "dp_income_tooltip": "Doanh thu bán hàng sản phẩm số",
  "dp_intro_content_1": "Tùy thuộc vào thế mạnh và mong muốn của bạn, Passio cung cấp đầy đủ các loại sản phẩm số như: Ảnh, Video, Khóa học online, Ebook, Sản phẩm dạng link,...",
  "dp_intro_content_2": "Chỉ với chưa đến 3 phút, bạn đã có thể cấu hình và đăng bán một sản phẩm số mới. Hơn nữa, sau khi người mua thanh thoán thành công, sản phẩm sẽ được gửi ngay tới email của họ, giúp đơn giản hóa việc vận chuyển và tiết kiệm thời gian",
  "dp_intro_content_title": "Sản phẩm số được hiểu là những sản phẩm trực tuyến, không cầm nắm được, chứa nội dung hữu ích cho người sử dụng. Ví dụ: Video, Hình ảnh, Ebook, Khóa học online,..",
  "dp_intro_title_1": "Đa dạng các loại sản phẩm cho bạn lựa chọn",
  "dp_intro_title_2": "Dễ dàng quản lý sản phẩm và đơn hàng bằng một vài thao tác",
  "dp_intruct_content_step_1": "Truy cập màn hình Sản phẩm của tôi và click \"Tạo sản phẩm\"",
  "dp_intruct_content_step_2": "Chọn loại sản phẩm số mà bạn muốn tạo",
  "dp_intruct_content_step_3": "Điền vào các thông tin của sản phẩm và click \"Lưu sản phẩm\"",
  "dp_intruct_content_step_4": "Để cấu hình khối Sản phẩm số, truy cập vào Homepage và ấn \"Thiết lập hiển thị\" / \"Cài đặt hiển thị\"",
  "dp_intruct_set_up": "Hướng dẫn thiết lập",
  "dp_learn_dp": "Tìm hiểu về Sản phẩm số",
  "dp_list_digital_product": "Danh sách",
  "dp_list_order": "Danh sách đơn hàng",
  "dp_list_product": "Danh sách",
  "dp_my_products": "Sản phẩm của tôi",
  "dp_order": "Đơn hàng",
  "dp_order_tooltip": "Đơn hàng sản phẩm số",
  "dp_original_price": "Giá gốc",
  "dp_overview": "Tổng quan",
  "dp_page_tittle_iframe": "Danh sách sản phẩm",
  "dp_page_tittle_setting_iframe": "Sản phẩm số",
  "dp_price_sale": "Giá khuyến mại",
  "dp_product": "Sản phẩm",
  "dp_product_review": "Xem trước sản phẩm",
  "dp_report": "Báo cáo",
  "dp_send_request_course_now": "Gửi yêu cầu ngay",
  "dp_send_request_course_success": "Gửi yêu cầu thành công",
  "dp_send_request_course_success_confirm": "Đóng",
  "dp_send_request_course_success_content": "Passio sẽ liên hệ bạn trong thời gian sớm nhất nếu yêu cầu được duyệt",
  "dp_set_up": "Thiết lập hiển thị",
  "dp_set_up_message": "Chọn vào nút bên dưới để chuyển sang Passio Page và cấu hình khối sản phẩm số",
  "dp_show_list_all_orders": "Danh sách đơn hàng",
  "dp_step_1": "Bước 1",
  "dp_step_2": "Bước 2",
  "dp_step_3": "Bước 3",
  "dp_step_4": "Bước 4",
  "dp_system_course_free": "Nếu bạn không nhập vào \"Giá bán\", \"Giá gốc khóa học\", hệ thống sẽ hiểu khóa học này là miễn phí",
  "dp_time_buy": "Thời gian đặt hàng",
  "dp_title_cant_download": "Không tải được tệp",
  "dp_title_request_course": "Giới thiệu sản phẩm Khóa học",
  "dp_waitlist_pending": "Chờ duyệt yêu cầu",
  "dp_what_digital_product": "Sản phẩm số là gì ?",
  "dp_what_is_the_course_product": "Sản phẩm Khóa học là gì ?",
  "ecm_advertiser_method": "Các hình thức quảng cáo",
  "ecm_commission_policy": "Chính sách hoa hồng",
  "ecm_cookie_policy": "Chính sách Cookies",
  "ecm_note": "Ghi chú quan trọng",
  "ecm_order_recording": "Cách ghi nhận đơn hàng",
  "eco_regards": "Trân trọng,",
  "ecomobi_generate_link_tool": "Ecomobi Generate Link tool",
  "ecomobi_team": "Đội ngũ Ecomobi",
  "edit_product": "Chỉnh sửa sản phẩm",
  "edit_voucher_title": "Chỉnh sửa mã giảm giá",
  "error": "Lỗi",
  "error_500": "Hệ thống đang quá tải, Quý khách vui lòng thử lại sau",
  "error_active_account_msg": "Oops! Có sự cố trong quá trình kích hoạt tài khoản. Bạn vui lòng liên hệ bộ phận hỗ trợ nhé",
  "error_backhome": "Quay lại trang chủ",
  "error_image_size": "Dung lượng ảnh vượt quá quy định cho phép",
  "error_incorrect_image_format": "Ảnh không đúng định dạng",
  "error_input_name_required": "Bạn nhập tên sản phẩm nhé",
  "error_input_price_required": "Bạn nhập giá sản phẩm nhé",
  "error_input_required": "Bạn chưa nhập thông tin",
  "error_invalid_redirect_url": "Vui lòng nhập đúng định dạng URL",
  "error_page_msg": "Không tìm thấy trang",
  "error_page_note_found": "Không tìm thấy trang",
  "error_pdf_size": "Dung lượng file ebook/pdf upload vượt quá quy định cho phép",
  "error_thumbnail_size": "Dung lượng ảnh vượt quá quy định cho phép",
  "error_thumbnail_size_dp": "Vui lòng tải lên file có kích thước tối đa 30MB",
  "error_video_size": "Dung lượng video vượt quá quy định cho phép",
  "error_zip_size": "Dung lượng zip file vượt quá quy định cho phép",
  "free_product": "Sản phẩm miễn phí",
  "go_to_top_label": "Lên trên cùng",
  "hide_product": "Ẩn sản phẩm",
  "hide_product_fail": "Ẩn sản phẩm thất bại",
  "hide_product_success": "Ẩn sản phẩm thành công",
  "history_link_appear": "Bạn có thể xem lịch sử liên kết của bạn ở đây",
  "home": "Home",
  "hot_campaigns": "Chiến dịch nổi bật",
  "image": "Ảnh {{n}}",
  "income": "Doanh thu",
  "income_label": "Doanh thu:",
  "incorrect_file_format": "Định dạng tệp không đúng",
  "input_select_all": "Tất cả",
  "instruc_on_virtual_gift": "👉🏻 Bật tính năng này, bạn sẽ cho phép fan gửi tặng quà đến bạn. Bạn có thể quy đổi các phần quà thành tiền. Bật tính năng ngay nhé!",
  "kol_edit_theme": "Sửa theme",
  "label.promotion_kit_all": "Tất cả",
  "label.promotion_kit_cash": "Tiền thưởng",
  "label.promotion_kit_cpc": "Thưởng click",
  "label.promotion_kit_product": "Sản phẩm mẫu",
  "label.promotion_kit_voucher": "Mã giảm giá",
  "label_special_kol": "Đặc biệt:",
  "last_note_condition": "*Trong trường hợp có tranh chấp, quyết định của Ecomobi là quyết định cuối cùng.",
  "link_channel": "Link nền tảng",
  "link_history": "Lịch sử liên kết",
  "link_refer_friends": "Link giới thiệu bạn bè:",
  "link_video": "Nhập link kênh của bạn",
  "link_youtube_invalid": "Link không hợp lệ",
  "link_youtube_note": "Nhập liên kết video nơi bạn dự định đặt các liên kết ngắn.",
  "list_service": "List Service",
  "make_money_with_passio": "Make money with Passio! Yayyy",
  "make_money_with_passio_content": "Tham gia các chiến dịch cùng Passio ngay để tạo thu nhập nhé!",
  "menu": "Menu",
  "message_from_seller": "Lời nhắn bạn đã gửi",
  "message_from_you": "Lời nhắn từ bạn",
  "message_from_you_placeholder": "Bạn hãy điền bất kỳ nội dung gì bạn muốn chỉ dành cho những người đặt hàng và thanh toán thành công ở đây (1 đường link bí mật dành riêng cho khách hàng, mật khẩu mở file, mật khẩu tham gia phòng Zoom,…). Và đừng quên cảm ơn những khách hàng đã mua sản phẩm, dịch vụ của bạn nhé! 😊",
  "move_down_label": "Xuống dưới",
  "move_to_label": "Di chuyển tới",
  "move_up_label": "Lên trên",
  "my_empty_products_content": "Thêm sản phẩm sáng tạo của bạn để tạo thu nhập cùng Ecomobi nhé!",
  "my_empty_products_title": "Bắt đầu xây dựng cửa hàng của bạn nào ! Yayyy",
  "my_page": "My Passio",
  "my_request": "Yêu cầu của tôi",
  "my_request_acceptance": "Nghiệm thu",
  "my_request_close": "Đóng",
  "my_request_delivered": "Đã nhận hàng",
  "my_request_delivery_confirm_note": "Tôi xác nhận tôi đã nhận sản phẩm mẫu. Sẽ không yêu cầu Trả hàng sau khi tôi xác nhận.",
  "my_request_error_file_size": "Kích thước ảnh tối đa 5 MB",
  "my_request_error_file_type": "Yêu cầu định dạng ảnh: jpeg, jpg, png, gif",
  "my_request_error_required": "Vui lòng cung cấp thông tin này",
  "my_request_error_url_match": "Vui lòng nhập đúng định dạng URL",
  "my_request_feedback": "Phản hồi",
  "my_request_feedback_placeholder": "Hãy gửi phản hồi cho chúng tôi, nếu bạn không nhận được sản phẩm mẫu hoặc bạn đang có vấn đề với sản phẩm mẫu này nhé.",
  "my_request_link_input_placeholder": "Nhập link nghiệm thu",
  "my_request_max_result_item": "Mỗi lần chỉ gửi tối đa 20 bài nghiệm thu cho 1 gói ưu đãi",
  "my_request_not_exist": "Yêu cầu này không tồn tại",
  "my_request_reason": "Lý do",
  "my_request_reason_label": "Đánh giá nghiệm thu",
  "my_request_save_fail": "Oops! Có sự cố trong quá trình xử lý dữ liệu. Bạn vui lòng thử lại sau nhé!",
  "my_request_save_successfull": "Lưu thành công",
  "my_request_send": "Gửi",
  "my_request_send_feedback_success": "Đánh giá của bạn đã được gửi",
  "my_request_send_result": "Gửi kết quả",
  "my_request_traffic_placeholder": "Nền tảng",
  "my_request_watch_all": "Xem tất cả ({{sum}})",
  "new_password": "Mật khẩu mới",
  "next": "Tiếp theo",
  "no": "Không",
  "no_campaign": "Chưa có yêu cầu 🤗",
  "no_result": "Không tìm thấy kết quả nào",
  "note_change_condition": "*Ecomobi có quyền thay đổi điều kiện thứ hạng và phần thưởng của\n          chương trình bất cứ lúc nào mà không cần thông báo trước.",
  "notice_bonus_for_user": "THÔNG BÁO CÁC MỨC THƯỞNG DÀNH CHO {{publisher_group}}",
  "notification": "Thông báo",
  "notification_back_to_previous": "Quay về trang trước",
  "notification_breadcumb": "Thông báo",
  "notification_campaign": "Chiến dịch",
  "notification_detail_page_breadcumb": "Chi tiết thông báo",
  "notification_empty_campaign": "Chưa có thông báo nào",
  "notification_empty_news": "Chưa có thông báo nào",
  "notification_empty_payment": "Chưa có thông báo nào",
  "notification_news": "Tin tức",
  "notification_not_exist": "Thông báo này không tồn tại",
  "notification_payment": "Thanh toán",
  "notification_read_all": "Đã đọc tất cả",
  "off_virtual_gift_noti": "Bạn đã tắt tính năng Nhận quà.",
  "offer_package": "Gói ưu đãi",
  "on_virtual_gift_noti": "Bạn đã bật tính năng nhận quà thành công",
  "or": "Hoặc",
  "order": "Đơn hàng",
  "order_history": "Lịch sử đơn hàng",
  "order_id": "Mã đơn hàng",
  "order_passio": "Order",
  "order_payment_fee": "Phí thanh toán",
  "order_platform_fee": "Phí nền tảng",
  "order_response": "Phản hồi",
  "order_revenue": "Thu nhập",
  "order_tooltip": "Số lượng đơn hàng đã được đặt mua thông qua link affiliate của bạn",
  "passio_page": "Passio Page",
  "password_confirmation": "Xác nhận mật khẩu",
  "pay_available_balances": "Số dư khả dụng",
  "payment_agree": "Đồng ý",
  "payment_button_request_pay": "Tạo yêu cầu rút riền",
  "payment_create_request_failed": "Yêu cầu lỗi",
  "payment_create_request_success": "Hoàn thành",
  "payment_created_request_payment": "Tạo yêu cầu thanh toán",
  "payment_info_bank_account_name_not_same": "Nhập Họ & Tên trùng với Tên tài khoản và thông tin trong CCCD/CMT/Hộ chiếu",
  "payment_info_description_file_card": "Đính kèm 2 ảnh CMND của bạn: Mặt trước, mặt sau. Yêu cầu tải lên hình ảnh rõ ràng , chi tiết",
  "payment_info_error_message": "Hồ sơ thanh toán chưa lưu thành công. Bạn vui lòng cập nhật lại sau nhé!",
  "payment_info_field_is_required": "Vui lòng nhập thông tin này",
  "payment_info_full_name": "Họ tên",
  "payment_info_id_card": "Nhập số CCCD/CMT/Hộ chiếu",
  "payment_info_place_holder_adress_payment": "Nhập địa chỉ thanh toán của bạn",
  "payment_info_placeholder_bank_branch": "Nhập chi nhánh ngân hàng",
  "payment_info_placeholder_bank_fullname": "Nhập tên chủ tài khoản",
  "payment_info_placeholder_bank_name": "Nhập ngân hàng hưởng thụ của bạn",
  "payment_info_placeholder_card_date": "Ngày cấp",
  "payment_info_placeholder_family_card_name": "Nhập tên thẻ gia đình",
  "payment_info_placeholder_family_card_number": "Nhập số thẻ gia đình",
  "payment_info_placeholder_id_card": "Nơi cấp",
  "payment_info_placeholder_number_bank_account": "Nhập số tài khoản",
  "payment_info_placeholder_personal_tax": "Nhập mã số thuế cá nhân",
  "payment_info_placeholder_postal_code": "Nhập mã bưu điện của bạn",
  "payment_info_please_enter_at_most_characters": "Vui lòng nhập tối đa {{n}} ký tự",
  "payment_info_please_enter_number": "Vui lòng chỉ nhập chữ số (0-9)",
  "payment_info_success_message": "Hồ sơ thanh toán đã cập nhật thành công. Bạn vui lòng chờ xác thực trong 7 ngày.",
  "payment_info_title_card_back": "Mặt sau CCCD/CMT",
  "payment_info_title_card_front": "Mặt trước CCCD/CMT",
  "payment_info_title_upload_image": "Tải lên hình ảnh",
  "payment_infor_payment_data": "Hồ sơ thanh toán",
  "payment_infor_personal_infor": "Thông tin cá nhân",
  "payment_menu": "Payment",
  "payment_message_not_update_information": "Tài khoản của bạn chưa được cung cấp thông tin cá nhân đầy đủ hoặc hồ sơ thanh toán chưa được xác thực. Vui lòng cập nhật để được thanh toán!",
  "payment_message_note": "Lưu ý: Thời gian xác thực hồ sơ thanh toán khoảng 7 ngày",
  "payment_message_withdraw_failed": "Yêu cầu thanh toán chưa tạo thành công. Bạn vui lòng tạo lại sau nhé!",
  "payment_message_withdraw_sending": "Yêu cầu thanh toán đang được xử lý. Xin vui lòng chờ trong giây lát...",
  "payment_message_withdraw_success": "Yêu cầu thanh toán đã tạo thành công. Ngày thanh toán dự kiến là: {{date}}.",
  "payment_pay_available_balances": "Số dư khả dụng",
  "payment_pay_minimum_drawable_balance": "Số dư tối thiểu có thể rút: {{money}}",
  "payment_pending_message": "Bạn đang có một yêu cầu thanh toán đang chờ duyệt.",
  "payment_support_text": "Chúng tôi có thể hỗ trợ thanh toán tất cả tiền trong tài khoản tại 1 lần giao dịch.",
  "payment_title": "Thanh toán",
  "payment_total_money": "Số tiền",
  "payment_update_information": "Cập nhật thông tin",
  "payment_withdraw": "Rút tiền",
  "payment_withdraw_to": "Rút tiền về",
  "pending": "Chờ duyệt",
  "pending_notify_msg_my_request": "Bạn sẽ có cơ hội nhận thêm các gói ưu đãi này nếu như được nhãn hàng phê duyệt.",
  "placeholder_reject_content": "Nhập vào lý do bạn từ chối yêu cầu này",
  "placeholder_response_virtual_gift": "Hãy gửi phản hồi đến người tặng quà này cho bạn",
  "platform": "Nền tảng",
  "please_enter_1_in_2_fields": "Vui lòng cung cấp 1 in 2 thông tin này",
  "please_enter_correct_link": "Vui lòng nhập chính xác đường dẫn {{traffic_source}} của bạn",
  "please_using_letters_a_z_and_number_0_9": "Vui lòng chỉ nhập chữ cái (a-z) và số (0-9)",
  "price": "Giá",
  "price_label": "Giá:",
  "product": "Sản phẩm",
  "product_description": "Mô tả sản phẩm",
  "product_description_placeholder": "Bạn hãy mô tả kỹ nội dung về dịch vụ/sản phẩm mà bạn cung cấp nhé.",
  "product_name": "Tên sản phẩm",
  "product_review_photo": "Ảnh xem trước của sản phẩm",
  "product_select_upload_type_suggest": "Bạn bấm chọn 1 trong 2 kiểu sản phẩm sau nha 👇👇👇",
  "product_type": "Loại sản phẩm",
  "product_upload_choose": "Tải lên một sản phẩm bất kỳ",
  "product_upload_file_suggest": "Bạn có thể bán bất kỳ một sản phẩm gì dưới dạng file kỹ thuật số (ảnh, video, ebook, zip,…). Hãy để khả năng sáng tạo của bạn được thoải mái bay bổng! ✍🏻",
  "product_upload_url_suggest": "Đây là sản phẩm dạng tư vấn (consulting), hướng dẫn (coaching) trực tiếp thông qua các ứng dụng gọi điện video như Zoom, Google Meet,… Bạn hãy mô tả kỹ càng nội dung dịch vụ bạn có thể cung cấp cho khách hàng nhé! ✍🏻",
  "product_url_choose": "Redirect URL",
  "profile": "Trang cá nhân",
  "profile_account_infomation": "Thông tin tài khoản",
  "profile_account_name": "Tên chủ tài khoản",
  "profile_account_number": "Số tài khoản",
  "profile_address": "Địa chỉ/Chỗ ở hiện tại",
  "profile_bank": "Ngân hàng",
  "profile_bank_branch": "Chi nhánh",
  "profile_change_password": "Thay đổi mật khẩu",
  "profile_change_password_fail": "Mật khẩu chưa được thay đổi. Vui lòng thực hiện lại sau.",
  "profile_change_password_note": "Bạn nên chọn đặt mật khẩu mạnh mà chưa sử dụng ở đâu khác",
  "profile_change_password_success": "Bạn đã đổi mật khẩu thành công",
  "profile_change_phone_number_note": "Vui lòng sử dụng số điện thoại của chính bạn để được nhận hỗ trợ tốt nhất.",
  "profile_confirm": "Xác nhận",
  "profile_confirm_password_error": "Mật khẩu mới không khớp",
  "profile_date_of_birth": "Ngày sinh",
  "profile_description_verify_success": "Nếu bạn muốn thay đổi thông tin thanh toán, vui lòng liên hệ với bộ phận hỗ trợ",
  "profile_description_verify_warning": "Thời gian chờ xác thực tối đa 7 ngày",
  "profile_email": "Email",
  "profile_error_channel_format": "Vui lòng nhập đúng định dạng link {{channel}}",
  "profile_error_duplicate_channel": "Kênh đã tồn tại",
  "profile_error_max_length": "Vui lòng nhập tối đa {{max}} ký tự",
  "profile_error_min_length": "Vui lòng nhập tối thiểu {{min}} ký tự",
  "profile_error_number_mobile": "Vui lòng chỉ nhập chữ số (0-9)",
  "profile_family_card_name": "Tên thẻ gia đình",
  "profile_family_card_number": "Số thẻ gia đình",
  "profile_field_required": "Vui lòng nhập thông tin này",
  "profile_file_card": "Ảnh CCCD/CMT",
  "profile_file_family_card_image": "Ảnh thẻ gia đình",
  "profile_file_tax_id_image": "Ảnh mã số thuế",
  "profile_followers_required": "Vui lòng nhập số lượt theo dõi",
  "profile_full_name": "Họ và tên",
  "profile_full_name_suggest": "Nhập Họ & Tên trùng với Tên tài khoản và thông tin trong CCCD/CMT/Hộ chiếu",
  "profile_gender": "Giới tính",
  "profile_gender_female": "Nữ",
  "profile_gender_male": "Nam",
  "profile_gender_other": "Khác",
  "profile_id_number": "Số CCCD/CMT/Hộ chiếu",
  "profile_id_number_created_at": "Ngày cấp CCCD/CMT",
  "profile_id_number_place": "Nơi cấp CCCD/CMT",
  "profile_image_id": "Ảnh CCCD/CMT",
  "profile_image_id_suggest": "Đính kèm 2 ảnh CMND của bạn: Mặt trước, mặt sau. Yêu cầu tải lên hình ảnh rõ ràng , chi tiết",
  "profile_industry": "Ngành hàng",
  "profile_industry_placeholder": "Chọn các ngành hàng mà bạn yêu thích",
  "profile_information": "Thông tin",
  "profile_input_facebook_placeholder": "Nhập kênh Facebook của bạn",
  "profile_input_instagram_placeholder": "Nhập kênh Instagram của bạn",
  "profile_input_lemon8_placeholder": "Nhập kênh Lemon8 của bạn",
  "profile_input_line_placeholder": "Nhập kênh Line của bạn",
  "profile_input_tiktok_placeholder": "Nhập kênh Tiktok của bạn",
  "profile_input_twitter_placeholder": "Nhập kênh Twitter của bạn",
  "profile_input_youtube_placeholder": "Nhập kênh Youtube của bạn",
  "profile_marital_status": "Trạng thái hôn nhân",
  "profile_matrial_married": "Đã kết hôn",
  "profile_matrial_other": "Khác",
  "profile_matrial_single": "Độc thân",
  "profile_new_password_error": "Mật khẩu mới không thể giống mật khẩu hiện tại",
  "profile_new_phone_number": "Số điện thoại mới",
  "profile_old_password_error": "Mật khẩu hiện tại chưa đúng",
  "profile_password": "Mật khẩu",
  "profile_payment_verification_status": "Trạng thái xác thực thanh toán",
  "profile_person_info_fail_message": "Hồ sơ cá nhân chưa lưu thành công. Bạn vui lòng cập nhật lại sau nhé!",
  "profile_person_info_success_message": "Hồ sơ cá nhận của bạn đã được xác nhận cập nhật thành công.",
  "profile_personal_tax_code": "Mã số thuế cá nhân",
  "profile_personal_tax_code_had": "Đã có",
  "profile_personal_tax_code_had_not": "Không có",
  "profile_personal_tax_code_helper": "Ecomobi sẽ giúp bạn đăng ký mã thuế cá nhân, lưu vào thông tin Thanh toán của bạn.",
  "profile_personal_tax_code_link": "Tại đây.",
  "profile_personal_tax_code_suggest": "Bạn có thể tra cứu mã số thuế cá nhân",
  "profile_phone_number": "Số điện thoại",
  "profile_postal_code": "Postal Code",
  "profile_save_success": "Bạn đã cập nhật thông tin thành công",
  "profile_social_channel_followers": "Lượt theo dõi",
  "profile_social_channel_label": "Link nền tảng",
  "profile_social_channel_placeholder": "Nhập số lượng",
  "profile_social_media_channel": "Kênh mạng xã hội",
  "profile_social_media_channel_note": "Vui lòng thêm ít nhất 1 kênh mạng xã hội",
  "profile_tab_account": "Tài khoản",
  "profile_tab_payment_info": "Thông tin thanh toán",
  "profile_tab_user_info": "Thông tin cá nhân",
  "profile_update_mobile_success": "Số điện thoại đã cập nhật thành công",
  "profile_upload_avatar_success": "Cập nhật ảnh đại diện thành công",
  "profile_username": "Tên đăng nhập",
  "profile_verify_incomplete": "Chưa hoàn thành",
  "profile_verify_not_complete": "Chưa hoàn thành",
  "profile_verify_not_provided": "Chưa cung cấp",
  "profile_verify_pending": "Đang xác thực",
  "profile_verify_success": "Đã xác thực",
  "profile_verify_waiting": "Chờ xác thực",
  "proflie_update_mobile_fail": "Số điện thoại chưa được cập nhật. Vui lòng thực hiện lại sau.",
  "public_product": "Xuất bản sản phẩm",
  "public_product_note": "👉🏻 Bật tính năng này, sản phẩm của bạn sẽ được hiển thị trong cửa hàng.",
  "quick_response": "Phản hồi ngay",
  "random_product": "Tạo sản phẩm bất kỳ",
  "rank_bonus": "Rank bonus",
  "rank_info": "Rank Info",
  "read_less": "Rút gọn",
  "read_more": "Đọc thêm",
  "reason": "Lý do",
  "receive_gift_from_customers": "Nhận quà từ fan😊",
  "receiver_address": "Địa chỉ nhận",
  "receiver_full_name": "Họ tên người nhận",
  "receiver_phone": "Số điện thoại người nhận",
  "recent_gift": "Quà nhận gần đây",
  "recent_order": "Đơn gần đây",
  "redirect_url_note": "Provide a publicly available link to the content you are selling. E.g: a Google drive, a Yutube video or a Zoom invite,...",
  "redirect_url_placeholder": "URL to the redirect your buyers after purchase",
  "referral": "Mời bạn bè",
  "referral_achievement": "Thành tích",
  "referral_bonus_amount": "Tiền thưởng",
  "referral_bonus_approved": "Đã đối soát",
  "referral_bonus_pending": "Chờ đối soát",
  "referral_conditions": "Điều kiện",
  "referral_friend": "Bạn bè",
  "referral_join_passio": "Tham gia Passio cùng {{name}}",
  "referral_method": "Phương thức",
  "referral_save_code": "Lưu",
  "referral_timeline": "Thời gian áp dụng",
  "referral_tooltip_bonus_amount": "Tổng tiền thưởng đã được đối soát",
  "referral_tooltip_fixed_bonus": "Số tiền thưởng cố định",
  "referral_tooltip_total": "Tổng số người được giới thiệu đã được đối soát",
  "referral_total": "Tổng người được giới thiệu",
  "referral_total_bonus_amount": "Tổng tiền thưởng",
  "referral_update_success": "Mã giới thiệu đã được cập nhật",
  "register_select_channel": "Traffic Source",
  "reject_reason": "Lý do từ chối",
  "rejected": "Đã hủy",
  "rejected_notify_msg_my_request": "Chào bạn, cảm ơn bạn đã đăng ký tham gia chiến dịch! Hiện tại profile của bạn chưa thật sự phù hợp với nhãn hàng và sản phẩm. Hẹn gặp lại bạn vào chiến dịch gần nhất nhé! Chúc bạn một ngày tốt lành!",
  "reload": "Tải lại",
  "report": "Report",
  "report_affiliate": "Affiliate",
  "report_affiliate_breadcrumbs": "Báo cáo Affiliate",
  "report_affiliate_general": "General report",
  "report_affiliate_general_orders": "Orders",
  "report_by_link": "Report by link",
  "report_by_sub": "Báo cáo Sub",
  "report_commission": "Commission",
  "report_digital_product_breadcrumbs": "Báo cáo Sản phẩm số",
  "report_general_report": "Báo cáo chung",
  "report_virtual_gift_breadcrumbs": "Báo cáo Quà tặng",
  "request_has_expired": "Yêu cầu quá hạn",
  "required_field_code": "Vui lòng nhập mã giới thiệu",
  "reset": "Reset",
  "responsed": "Đã phản hồi",
  "results": "Kết quả",
  "reviewed": "Chưa đạt",
  "reviewed_multi_result_infinity_end_date_notify_msg_my_request": "Bạn còn {{count}} kết quả chưa đủ điều kiện. Vui lòng gửi nghiệm thu lại sớm để hoàn thành chiến dịch nhé!",
  "reviewed_multi_result_notify_msg_my_request": "Bạn còn {{count}} kết quả chưa đủ điều kiện. Vui lòng gửi nghiệm thu lại trước {{end_date}} để hoàn thành chiến dịch nhé!",
  "reviewed_one_result_infinity_end_date_notify_msg_my_request": "Kết quả của bạn chưa đủ điều kiện. Vui lòng gửi nghiệm thu lại sớm để hoàn thành chiến dịch nhé!",
  "reviewed_one_result_notify_msg_my_request": "Kết quả của bạn chưa đủ điều kiện. Vui lòng gửi nghiệm thu lại trước ngày {{end_date}} để hoàn thành chiến dịch nhé!",
  "sale_amount": "Sale amount",
  "save_channel": "Lưu",
  "save_product": "Lưu sản phẩm",
  "save_successfull": "Bạn đã lưu thay đổi thành công",
  "select_date": "Chọn thời gian",
  "select_date_range": "Chọn khoảng thời gian",
  "selected_date_must_be_less_than_current_date": "Selected date must be less than current date",
  "send_acceptance": "Gửi nghiệm thu",
  "send_feedback": "Phản hồi ngay",
  "send_request": "Gửi yêu cầu",
  "setting_dp_add": "Thêm",
  "setting_dp_cancle": "Huỷ bỏ",
  "shouout_empty_2_text": "Vui lòng ấn \"Tạo mã\" để thêm mới",
  "shouout_empty_text": "Bạn hiện tại chưa có yêu cầu nào",
  "shouout_explore_now": "Khám phá ngay",
  "shouout_text_start_create": "Bắt đầu tạo Shoutout",
  "shout_out": "Shoutout",
  "shoutout_about_service_description": "Video ShoutOut là dịch vụ cho phép Fan gửi tới Creator những yêu cầu về một video cá nhân hóa cho các dịp cụ thể như: Sinh nhật, tốt nghiệp, chúc mừng,... Creator sẽ phản hồi lại các yêu cầu đó bằng một video",
  "shoutout_about_service_title": "Giới thiệu về dịch vụ",
  "shoutout_accept": "Xác nhận",
  "shoutout_accepted": "Đã thực hiện",
  "shoutout_answer": "Cách thức trả lời",
  "shoutout_answer_method_description": "Nếu chấp nhận yêu cầu của Fan Creator cần trả lời lại bằng một video",
  "shoutout_answer_method_title": "Phương thức trả lời",
  "shoutout_breadcrumb": "Video Shoutout",
  "shoutout_btn_content": "Nội dung nút",
  "shoutout_button_back": "Quay lại",
  "shoutout_button_share": "Chia sẻ",
  "shoutout_cancel": "Huỷ bỏ",
  "shoutout_cancel_upload": "Huỷ",
  "shoutout_change_file": "Đổi ảnh/video",
  "shoutout_change_file_video": "Đổi video",
  "shoutout_confirm_cancle": "Không",
  "shoutout_confirm_no": "Không",
  "shoutout_confirm_request_warning": "Sau khi ấn \"Có\" video sẽ được gửi tới Fan. Bạn có chắc chắn về hành động này ?",
  "shoutout_confirm_yes": "Có",
  "shoutout_content_step_1": "Chọn \"Trả lời\" hoặc \"Từ chối\" để phản hồi Fan của bạn",
  "shoutout_content_step_2": "Quay trực tiếp từ điện thoại hoặc tải lên một video để trả lời yêu cầu, thời gian của video nên nhỏ hơn 1p30s",
  "shoutout_content_step_3": "Ấn \"Xác nhận\" đồng nghĩa với việc video của bạn sẽ được gửi đi và không thể chỉnh sửa",
  "shoutout_continue_editing": "Tiếp tục chỉnh sửa",
  "shoutout_copy_link_fail": "Sao chép liên kết thất bại",
  "shoutout_copy_link_success": "Sao chép liên kết thành công",
  "shoutout_create_request": "Tạo yêu cầu ngay",
  "shoutout_create_shoutout_btn": "Bắt đầu tạo Shoutout",
  "shoutout_decline": "Từ chối",
  "shoutout_description": "Nội dung giới thiệu",
  "shoutout_description_modal_upload": "Vui lòng tải lên hoặc quay một video để trả lời yêu cầu Fan của bạn nha",
  "shoutout_description_placeholder": "Nhập vào nội dung giới thiệu",
  "shoutout_description_request": "Nội dung yêu cầu",
  "shoutout_download_success": "Tải video lên thành công",
  "shoutout_edit": "Chỉnh sửa",
  "shoutout_edit_btn": "Chỉnh sửa Shoutout",
  "shoutout_error_only_video_type": "Vui lòng tải lên file là một trong các định dạng MP4, MOV",
  "shoutout_error_price_required": "Vui lòng nhập vào giá tiền",
  "shoutout_error_upload": "Có lỗi xảy ra, tải lên thất bại",
  "shoutout_error_video_required": "Vui lòng tải lên ảnh/video",
  "shoutout_error_video_size": "Vui lòng tải lên hình ảnh/ video tối đa 500MB",
  "shoutout_error_video_type": "Vui lòng tải lên file là một trong các định dạng JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC, MP4, MOV",
  "shoutout_escape": "Thoát",
  "shoutout_establish": "Thiết lập ngay",
  "shoutout_exit_deleting_warning": "Các chỉnh sửa của bạn sẽ không được lưu. Bạn có chắc chắn muốn thoát không?",
  "shoutout_exit_editing": "Thoát chỉnh sửa?",
  "shoutout_file_intro": "Ảnh/video giới thiệu",
  "shoutout_file_intro_note": "Tải lên hình ảnh hoặc video để kêu gọi Fan sử dụng dịch vụ Video Shoutout từ bạn",
  "shoutout_file_upload_note": "Để trả lời Fan, bạn cần tải lên một video",
  "shoutout_home": "Trang chủ",
  "shoutout_home_title": "Trang chủ",
  "shoutout_how_to_answer": "Cách trả lời",
  "shoutout_intro_content_image_build": "Một hình thức hỗ trợ bạn tương tác sâu và gần gũi hơn với tệp super fan của mình",
  "shoutout_intro_content_image_gift": "Tính năng giúp fan có thể gửi yêu cầu tới bạn để thực hiện video cho các dịp đặc biệt",
  "shoutout_intro_content_image_private": "Quyền chấp nhận hay từ chối thực hiện video là ở bạn",
  "shoutout_intro_content_image_request": "Thông qua Passio, fan của bạn có thể gửi tới bạn những yêu cầu thực hiện video cho các dịp đặc biệt : Sinh nhật, chúc mừng, ngày kỷ niệm, video động viên, truyền cảm hứng... với một khoản phí nhất định do chính bạn đề ra.",
  "shoutout_intro_content_image_share": "Bạn và fan có thể chia sẻ những video ý nghĩa này với mọi người thông qua các trang mạng xã hội.",
  "shoutout_intro_content_image_video": "Bạn có 7 ngày để quyết định thực hiện hoặc từ chối yêu cầu của fan. Nếu bạn từ chối không thực hiện thì Ecomobi sẽ hoàn lại toàn bộ số tiền đã thanh toán tới người yêu cầu trong vòng 30 ngày.",
  "shoutout_intro_fail": "Thất bại",
  "shoutout_intro_title_gift": "Video Shoutout hoạt động như thế nào?",
  "shoutout_intro_title_image_build": "Xây dựng cộng đồng fan \"bự\"",
  "shoutout_intro_title_image_gift": "Quà tặng mang dấu ấn riêng của bạn",
  "shoutout_intro_title_image_private": "Đảm bảo tính riêng tư",
  "shoutout_intro_title_image_request": "Nhận yêu cầu từ fan",
  "shoutout_intro_title_image_share": "Chia sẻ những khoảnh khắc ý nghĩa.",
  "shoutout_intro_title_image_video": "Thực hiện Video",
  "shoutout_introduce": "Giới thiệu",
  "shoutout_learn_shoutout": "Tìm hiểu về Video Shoutout",
  "shoutout_learn_tod": "Tìm hiểu về Truth or Dare",
  "shoutout_list_request_title": "Danh sách yêu cầu",
  "shoutout_loading_service": "Đang tải ...",
  "shoutout_loading_service_title": "Video ShoutOut",
  "shoutout_loading_upload": "Đang tải ...",
  "shoutout_new": "Mới",
  "shoutout_no_file_selected": "Chưa có ảnh/video nào được chọn",
  "shoutout_no_file_video_selected": "Chưa có video nào được chọn",
  "shoutout_order_code": "Mã yêu cầu",
  "shoutout_participate_description": "Creator có thể chọn thực hiện hoặc từ chối yêu cầu của Fan",
  "shoutout_participate_title": "Quyền tham gia ở bạn",
  "shoutout_price": "Giá tiền",
  "shoutout_price_free": "Miễn phí",
  "shoutout_price_placeholder": "Nhập vào giá tiền",
  "shoutout_processing": "Video đang được xử lý để gửi đến Fan",
  "shoutout_reject": "Từ chối",
  "shoutout_reject_cancle": "Đóng",
  "shoutout_reject_failed": "Từ chối thất bại",
  "shoutout_reject_request": "Từ chối yêu cầu?",
  "shoutout_reject_request_warning": "Bạn có chắc chắn muốn từ chối yêu cầu này ? Hành động sẽ không thể hoàn tác",
  "shoutout_reject_success": "Đã từ chối một yêu cầu",
  "shoutout_reply": "Trả lời",
  "shoutout_reply_error_msg": "Phản hồi thất bại",
  "shoutout_reply_success_msg": "Chúc mừng, đã gửi video tới Fan của bạn",
  "shoutout_request": "Yêu cầu",
  "shoutout_request_detail": "Chi tiết yêu cầu",
  "shoutout_request_reject_confirm": "Xác nhận",
  "shoutout_response_description": "Sau khi Fan gửi yêu cầu, Creator có 7 ngày để thực hiện Video. Sau thời gian này, số tiền của yêu cầu đó sẽ được hoàn trả lại Fan",
  "shoutout_response_title": "7 ngày phản hồi",
  "shoutout_save": "Lưu",
  "shoutout_save_video_success": "Cấu hình Video Shoutout thành công",
  "shoutout_select_file": "Chọn ảnh/video",
  "shoutout_select_file_video": "Chọn video",
  "shoutout_select_video_upload": "Chọn video",
  "shoutout_share": "Chia sẻ",
  "shoutout_start": "Bắt đầu thiết lập Video Shoutout",
  "shoutout_start_message": "Thiết lập Video ShoutOut để nhận yêu cầu từ Fan nhé",
  "shoutout_status_request": "Trạng thái yêu cầu",
  "shoutout_step_1": "Bước 1",
  "shoutout_step_2": "Bước 2",
  "shoutout_step_3": "Bước 3",
  "shoutout_text_from": "Từ",
  "shoutout_text_to": "Đến",
  "shoutout_time_created": "Thời gian tạo",
  "shoutout_time_remain": "{{day}} ngày còn lại",
  "shoutout_time_remain_status": "Thời gian còn lại",
  "shoutout_time_remain_title": "Thời gian còn lại",
  "shoutout_title_modal_upload": "Trả lời yêu cầu",
  "shoutout_type_advice": "Lời khuyên",
  "shoutout_type_anniversary": "Dịp đặc biệt",
  "shoutout_type_birthday": "Sinh nhật",
  "shoutout_type_mobilize": "Động viên",
  "shoutout_type_other": "Các dịp khác",
  "shoutout_type_question": "Câu hỏi",
  "shoutout_type_support": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC, MP4, MOV tối đa 500MB",
  "shoutout_type_video_support": "Hỗ trợ: MP4, MOV tối đa 500MB",
  "shoutout_type_vocative_adik": "Adik",
  "shoutout_type_vocative_anda": "Anda",
  "shoutout_type_vocative_aunt": "Cô",
  "shoutout_type_vocative_bapak": "Bapak",
  "shoutout_type_vocative_big_uncle": "Bác",
  "shoutout_type_vocative_bro": "Em",
  "shoutout_type_vocative_friend": "Bạn",
  "shoutout_type_vocative_grandfather": "Ông",
  "shoutout_type_vocative_grandmother": "Bà",
  "shoutout_type_vocative_he": "Anh",
  "shoutout_type_vocative_he_him": "He/Him",
  "shoutout_type_vocative_ibu": "Ibu",
  "shoutout_type_vocative_kakak": "Kakak",
  "shoutout_type_vocative_paman": "Paman",
  "shoutout_type_vocative_she": "Chị",
  "shoutout_type_vocative_she_her": "She/Her",
  "shoutout_type_vocative_tante": "Tante",
  "shoutout_type_vocative_thai_1": "คุณ",
  "shoutout_type_vocative_thai_2": "พี่ชาย",
  "shoutout_type_vocative_thai_3": "พี่สาว",
  "shoutout_type_vocative_thai_4": "น้อง",
  "shoutout_type_vocative_thai_5": "น้า",
  "shoutout_type_vocative_thai_6": "อา",
  "shoutout_type_vocative_thai_7": "ลุง",
  "shoutout_type_vocative_thai_8": "ยาย",
  "shoutout_type_vocative_thai_9": "ปู่",
  "shoutout_type_vocative_they_them": "They/Them",
  "shoutout_type_vocative_uncle": "Chú",
  "shoutout_type_vocative_you": "You",
  "shoutout_upload_cancel": "Thoát",
  "shoutout_upload_continute": "Tiếp tục chỉnh sửa",
  "shoutout_video_required": "Vui lòng tải lên video",
  "shoutout_view_detail": "Xem chi tiết",
  "shoutout_warning_cancel_upload": "Các chỉnh sửa của bạn sẽ không được lưu. Bạn có chắc chắn muốn thoát không?",
  "shoutout_warning_title_cancel_upload": "Thoát chỉnh sửa?",
  "shoutout_your_feedback": "Phản hồi của bạn",
  "ss_account_total": "Tổng số",
  "ss_account_total_not_sold": "Còn lại",
  "ss_account_total_sold": "Đã bán",
  "ss_add_photo_product_success": "Lưu thành công",
  "ss_add_voucher_success": "Thêm mới mã giảm giá thành công",
  "ss_builder_digital_product_free_label": "Miễn phí",
  "ss_builder_passio_page_ready": "Passio Page đã sẵn sàng",
  "ss_builder_passio_page_ready_description": "Passio Page đã sẵn sàng để sử dụng và thay thế cho hệ thống Landing Page cũ.",
  "ss_builder_start_use_passio_page": "Bắt đầu sử dụng Passio Page ngay nào!",
  "ss_builder_to_new_version": "Đi đến Passio Page",
  "ss_button_title_default_donation": "Ủng hộ 💵 cho {{name}} bạn nhé!",
  "ss_buyer": "Người mua",
  "ss_call_api_error": "Đã có lỗi xảy ra, vui lòng thử lại sau",
  "ss_cancel": "Hủy",
  "ss_category_add_product_label": "Thêm sản phẩm",
  "ss_course_info_preview_button": "Vui lòng điền vào các thông tin \"Thiết lập khóa học\" & \"Bài giảng\" để xem trước nội dung khóa học",
  "ss_course_manager_complete_date": "Ngày hoàn thành",
  "ss_course_manager_last_date": "Ngày học gần nhất",
  "ss_course_manager_list_empty": "Chưa có học viên nào",
  "ss_course_manager_start_date": "Ngày bắt đầu",
  "ss_course_manager_status_course_complete": "Hoàn thành",
  "ss_course_manager_status_course_learing": "Đang học",
  "ss_course_manager_status_course_not_learn": "Chưa vào học",
  "ss_course_manager_title_generality": "Tổng quát",
  "ss_course_manager_title_list": "Danh sách",
  "ss_course_preview_button": "Xem trước",
  "ss_customer_donation_name": "Donation",
  "ss_customer_list_empty": "Chưa có khách hàng nào",
  "ss_customer_order_success": "Thành công",
  "ss_customer_placeholder_search": "Username, Email, Số điện thoại",
  "ss_customer_price_free": "Miễn phí",
  "ss_customer_title": "Danh sách khách hàng",
  "ss_customer_title_detail_customer": "Thông tin khách hàng",
  "ss_customer_title_list_purchase": "Danh sách sản phẩm đã mua",
  "ss_challenge": "Thách thức",
  "ss_click_to_detail_note": "Bạn bấm chọn 1 trong 2 kiểu sản phẩm sau nha 👇👇👇",
  "ss_close": "Đã hiểu",
  "ss_content_success_TOD": "Bạn đã cài đặt tính năng Truth or Dare thành công.",
  "ss_count_gift_giving": "Lượt tặng quà",
  "ss_digital_product": "Sản phẩm số",
  "ss_discover_now": "Khám phá ngay",
  "ss_donation_answer": "Hiểu thêm về Donation",
  "ss_donation_content_helper_1": "Creator có thể gia tăng thu nhập thông qua dịch vụ Donation bằng cách kêu \ngọi Fan và người xem donate cho mình những khoản tiền cụ thể. \nBạn có thể đặt lời kêu gọi donate ở phía cuối video hoặc cuối các bài post của mình.",
  "ss_donation_content_helper_2": "Creator có thể thiết lập mức tiền Donate tối thiểu mà mình mong muốn. \nFan có thể tùy ý Donate các khoản tiền mong muốn, miễn sao không thấp \nhơn mức Donate tối thiểu mà Creator kỳ vọng.",
  "ss_donation_content_helper_3": "Passio miễn phí hoàn toàn phí nền tảng cho Creator khi sử dụng dịch vụ Donation. \nCreator chỉ phải chịu 5% phí cổng thanh toán và có thể đút túi 95% số tiền mà Fan donate.",
  "ss_donation_content_intro_1": "Donation là dịch vụ hỗ trợ Creator kêu gọi Fan và người xem donate cho \nmình bằng các khoản tiền cụ thể để Creator có thêm động lực để tiếp tục \nsản xuất thêm những nội dung chất lượng khác.",
  "ss_donation_content_intro_2": "Creator có thể gia tăng thu nhập, tối ưu hoá nguồn lợi từ hình ảnh của bản thân \nhoặc nội dung mình sản xuất khi tất cả lượt donate từ Fan đều có thể quy đổi \nsang tiền mặt. Đặc biệt, Creator có thể nhận được donate bất cứ ở đâu và \nkhi nào, ngay cả trong lúc ngủ.",
  "ss_donation_currency_shortcut": "₫",
  "ss_donation_edit": "Chỉnh sửa",
  "ss_donation_establish": "Thiết lập ngay",
  "ss_donation_home_title": "Donation",
  "ss_donation_intro_button_submit": "Khám phá ngay",
  "ss_donation_intro_button_title_default": "Ủng hộ 💵 cho {{name}} bạn nhé!",
  "ss_donation_intro_invitation_default": "Bạn yêu quý {{name}}?",
  "ss_donation_intro_message_default": "Cảm ơn bạn đã ủng hộ {{name}}. Hi vọng chúng ta sẽ có nhiều dịp tương tác với nhau nữa nhé!",
  "ss_donation_learn_service": "Tìm hiểu về Donation",
  "ss_donation_list_request_title": "Danh sách ủng hộ",
  "ss_donation_setting_btn_continue": "Tiếp tục",
  "ss_donation_setting_btn_donation_title": "Cài đặt tên nút kêu gọi",
  "ss_donation_setting_btn_title_placeholder": "Donate now",
  "ss_donation_setting_btn_title_tooltip": "Nhập tối đa 40 ký tự",
  "ss_donation_setting_cover_image": "Ảnh bìa",
  "ss_edit_voucher_success": "Chỉnh sửa mã giảm giá thành công",
  "ss_exclusive_passio_badge": "Độc quyền",
  "ss_exit_add_warning": "Các thay đổi của bạn sẽ không được lưu. Bạn có chắc chắn muốn thoát không?",
  "ss_intro_plaform_fee": "Phí nền tảng:",
  "ss_intro_plaform_fee_free": "Miễn phí",
  "ss_intro_plaform_fee_from": "từ",
  "ss_intro_plaform_fee_to": "đến",
  "ss_label_end_date_code": "Đến ngày",
  "ss_label_name_voucher": "Tên giảm giá",
  "ss_label_quantity": "Lượt sử dụng",
  "ss_label_quantity_left": "Số lượt còn lại",
  "ss_label_quantity_used": "Số lượt đã sử dụng",
  "ss_label_start_date_code": "Từ ngày",
  "ss_label_voucher_amount_discount": "Số tiền giảm",
  "ss_label_voucher_code": "Mã giảm giá",
  "ss_label_voucher_date": "Thời hạn sử dụng",
  "ss_label_voucher_max_discount": "Mức giảm tối đa",
  "ss_label_voucher_percent_discount": "Phần trăm giảm",
  "ss_label_voucher_quantity": "Số lượt sử dụng",
  "ss_label_voucher_type": "Loại giảm giá",
  "ss_list_empty_content": "Để thêm vui lòng nhấn nút “Tạo sản phẩm” ở phía trên",
  "ss_list_ticket_account": "Danh sách vé/tài khoản",
  "ss_message_default_donation": "Cảm ơn bạn đã ủng hộ {{name}}. Hi vọng chúng ta sẽ có nhiều dịp tương tác với nhau nữa nhé!",
  "ss_modal_preview_button_back": "Quay lại",
  "ss_modal_preview_button_donation": "Ủng hộ",
  "ss_modal_preview_button_submit": "Đăng tải",
  "ss_modal_preview_message_note_placeholder": "Gửi những lời yêu thương đến người sáng tạo nhé✍🏻",
  "ss_modal_preview_submit_success": "Chúc mừng bạn cài đặt Nhận ủng hộ thành công.",
  "ss_more_about_virtual_gift": "Hiểu thêm về Virtual Gift",
  "ss_no": "Không",
  "ss_no_data": "Chưa có số liệu",
  "ss_no_data_text": "Hãy bắt đầu thiết lập dịch vụ bên dưới để có nhiều đơn hơn nhé!",
  "ss_off": "Tắt",
  "ss_on": "Bật",
  "ss_order_code": "Mã đơn hàng",
  "ss_order_date": "Thời gian đặt hàng",
  "ss_photo_title_create": "Thêm sản phẩm",
  "ss_placeholder_input_code_voucher": "PASSIO123",
  "ss_placeholder_input_max_ammount_voucher": "Nhập giá tiền",
  "ss_placeholder_input_name_voucher": "Sale 12/12",
  "ss_placeholder_input_percent_voucher": "Nhập phần trăm",
  "ss_placeholder_input_price_ammount_voucher": "Nhập giá tiền",
  "ss_placeholder_input_quantity_voucher": "Nhập số lượt",
  "ss_product_account_required": "Vui lòng tải danh sách lên",
  "ss_product_list_empty_title": "Chưa có sản phẩm",
  "ss_report_store_order_commission_value": "Là số hoa hồng chia sẻ cho Creator hỗ trợ bán đơn hàng này",
  "ss_product_name": "Tên sản phẩm",
  "ss_product_success_off_donation": "Bạn đã tắt tính năng Nhận ủng hộ.",
  "ss_product_success_off_virtual_gift": "Bạn đã tắt tính năng Nhận quà.",
  "ss_product_success_on_donation": "Bạn đã bật tính năng Nhận ủng hộ thành công.",
  "ss_product_success_on_virtual_gift": "Bạn đã bật tính năng Nhận quà thành công.",
  "ss_push_TOD": "Đăng tải",
  "ss_report_conversion_rate_brand_gift": "Tỷ lệ chuyển đổi",
  "ss_report_total_order_brand_gift": "Số đơn hàng",
  "ss_report_total_order_income_brand_gift": "Số brand có đơn",
  "ss_report_total_order_income_tooltip_brand_gift": "Số brand phát sinh tối thiểu 1 đơn",
  "ss_report_total_order_tooltip_brand_gift": "Số đơn mua hàng từ Fan",
  "ss_report_total_view_tooltip_brand_gift": "Số lượt Fan vào xem mục Brand Gift",
  "ss_report_vg_income_tooltip": "Thu nhập quà tặng mà bạn nhận được",
  "ss_report_vg_order_tooltip": "Đơn hàng quà tặng mà bạn nhận được",
  "ss_report_vg_show_list_all_orders": "Danh sách đơn hàng",
  "ss_response_customer": "Hãy gửi phản hồi đến người tặng quà này cho bạn",
  "ss_save": "Lưu",
  "ss_sell_photo_btn_save": "Lưu",
  "ss_sell_photo_btn_selectd": "Chọn",
  "ss_sell_photo_button_cancel_upload": "Hủy bỏ",
  "ss_sell_photo_cancel_add": "Hủy bỏ",
  "ss_sell_photo_cancel_add_warning": "Đóng",
  "ss_sell_photo_cancel_btn_delete": "Huỷ bỏ",
  "ss_sell_photo_confirm_btn_delete": "Xác nhận xoá",
  "ss_sell_photo_confirm_btn_warning_cancel": "Đóng",
  "ss_sell_photo_continute_add": "Xác nhận",
  "ss_sell_photo_cover_photo_upload": "Ảnh bìa",
  "ss_sell_photo_description_delete": "Ảnh sẽ bị xóa vĩnh viễn khỏi thư viện",
  "ss_sell_photo_description_label": "Mô tả",
  "ss_sell_photo_description_waring_cancel": "Bạn có chắc chắn muốn hủy bỏ",
  "ss_sell_photo_empty_description": "Để thêm ảnh sản phẩm vui lòng nhấn nút “Tải ảnh” ở phía trên",
  "ss_sell_photo_empty_title": "Chưa có ảnh sản phẩm nào",
  "ss_sell_photo_error_description_required": "Vui lòng nhập vào mô tả sản phẩm",
  "ss_sell_photo_error_name_required": "Bạn nhập tên sản phẩm nhé",
  "ss_sell_photo_error_original_price_required": "Bạn nhập giá gốc sản phẩm nhé",
  "ss_sell_photo_exit_add_text": "Các thay đổi chưa được lưu",
  "ss_sell_photo_exit_add_warning": "Bạn có chắc chắn muốn hủy bỏ",
  "ss_sell_photo_exit_upload_text": "Bạn có muốn hủy tải ảnh lên",
  "ss_sell_photo_exit_upload_warning": "Tất cả dữ liệu sẽ không được lưu",
  "ss_sell_photo_image_is_processing": "Ảnh đang được xử lý, vui lòng chờ trong giây lát",
  "ss_sell_photo_intro_photo_upload": "Ảnh giới thiệu (Tối đa 4 ảnh)",
  "ss_sell_photo_label_image_selected": "Ảnh đã chọn",
  "ss_sell_photo_list_image_title_popup": "Danh sách ảnh",
  "ss_sell_photo_max_photo": "Vui lòng tải lên tối đa 50 ảnh/ sản phẩm",
  "ss_sell_photo_max_size_upload_photo": "Vui lòng tải lên ảnh có kích thước tối đa 20MB",
  "ss_sell_photo_message_label": "Lời nhắn từ bạn",
  "ss_sell_photo_name_photo": "Tên sản phẩm",
  "ss_sell_photo_placeholder_input_description": "Nhập nội dung",
  "ss_sell_photo_placeholder_input_message": "Bạn hãy điền bất kỳ nội dung gì bạn muốn chỉ dành cho Fan ở đây (1 đường link bí mật dành riêng cho khách hàng, mật khẩu mở file, mật khẩu tham gia phòng Zoom,…)",
  "ss_sell_photo_placeholder_input_name_photo": "Nhập nội dung",
  "ss_sell_photo_placeholder_input_price": "Nhập giá tiền",
  "ss_sell_photo_price_discount_label": "Giá khuyến mãi",
  "ss_sell_photo_price_label": "Giá",
  "ss_sell_photo_price_original_label": "Giá gốc",
  "ss_sell_photo_required_photo": "Vui lòng tải lên ảnh sản phẩm",
  "ss_sell_photo_required_photo_cover": "Vui lòng tải lên ảnh bìa",
  "ss_sell_photo_required_photo_intro": "Vui lòng tải lên ảnh giới thiệu",
  "ss_sell_photo_text_button_list_image": "Danh sách ảnh",
  "ss_sell_photo_text_button_uncheck": "Huỷ bỏ",
  "ss_sell_photo_text_button_upload_image": "Tải ảnh",
  "ss_sell_photo_text_des_image_size": "Kích thước tệp tối đa: 20MB/ảnh",
  "ss_sell_photo_text_des_image_type": "Định dạng hỗ trợ: jpg, jpeg, png, bmp",
  "ss_sell_photo_title_delete": "Xóa ảnh",
  "ss_sell_photo_title_list": "Danh sách",
  "ss_sell_photo_title_photo": "Ảnh sản phẩm",
  "ss_sell_photo_title_waring_cancel": "Các thay đổi chưa được lưu",
  "ss_sell_photo_type_price_charge": "Trả phí",
  "ss_sell_photo_type_price_free": "Miễn phí",
  "ss_sell_photo_uploading_text": "Đang trong quá trình xử lý",
  "ss_send_response": "Gửi phản hồi",
  "ss_service_feature": "Giới thiệu về Virtual Gift",
  "ss_service_feature_text": "Virtual Gift là dịch vụ giúp Fan tặng cho Creator những món quà online với nhiều mệnh giá \ntiền khác nhau cùng lời nhắn thân thương thông qua Passio Page của họ.",
  "ss_service_introduction": "Giới thiệu dịch vụ",
  "ss_service_intruct": "Hướng dẫn thiết lập",
  "ss_service_make_money": "Kiếm tiền từ Virtual Gift",
  "ss_service_make_money_text": "Creator nhận càng nhiều quà từ Fan đồng nghĩa với việc thu nhập của bạn sẽ ngày càng cao. Đặc biệt, bạn có thể nhận những món quà online bất cứ khi nào, kể cả trong lúc ngủ.",
  "ss_setting_TOD_background_tooltip": "Chọn ảnh nền tỷ lệ 3:2 với dung lượng tối đa 4 MB",
  "ss_setting_TOD_field_image_background": "Thêm hình nền",
  "ss_setting_TOD_field_image_background_bottom": "Hình nền",
  "ss_setting_TOD_field_invitation_tooltip": "Nội dung lời mời chào giới hạn tối đa 60 ký tự.",
  "ss_setting_TOD_field_welcome_tooltip": "Nội dung Lời kêu gọi fan thử thách sẽ tự động điền nếu bạn bỏ trống.",
  "ss_setting_TOD_title": "Cài đặt",
  "ss_setting_btn_donation_title": "Cài đặt tên nút kêu gọi",
  "ss_setting_btn_donation_title_placeholder": "Ủng hộ ngay",
  "ss_setting_btn_donation_title_tooltip": "Nhập tối đa 40 ký tự",
  "ss_setting_donation_field_image_background": "Ảnh nền",
  "ss_setting_donation_field_image_background_tooltip": "Lựa chọn ảnh bìa với tỷ lệ 3:2 và dung lượng tối đa 4 MB",
  "ss_setting_donation_field_image_cover": "Ảnh bìa",
  "ss_setting_donation_field_max_price": "Số tiền nhận tối đa",
  "ss_setting_donation_field_max_price_placeholder": "Số tiền tối đa nhận từ fan",
  "ss_setting_donation_field_max_price_suggest": "Số tiền nhận tối đa không được thấp hơn số tiền nhận tối thiểu và không được cao hơn",
  "ss_setting_donation_field_message_thank_you": "Lời cảm ơn tới Fan",
  "ss_setting_donation_field_message_thank_you_placeholder": "Bạn cần gửi lời cảm ơn tới Fan của mình",
  "ss_setting_donation_field_message_thank_you_placeholder_focus_after": ". Mình sẽ tiếp tục sáng tạo nhiều nội dung thú vị đến cộng đồng.",
  "ss_setting_donation_field_message_thank_you_placeholder_focus_before": "Cảm ơn bạn đã ủng hộ",
  "ss_setting_donation_field_message_thank_you_tooltip": "Tối đa 300 ký tự",
  "ss_setting_donation_field_price": "Số tiền nhận tối thiểu",
  "ss_setting_donation_field_price_placeholder": "Số tiền tối thiểu nhận từ fan",
  "ss_setting_donation_field_price_suggest": "Số tiền nhận tối thiểu không được dưới 2000₫ và không được cao hơn 9,999,999,999₫.",
  "ss_setting_donation_field_price_suggest1": "Số tiền nhận tối thiểu không được dưới",
  "ss_setting_donation_field_price_suggest2": "và không được cao hơn",
  "ss_setting_donation_field_welcome": "Lời kêu gọi Fan ủng hộ",
  "ss_setting_donation_field_welcome_placeholder": "Bạn nhập lời kêu gọi ủng hộ",
  "ss_setting_donation_field_welcome_tooltip": "Lời kêu gọi ủng hộ chỉ giới hạn tối đa 60 ký tự.",
  "ss_setting_donation_title": "Cài đặt nhận tiền ủng hộ",
  "ss_showing": "Đang hiển thị",
  "ss_store_add_new": "Thêm mới",
  "ss_store_add_new_course_section": "Thêm bài mới",
  "ss_store_author_name_place_holder": "Nhập tên tác giả",
  "ss_store_author_name_title": "Tên tác giả",
  "ss_store_commission_value": "Hoa hồng chia sẻ",
  "ss_store_confirm_change_donation": "Bật tính năng Nhận ủng hộ và tắt tính năng Nhận quà tặng. Bạn chắc chắn không?",
  "ss_store_confirm_off_donation": "Bạn có muốn tắt tính năng Nhận ủng hộ?",
  "ss_store_confirm_off_virtual_gift": "Bạn có muốn tắt tính năng Nhận quà không?",
  "ss_store_course_content_group_title": "Bài giảng",
  "ss_store_course_description_label": "Mô tả ngắn khóa học",
  "ss_store_course_document_title": "Tài liệu học tập",
  "ss_store_course_faq_group_title": "Câu hỏi thường gặp",
  "ss_store_course_faq_title": "Câu hỏi thường gặp",
  "ss_store_course_info_bar_group_title": "Thiết lập khóa học",
  "ss_store_course_info_bar_title": "Thiết lập khóa học",
  "ss_store_course_original_price_title": "Giá gốc khóa học",
  "ss_store_course_price": "Giá khóa học",
  "ss_store_course_price_group_title": "Giá khóa học",
  "ss_store_course_price_title": "Giá",
  "ss_store_course_section_data_error": "Dự liệu bị thiếu hoặc không hợp lệ. Vui lòng kiểm tra lại",
  "ss_store_course_section_title": "Tạo bài giảng",
  "ss_store_course_sell_price_title": "Giá bán",
  "ss_store_course_thumbnail_label": "Hình ảnh khóa học",
  "ss_store_course_title_label": "Tiêu đề khóa học",
  "ss_store_course_value_description": "Làm rõ giá trị mà học viên sẽ đạt được ở đây sẽ giúp người học nhanh chóng hiểu được giá trị của khóa học và độ phù hợp với bản thân của khóa học.",
  "ss_store_course_value_title": "Giá trị học viên nhận được",
  "ss_store_course_video_warn": "Video có độ phân giải tối thiểu 720p và kích cỡ tối đa 1GB",
  "ss_store_create_course_material": "Tạo tài liệu học tập",
  "ss_store_create_course_section": "Tạo bài giảng",
  "ss_store_create_digital_course": "Tạo khóa học",
  "ss_store_data_required": "Vui lòng điền thông tin",
  "ss_store_default_answer_1": "Khóa học phù hợp với newbie",
  "ss_store_default_answer_2": "Bạn có thể học khóa này mãi mãi, ở bất kỳ đâu",
  "ss_store_default_answer_3": "Có. Sẽ có group riêng để bạn trao đổi với giảng viên.",
  "ss_store_default_question_1": "Khóa học này phù hợp với ai?",
  "ss_store_default_question_2": "Tôi có thể học khóa học này trong bao lâu?",
  "ss_store_default_question_3": "Khi có thắc mắc, tôi có thể trao đổi với giảng viên không?",
  "ss_store_delete_file": "Xoá video",
  "ss_store_demo_video_title": "Video quảng cáo khóa học",
  "ss_store_discount_price": "Giá khuyến mại",
  "ss_store_edit_digital_course": "Chỉnh sửa khóa học",
  "ss_store_error_save_course_section": "Cập nhật thất bại, Vui lòng thử lại sau.",
  "ss_store_error_update_document": "Cập nhật thất bại, Vui lòng thử lại sau.",
  "ss_store_faq_answer": "Trả lời",
  "ss_store_faq_answer_max_length": "Vui lòng nhập tối đa {{n}} ký tự cho câu trả lời",
  "ss_store_faq_field_require": "Vui lòng nhập đầy đủ giá trị",
  "ss_store_faq_question": "Câu hỏi",
  "ss_store_faq_question_max_length": "Vui lòng nhập tối đa {{n}} ký tự cho câu hỏi",
  "ss_store_field_required": "Trường này là bắt buộc",
  "ss_store_item_inprogress": "Vui lòng hoàn thành đầy đủ thông tin",
  "ss_store_max_character": "Vui lòng nhập nhiều nhất nhất {{n}} ký tự",
  "ss_store_max_value": "Giá trị tối đa là {{n}}",
  "ss_store_min_value_or_zero": "Giá trị bằng 0 hoặc lớn hơn hoặc bằng 1000",
  "ss_store_momo_wallet": "Momo Wallet - Ví điện tử",
  "ss_store_not_enough_course_resource": "Bạn cần nhập đủ thông tin để Hiển thị khóa học",
  "ss_store_order_buyer_message": "Người mua gửi tới bạn",
  "ss_store_order_income": "Doanh thu",
  "ss_store_order_payment_fee": "Phí thanh toán",
  "ss_store_order_payment_fee_tooltip": "Là phí do các cổng thanh toán tự động thu trên từng giao dịch. Mức phí phụ thuộc vào đối tác thanh toán và từng phương thức thanh toán.",
  "ss_store_order_platform_fee": "Phí nền tảng",
  "ss_store_order_platform_fee_tooltip": "Là phí được tính theo phần trăm từ doanh thu phát sinh khi người dùng đặt mua thành công. Mức phần trăm có thể khác nhau tùy loại hình sản phẩm.",
  "ss_store_order_revenue": "Thu nhập",
  "ss_store_order_revenue_tooltip": "Là khoản tiền (trước khi khấu trừ thuế nếu có) bạn sẽ nhận được sau khi lấy doanh thu trừ đi các khoản chi phí.",
  "ss_store_origin_larger_than_price": "Gía gốc phải cao hơn hoặc bằng giá bán của sản phẩm",
  "ss_store_original_price": "Giá gốc",
  "ss_store_quit_page_content": "Bạn có muốn thoát trang?",
  "ss_store_quit_page_title": "Dữ liệu sẽ không được lưu.",
  "ss_store_received_donation": "Nhận ủng hộ",
  "ss_store_received_donation_message": "Bật tính năng này, bạn sẽ cho phép fan gửi tiền ủng hộ. Thử tính năng thú vị này nhé!",
  "ss_store_received_virtual_gift": "Nhận quà tặng",
  "ss_store_received_virtual_gift_message": "Bật tính năng Nhận quà tặng và tắt tính năng Nhận ủng hộ. Bạn chắc chắn không?",
  "ss_store_replace_file": "Đổi video",
  "ss_store_save_course_error": "Lưu khóa học không thành công, vui lòng thử lại hoặc liên hệ hỗ trợ kĩ thuật",
  "ss_store_save_course_success": "Lưu khóa học thành công",
  "ss_store_save_error": "Cập nhật thất bại, Vui lòng thử lại sau.",
  "ss_store_save_questions_error": "Cập nhật thất bại, Vui lòng thử lại sau.",
  "ss_store_save_questions_successfull": "Cập nhật thành công",
  "ss_store_save_successfull": "Cập nhật thành công",
  "ss_store_section_default_title": "Bài 1: Giới thiệu",
  "ss_store_section_group_default_title": "Phần 1: Giới thiệu",
  "ss_store_setup_the_course": "Thiết lập khóa học",
  "ss_store_teacher_info_title": "Thông tin giảng viên",
  "ss_store_thumbnails_description": "Hình ảnh có kích thước tối đa 30MB",
  "ss_store_update_document_successfully": "Cập nhật thành công",
  "ss_store_update_section_successfully": "Cập nhật thành công",
  "ss_store_upload_avatar": "Tải lên ảnh đại diện",
  "ss_store_upload_fail_message": "Tải lên thất bại",
  "ss_store_upload_video": "Video",
  "ss_store_use_text": "Văn bản",
  "ss_store_validate_url": "URL không chính xác. Bạn cần nhập vào URL video youtube",
  "ss_store_video_description": "Bạn có biết Video quảng cáo khóa học giúp tăng 5 lần tỷ lệ học viên đăng ký khóa học? Tạo và tải lên video của bạn”",
  "ss_store_youtube_demo_video_url_place_holder": "Vui lòng nhập vào URL Youtube quảng cáo khóa học của bạn",
  "ss_success_label": "Thành công",
  "ss_time_draw_content_box_1": "Ngày phát sinh đơn hàng thành công",
  "ss_time_draw_content_box_2": "Ngày D+7",
  "ss_time_draw_content_box_3": "Ngày D",
  "ss_time_draw_content_box_4": "Sau 7 ngày, tính từ ngày phát sinh đơn hàng thành công, tiền sẽ được cộng vào số dư khả dụng",
  "ss_time_picker": "Thời gian",
  "ss_title_time_draw": "Thời gian có thể rút tiền",
  "ss_tod_reply_add_video_image_after": "video",
  "ss_tod_reply_add_video_image_before": "Thêm ảnh/",
  "ss_tod_reply_helper_add_video": "Để thực hiện thách thức, bạn cần up tối thiểu 1 video",
  "ss_tod_reply_img_incorrect_type": "Vui lòng chọn file là một trong các định dạng sau JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC",
  "ss_tod_reply_input_alias": "Nội dung trả lời",
  "ss_tod_reply_input_placeholder": "Hãy thành thật trả lời câu hỏi từ fan nhé!",
  "ss_tod_reply_placeholder_content_dare": "Giới thiệu ngắn gọn hoặc nhắn gửi đến Fan ở đây nhé!",
  "ss_tod_reply_reply_submit": "Gửi câu trả lời",
  "ss_tod_reply_reply_take_challenge": "Thực hiện thử thách",
  "ss_tod_reply_tooltip_max_size_video": "Dung lượng tối đa đối với video là 10GB",
  "ss_tod_reply_tooltip_max_size_video_image": "Dung lượng tối đa đối với ảnh là 4MB, đối với video là 10GB",
  "ss_tod_reply_video_img_incorrect_size": "Hình ảnh hoặc video vượt quá dung lượng cho phép.",
  "ss_tod_reply_video_incorrect_size": "Video vượt quá dung lượng cho phép",
  "ss_tod_reply_video_incorrect_type": "Vui lòng chọn file có định dạng MP4 hoặc MOV",
  "ss_tod_reply_wait_upload_video": "Video/ảnh đang được xử lý. Bạn chờ thêm chút nhé",
  "ss_tod_report_detail": "Chi tiết báo cáo",
  "ss_tod_response": "Cách thức trả lời",
  "ss_tod_setting_background": "Ảnh backgound",
  "ss_tod_setting_background_tooltip": "Chọn ảnh nền tỷ lệ 3:2 với dung lượng tối đa 4 MB",
  "ss_tod_setting_button_confirm_exit": "Thoát",
  "ss_tod_setting_content_success": "Bạn đã cài đặt tính năng Truth or Dare thành công.",
  "ss_tod_setting_dare_title": "Creator trả lời bằng tối thiểu 1 video",
  "ss_tod_setting_exit": "Thoát",
  "ss_tod_setting_field_invitation_tooltip": "Nội dung lời mời chào giới hạn tối đa 60 ký tự.",
  "ss_tod_setting_field_welcome_tooltip": "Nội dung lời kêu gọi fan thử thách sẽ tự động điền nếu bạn bỏ trống.",
  "ss_tod_setting_helper_service_1": "Creator có tối đa 15 ngày để trả lời hoặc thực hiện thách thức của bạn.",
  "ss_tod_setting_helper_service_2": "Nếu thách thức hoặc câu hỏi mà bạn không nhận được phản hồi, số tiền đã ủng hộ sẽ được hoàn trả đến bạn trong vòng tối đa 30 ngày.",
  "ss_tod_setting_invitation": "Nội dung mời chào thực hiện thử thách",
  "ss_tod_setting_invitation_placeholder": "Nhập nội dung mời fan tham gia",
  "ss_tod_setting_message_success": "Bạn đã cài đặt tính năng Truth or Dare thành công.",
  "ss_tod_setting_min_price_dare_alias": "Số tiền tối thiểu fan thanh toán đơn Dare",
  "ss_tod_setting_min_price_dare_example": "Ví dụ: 100,000",
  "ss_tod_setting_min_price_dare_note_v2": "Thanh toán tối thiểu là {{price}} ₫",
  "ss_tod_setting_preview": "Tiếp tục",
  "ss_tod_setting_push": "Đăng tải",
  "ss_tod_setting_success_TOD": "Thành công",
  "ss_tod_setting_text_edit": "Chỉnh sửa",
  "ss_tod_setting_title_service_1": "Thời gian phản hồi",
  "ss_tod_setting_title_service_2": "Thời gian hoàn tiền",
  "ss_tod_setting_tooltip_min_price_dare": "Nếu bạn không nhập, hệ thống sẽ tự động điền trường này theo giá trị mặc định",
  "ss_tod_setting_truth_title": "Creator trả lời bằng tin nhắn chữ",
  "ss_tod_setting_txt_btn": "Lời kêu gọi fan gửi thử thách",
  "ss_tod_setting_warning_text": "Cài đặt của bạn chưa được lưu. Bạn vẫn muốn thoát chứ?",
  "ss_tod_setup": "Thiết lập ngay",
  "ss_tod_start": "Bắt đầu thiết lập Truth or Dare",
  "ss_tod_status_alias_new": "Mới",
  "ss_tod_status_alias_pending": "Đang chờ",
  "ss_tod_the_number_of_times_fans_senttod": "Số lượt Fan gửi Truth or Dare",
  "ss_tod_title_guide_1": "Thời gian phản hồi",
  "ss_tod_title_guide_2": "Quyền lựa chọn của bạn",
  "ss_tod_title_guide_3": "Phương thức trả lời",
  "ss_tod_title_intro_1": "Thời gian phản hồi",
  "ss_tod_title_intro_2": "Quyền lựa chọn",
  "ss_tod_title_intro_3": "Phương thức trả lời",
  "ss_tod_tod_no": "Số TOD",
  "ss_tod_tooltip_min_price_dare": "Nếu bạn không nhập, hệ thống sẽ tự động điền trường này theo giá trị mặc định",
  "ss_tod_truth": "Truth",
  "ss_tod_truth_title": "Creator trả lời bằng tin nhắn chữ",
  "ss_tod_view": "Lượt xem",
  "ss_total_products_sold": "Tổng số sản phẩm đã bán",
  "ss_txt_btn_TOD": "Lời kêu gọi fan gửi thử thách",
  "ss_upload_file": "Tải lên",
  "ss_validate_image_post_max_size": "Ảnh quá dung lượng cho phép",
  "ss_video_processing_error": "Có lỗi trong quá trình xử lý, vui lòng thử lại",
  "ss_video_processing_text": "Video đang được xử lý. Quá trình này có thể mất một khoảng thời gian",
  "ss_validate_invitation_max_length": "Lời kêu gọi ủng hộ vượt quá ký tự cho phép, bạn kiểm tra lại nhé.",
  "ss_validate_message_thank_you_max_length": "Lời cảm ơn vượt quá ký tự cho phép, bạn kiểm tra lại nhé.",
  "ss_validate_not_found": "Trường này không được để trống",
  "ss_validate_value_invalid": "Giá trị nhập không hợp lệ.",
  "ss_value_helper_1": "Câu hỏi hoặc thử thách từ Fan cần được trả lời trong 15 ngày kể từ khi bạn nhận được thông tin. Quá ngày này, số tiền ủng hộ từ Fan sẽ được hoàn trả.",
  "ss_value_helper_2": "Bạn có thể chọn thực hiện hoặc từ chối trả lời câu hỏi hoặc thực hiện thử thách. Với mỗi lần thực hiện, bạn nhận được ủng hộ số tiền của Fan. Các lần thực hiện sẽ được công khai cho cộng đồng Fan của bạn.",
  "ss_value_helper_3": "Nếu Fan chọn Truth: Bạn chỉ được trả lời dạng chữ để truyền đạt đủ thông tin.\nNếu Fan chọn Dare: Bạn cần trả lời bằng dạng chữ và hình ảnh hoặc video.",
  "ss_value_helper_3_after": "Nếu Fan chọn Dare: Bạn cần thêm ít nhất 1 video để thực hiện thách thức.",
  "ss_value_helper_3_before": "Nếu Fan chọn Truth: Bạn chỉ được trả lời dạng chữ để truyền đạt đủ thông tin.",
  "ss_virtual_gift_home_about_gift_after": "về Virtual Gift",
  "ss_virtual_gift_home_about_gift_before": "Hiểu thêm",
  "ss_virtual_gift_home_about_virtual_gift": "Tìm hiểu về Virtual Gift",
  "ss_virtual_gift_home_close": "Đã hiểu",
  "ss_virtual_gift_home_count_gift_giving": "Lượt tặng quà",
  "ss_virtual_gift_home_intro_service_after": "dịch vụ",
  "ss_virtual_gift_home_intro_service_before": "Giới thiệu",
  "ss_virtual_gift_home_make_money_des": "Virtual Gift sẽ giúp bạn kiếm tiền cực dễ dàng từ những món quà tặng của Fan. \nNhững món quà này tùy là ảo, nhưng hoàn toàn có thể được quy đổi thành tiền mặt.",
  "ss_virtual_gift_home_make_money_title": "Kiếm tiền từ Virtual Gift như thế nào?",
  "ss_virtual_gift_home_order_report": "Danh sách quà tặng",
  "ss_virtual_gift_home_order_revenue": "Thu nhập ước tính của các đơn sau khi trừ các loại phí",
  "ss_virtual_gift_home_payment_des": "Hiện Passio đang tích hợp rất nhiều cổng thanh toán nội địa (Shopee Pay, Momo, thẻ ATM) \ncho đến quốc tế để Fan có thể thanh toán dễ dàng hơn.",
  "ss_virtual_gift_home_payment_title": "Fan có thể thanh toán bằng hình thức nào?",
  "ss_virtual_gift_home_refund_des": "Creator có thể đút túi 85% số tiền Fan tặng thông qua tính năng Virtual Gift do chỉ phải chịu \n15% phí, bao gồm 10% phí nền tảng Passio và 5% phí cổng thanh toán.",
  "ss_virtual_gift_home_refund_title": "Creator có thể nhận được bao nhiêu % số tiền Fan tặng?",
  "ss_virtual_gift_intro_report": "Virtual Gift",
  "ss_virtual_gift_intro_service_feature": "Giới thiệu về Virtual Gift",
  "ss_virtual_gift_intro_service_feature_text": "Virtual Gift là dịch vụ giúp Fan tặng cho Creator những món quà online với nhiều mệnh giá \ntiền khác nhau cùng lời nhắn thân thương thông qua Passio Page của họ.",
  "ss_virtual_gift_intro_service_make_money": "Kiếm tiền từ Virtual Gift",
  "ss_virtual_gift_intro_service_make_money_text": "Creator nhận càng nhiều quà từ Fan đồng nghĩa với việc thu nhập của bạn sẽ ngày càng cao. Đặc biệt, bạn có thể nhận những món quà online bất cứ khi nào, kể cả trong lúc ngủ.",
  "ss_virtual_gift_report_dont_have_gift": "Không có quà tặng nào trong thời gian này",
  "ss_virtual_gift_report_message_from_seller": "Lời nhắn bạn đã gửi",
  "ss_virtual_gift_report_message_response_failed": "Bạn gửi phản hồi thất bại",
  "ss_virtual_gift_report_message_response_successful": "Bạn gửi phản hồi thành công",
  "ss_virtual_gift_report_method_payment_atm": "Thẻ ATM - chuyển tiền {{param}} ngân hàng",
  "ss_virtual_gift_report_method_payment_credit_card": "Thẻ tín dụng/ {{param}} ghi nợ quốc tế",
  "ss_virtual_gift_report_overview": "Tổng quan",
  "ss_virtual_gift_report_payment_history": "Lịch sử thanh toán",
  "ss_virtual_gift_report_payment_method": "Phương thức thanh toán",
  "ss_virtual_gift_report_phone_number": "Số điện thoại",
  "ss_virtual_gift_report_ss_showing": "Đang hiển thị",
  "ss_virtual_gift_report_store_momo_wallet": "Momo Wallet - Ví điện tử",
  "ss_virtual_gift_report_store_order_buyer_message": "Người mua gửi tới bạn",
  "ss_virtual_gift_report_store_order_giver_message": "Người tặng gửi tới bạn",
  "ss_virtual_gift_report_store_order_payment_fee_tooltip": "Là phí do các cổng thanh toán tự động thu trên từng giao dịch. Mức phí phụ thuộc vào đối tác thanh toán và từng phương thức thanh toán.",
  "ss_virtual_gift_report_store_order_platform_fee_tooltip": "Là phí được tính theo phần trăm từ doanh thu phát sinh khi người dùng đặt mua thành công. Mức phần trăm có thể khác nhau tùy loại hình sản phẩm.",
  "ss_virtual_gift_report_store_order_revenue_tooltip": "Là khoản tiền (trước khi khấu trừ thuế nếu có) bạn sẽ nhận được sau khi lấy doanh thu trừ đi các khoản chi phí.",
  "ss_virtual_gift_report_store_order_total_income": "Doanh thu = Giá gốc/ Giá khuyến mại - Số tiền giảm giá(nếu có)",
  "ss_virtual_gift_report_vg_buyer": "Người tặng",
  "ss_virtual_gift_report_vg_error_upload": "Opps! Có sự cố trong quá trình tải dữ liệu. Bạn vui lòng thử tải lại trang nhé!",
  "ss_virtual_gift_report_vg_product": "Quà tặng",
  "ss_virtual_gift_report_vg_time_buy": "Thời gian tặng quà",
  "ss_voucher_add_button_label": "Thêm",
  "ss_voucher_add_product_btn": "Thêm",
  "ss_voucher_add_product_warning_cancel": "Thoát",
  "ss_voucher_add_product_warning_confirm": "Tiếp tục thêm sản phẩm",
  "ss_voucher_btn_add": "Lưu",
  "ss_voucher_cancel_add": "Hủy",
  "ss_voucher_cancel_add_product_btn": "Hủy",
  "ss_voucher_cancel_add_warning": "Thoát",
  "ss_voucher_continute_add": "Tiếp tục thêm mới",
  "ss_voucher_copy_error": "Sao chép mã thất bại",
  "ss_voucher_copy_success": "Sao chép mã thành công",
  "ss_voucher_creator": "Mã giảm giá của Creator",
  "ss_voucher_datepicker_title": "Ngày",
  "ss_voucher_error_code_required": "Vui lòng nhập vào tên mã giảm giá",
  "ss_voucher_error_end_date_soon_current_date": "Thời gian kết thúc phải muộn hơn thời gian bắt đầu",
  "ss_voucher_error_input_discount_amount_required": "Vui lòng nhập vào mức giảm giá",
  "ss_voucher_error_input_percent_discount_required": "Vui lòng nhập vào mức giảm giá",
  "ss_voucher_error_name_existed": "Mã giảm giá đã tồn tại",
  "ss_voucher_error_name_min_length": "Vui lòng nhập vào tối thiểu {{min}} ký tự",
  "ss_voucher_error_name_required": "Vui lòng nhập vào mã giảm giá",
  "ss_voucher_error_quantity_less_than_current": "Không thể giảm số lượt sử dụng của mã giảm giá đang diễn ra",
  "ss_voucher_error_quantity_required": "Vui lòng nhập vào Số lượt sử dụng",
  "ss_voucher_error_start_date_soon_current_date": "Thời gian bắt đầu phải muộn hơn thời gian hiện tại",
  "ss_voucher_exit_add": "Hủy bỏ thêm mới ?",
  "ss_voucher_exit_deleting_warning": "Các thay đổi của bạn sẽ không được lưu. Bạn có chắc chắn muốn thoát không?",
  "ss_voucher_label_appled": "Đã chọn",
  "ss_voucher_label_apply_all": "Áp dụng tất cả sản phẩm",
  "ss_voucher_label_date": "Hết hạn:",
  "ss_voucher_label_left": "đã sử dụng",
  "ss_voucher_label_total": "Tổng số",
  "ss_voucher_list_apply": "Sản phẩm áp dụng",
  "ss_voucher_no_quantity": "Không giới hạn",
  "ss_voucher_passio": "Mã giảm giá của Passio",
  "ss_voucher_placeholder_input_search": "Tên sản phẩm",
  "ss_voucher_search_button_label": "Tìm kiếm",
  "ss_voucher_timepicker_title": "Thời gian",
  "ss_voucher_title_select_product": "Thêm sản phẩm",
  "ss_voucher_turn_off_cancle_text": "Hủy",
  "ss_voucher_turn_off_confirm_cancel": "Kết thúc",
  "ss_voucher_turn_off_warning_content": "Bạn có chắc chắn muốn kết thúc Mã giảm giá này? Hành động không thể hoàn tác",
  "ss_voucher_turn_off_warning_title": "Kết thúc giảm giá",
  "ss_voucher_type_money": "Theo số tiền",
  "ss_voucher_type_money_label": "Theo giá tiền",
  "ss_voucher_type_percent": "Theo phần trăm",
  "ss_voucher_type_percent_label": "Theo phần trăm",
  "ss_voucher_warning_edit": "Hủy thêm sản phẩm?",
  "start_date_must_be_less_than_end_date": "Start date must be less than end date",
  "start_making_money": "Bắt đầu kiếm tiền",
  "status": "Trạng thái",
  "store_order_giver_message": "Người tặng gửi tới bạn",
  "store_order_total_income": "Là khoản doanh thu của đơn hàng",
  "sub1_empty_data": "Không có dữ liệu",
  "sub_account": "Sub account",
  "system_error": "Lỗi hệ thống",
  "tab_list_voucher_all": "Tất cả",
  "tab_list_voucher_end": "Kết thúc",
  "tab_list_voucher_running": "Đang diễn ra",
  "tab_list_voucher_upcoming": "Sắp diễn ra",
  "text_custom_range": "Tùy chỉnh khoảng thời gian",
  "text_last_month": "Tháng trước",
  "text_last_n_day": "{{n}} ngày gần nhất",
  "text_last_week": "Tuần trước",
  "text_this_month": "Tháng này",
  "text_this_quarter": "Quý này",
  "text_this_week": "Tuần này",
  "text_today": "Hôm nay",
  "text_yesterday": "Hôm \rqua",
  "ticket_cancel": "Hủy",
  "ticket_choose_file": "Chọn file",
  "ticket_choose_file_template": "Chọn file mẫu phù hợp với bạn",
  "ticket_confirm": "Xác nhận",
  "ticket_delete_row": "Xóa bản ghi?",
  "ticket_delete_row_content": "Xóa bản ghi ở đây nếu bạn đã sử dụng/bán ở nơi khác",
  "ticket_delete_row_success": "Xóa thành công bản ghi",
  "ticket_download_template": "Tải file mẫu",
  "ticket_error_first_column": "Không thể tải lên file. Mỗi dòng ở cột đầu tiên không được để trống",
  "ticket_error_length_text": "Không thể tải lên file. Tên cột tối đa 30 ký tự, Trường dữ liệu tối đa 255 ký tự",
  "ticket_instruct_template": "Lựa chọn file mẫu phù hợp với sản phẩm bạn muốn bán.Hệ thống hỗ trợ từ 1 cột dữ liệu đến 5 cột dữ liệu.Ví dụ: Bạn bán tài khoản ChatGPT thì sẽ chọn file 2 cột.",
  "ticket_list_template": "Danh sách file mẫu",
  "ticket_list_ticket_account_note": "Nhấn “tải lên” để tải danh sách sản phẩm bạn muốn bán.",
  "ticket_no_file_select": "Chưa có file được chọn",
  "ticket_product_account_max_size_upload_file": "Chọn file có dung lượng tối đa 3MB",
  "ticket_product_account_type_upload_file": "Định dạng cho phép xls, xlsx",
  "ticket_row": "Cột",
  "ticket_support_file_upload": "Hỗ trợ : xls, xlsx, tối đa: 3MB",
  "ticket_text_template": "File mẫu",
  "ticket_text_upload_file": "Tải lên file xls hoặc xlsx để cập nhật danh sách",
  "ticket_upload": "Tải lên",
  "ticket_upload_list": "Tải lên danh sách",
  "ticket_upload_success": "Tải lên danh sách thành công",
  "tickets_upload_list": "Tải lên danh sách",
  "time_picker": "Thời gian",
  "tod_file_format_incorrect": "File không đúng định dạng",
  "tod_helper_add_video": "Để thực hiện thách thức, bạn cần up tối thiểu 1 video",
  "tod_img_incorrect_type": "Vui lòng chọn file là một trong các định dạng sau JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC",
  "tod_list_challenge_label": "Danh sách thử thách",
  "tod_msg_required_video": "Bạn cần gửi video thực hiện thách thức",
  "tod_notification": "Bấm xem chi tiết từng thử thách để Chấp nhận hoặc Từ chối",
  "tod_perform": "Thực hiện",
  "tod_placeholder_content_dare": "Giới thiệu ngắn gọn hoặc nhắn gửi đến Fan ở đây nhé!",
  "tod_private": "Riêng tư",
  "tod_private_text": "Hiển thị riêng tư",
  "tod_public_text": "Hiển thị công khai",
  "tod_reply": "Trả lời",
  "tod_reply_input_alias": "Nội dung trả lời",
  "tod_reply_input_placeholder": "Hãy thành thật trả lời câu hỏi từ fan nhé!",
  "tod_reply_submit": "Gửi câu trả lời",
  "tod_reply_take_challenge": "Thực hiện thử thách",
  "tod_share_challenge": "Chia sẻ thử thách",
  "tod_share_link": "Share link",
  "tod_start_message": "Thêm ngay trên Passio Page để có tương tác với Fan của bạn và có \nnhiều thử thách hơn nhé!",
  "tod_status_alias_accepted": "Đã thực hiện",
  "tod_status_alias_completed": "Đã thực hiện",
  "tod_status_alias_expired": "Hết hạn",
  "tod_status_alias_new": "Mới",
  "tod_status_alias_refund": "Hoàn trả",
  "tod_status_alias_wait": "Đang chờ",
  "tod_status_private": "Chế độ riêng tư",
  "tod_status_private_des": "Fan của bạn sẽ không nhìn thấy thử thách khi ở chế độ này",
  "tod_status_private_reject": "Bạn đã từ chối thực hiện thử thách",
  "tod_time_remain": "Thời gian còn lại",
  "tod_time_sending": "Thời gian gửi",
  "tod_tooltip_max_size_video": "Dung lượng tối đa đối với video là 10GB",
  "tod_tooltip_max_size_video_image": "Dung lượng tối đa đối với ảnh là 4MB, đối với video là 10GB",
  "tod_video_format_incorrect": "Video không đúng định dạng",
  "tod_video_img_incorrect_size": "Hình ảnh hoặc video vượt quá dung lượng cho phép.",
  "tod_video_incorrect_size": "Video vượt quá dung lượng cho phép",
  "tod_video_incorrect_type": "Vui lòng chọn file có định dạng MP4 hoặc MOV",
  "tod_video_over_size": "Dung lượng video vượt quá quy định cho phép",
  "tod_view_detail": "Xem chi tiết",
  "tod_wait_upload_video": "Video/ảnh đang được xử lý. Bạn chờ thêm chút nhé",
  "tod_warning_reject_tod": "Sau khi từ chối, bạn không thể thực hiện thử thách này.",
  "tooltip_list_tod": "Bấm vào thử thách để xem chi tiết và quyết định Chấp nhận hay Từ chối.",
  "total": "Tổng",
  "total_commission": "Tổng hoa hồng",
  "total_donation": "Lượt ủng hộ",
  "total_order_brand_gift": "Số đơn hàng",
  "total_order_income_brand_gift": "Số brand có đơn",
  "total_order_income_tooltip_brand_gift": "Số brand phát sinh tối thiểu 1 đơn",
  "total_order_tod": "Thử thách",
  "total_order_tooltip_brand_gift": "Số đơn mua hàng từ Fan",
  "total_search_report_by_subs": "Có {{number}} kết quả được tìm thấy",
  "total_view_brand_gift": "Lượt xem",
  "total_view_tooltip_brand_gift": "Số lượt Fan vào xem mục Brand Gift",
  "traffic_link_is_duplicated": "Liên kết bị trùng lặp",
  "traffic_link_is_empty": "Nền tảng không có kênh",
  "truth_or_dare": "Truth Or Dare",
  "truth_or_dare_detail": "Chi tiết thử thách",
  "try_upload_again": "Thử lại",
  "tt_estimate_approved_commission": "Calculated based on Estimated Approved Rate of each campaign",
  "undefined": null,
  "unhide_product": "Bỏ ẩn",
  "unhide_product_fail": "Bỏ ẩn sản phẩm thất bại",
  "unhide_product_success": "Bỏ ẩn sản phẩm thành công",
  "unique_click_tooltip": "Số người truy cập từ nhiều thiết bị tới link affilate của bạn theo ngày",
  "unique_clicks": "Lượt clicks",
  "update_referral_error": "Cập nhật mã giới thiệu thất bại",
  "upload_fail": "Upload thất bại",
  "upload_file_exceed_limit": "Vui lòng chọn ảnh có kích thước nhỏ hơn 8000x8000 px",
  "upload_failed": "Tải lên thất bại",
  "upload_product": "Tải sản phẩm",
  "uploading": "Đang tải lên...",
  "user_guide": "Hướng dẫn",
  "user_information": "Thông tin tài khoản",
  "uv_commission": "UV Commission",
  "vg_buyer": "Người tặng",
  "vg_error_upload": "Opps! Có sự cố trong quá trình tải dữ liệu. Bạn vui lòng thử tải lại trang nhé!",
  "vg_income_tooltip": "Thu nhập quà tặng mà bạn nhận được",
  "vg_order_tooltip": "Đơn hàng quà tặng mà bạn nhận được",
  "vg_product": "Quà tặng",
  "vg_show_list_all_orders": "Danh sách đơn hàng",
  "vg_time_buy": "Thời gian tặng quà",
  "video_shoutout": "Video Shoutout",
  "voucher_create_new_button": "Tạo mã",
  "voucher_list": "Danh sách",
  "voucher_list_title": "Tạo mã giảm giá",
  "voucher_title": "Mã giảm giá",
  "yes": "Có"
} 
} 
export default vi