const  id =  {
 translation: {
  "6_40_characters_are_allowed": "Harap masukkan dari 6 hingga 40 karakter",
  "The_interval_must_be_less_than_days": "Interval harus kurang dari {{max}} hari",
  "accepted": "Tercapai",
  "action_has_not_been_performed": "Hal ini belum dilakukan. Tolong coba lagi",
  "add": "lainnya",
  "add_channel": "id_Thêm kênh",
  "add_cover_image": "Tambahkan cover foto",
  "add_image": "Tambahkan foto {{n}}",
  "add_new_product": "Buat Produk",
  "add_product": "Tambahkan produk",
  "add_store_new_section_group": "Tambahkan item baru",
  "atc_config": "Config",
  "atc_confirm": "Confirm",
  "atc_pause": "Pause",
  "atc_start": "Start",
  "atc_title": "Set up auto crawl",
  "campaign_detail_page_breadcumb": "Detil",
  "campaign_drawer_base_commission": "Komisi yang diterima saat fans mengklik link produk / campaign yang anda share. Kemudian sukses menyelesaikan pembelian atau checkout order di marketplace (Shopee/Lazada/Tokopedia/etc)",
  "campaign_drawer_brand_commission": "Komisi yang diterima saat fans mengklik link produk / campaign yang anda share. Kemudian menyelesaikan pembelian produk di store yang tercatat sebagai bonus brands campaign.",
  "campaign_drawer_exclusive_commission": "Komisi yang diterima saat fans mengklik link produk / campaign yang anda share. Kemudian menyelesaikan pembelian produk apa pun di store yang Exclusive bekerjasama dengan Ecomobi.",
  "campaign_ecommerce_campaign": "Campaign E -Commerce",
  "campaign_ecommerce_campaign_breadcumb": "Kampanye Lantai E -Commerce",
  "campaign_ecommerce_campaign_tooltip": "Campaign terhubung dengan store di marketplace (Shopee/ Lazada/ ...)",
  "campaign_empty_campaign_content": "Dengan cepat 👉 \"Lihat segalanya\" untuk menemukan 🙌 🙌 🙌",
  "campaign_empty_campaign_title": "Semua campaignyang menarik sedang menunggu Anda!",
  "campaign_exclusive_commission": "Exclusive commision",
  "campaign_filter": "Filter",
  "campaign_filter_Indonesia": "Indonesia",
  "campaign_filter_Lazada": "Lazada",
  "campaign_filter_Malaysia": "Malaysia",
  "campaign_filter_Philippines": "Filipina",
  "campaign_filter_Shopee": "Shopee",
  "campaign_filter_Singapore": "Singapura",
  "campaign_filter_Thailand": "Thailand",
  "campaign_filter_Vietnam": "Vietnam",
  "campaign_filter_find_campaign": "Cari Campaign",
  "campaign_filter_label.promotion_kit_all": "Semua",
  "campaign_filter_label.promotion_kit_cash": "Bonus",
  "campaign_filter_label.promotion_kit_cpc": "Hadiah klik Exclusive",
  "campaign_filter_label.promotion_kit_product": "Sampel produk",
  "campaign_filter_label.promotion_kit_voucher": "Kode diskon",
  "campaign_filter_text": "Filter:",
  "campaign_for_membership": "id_Dành cho thành viên",
  "campaign_for_membership_tooltip": "id_Chiến dịch tiếp thị dành riêng cho các Creators đã đăng ký gói Pro",
  "campaign_gift": "Paket preferensial",
  "campaign_gift_exclusive_click": "Hadiah klik Exclusive",
  "campaign_giif_commission": "Kondisi untuk menerima paket preferensial",
  "campaign_has_not_config_setting_onair_rules": "id_Liên kết không hợp lệ",
  "campaign_inventory_number": "Dan {{number}}",
  "campaign_list": "List Campaign",
  "campaign_max_address_input": "Masukkan maksimum {{word}} karakter",
  "campaign_max_character": "Maksimum {{word}} karakter",
  "campaign_max_phone_input": "Nomor telepon tidak valid",
  "campaign_monopoly": "Eksklusif",
  "campaign_no_campaign": "Tidak menemukan Campaign yang diinginkan 🤗",
  "campaign_not_exist": "Kampanye ini tidak ada",
  "campaign_noti_is_pending_campaign": "Request campaign sedang dalam proses",
  "campaign_number_per_unique_click": "{{number}} {{currency}} / unik klik",
  "campaign_other": "Lainnya",
  "campaign_policy": "Peraturan",
  "campaign_promotion": "Promosi",
  "campaign_promotion_kit_field_required": "Harap masukkan informasi ini",
  "campaign_promotion_kit_max_selected": "id_Chọn tối đa {amount} ưu đãi",
  "campaign_promotion_kit_notification": "You are not qualified for this offer package. Please check the qualifications, and contact support if you need assistance",
  "campaign_promotion_kit_selected": "id_Đã chọn",
  "campaign_receive_gift_from_brand": "Pilih Gift dari brands",
  "campaign_receive_info_sample_product": "Masukkan Informasi Penerima Sampel produk 👇👇",
  "campaign_redirect_link_shop": "Lihat brands",
  "campaign_request_offer_pending": "Pending",
  "campaign_request_offer_reject": "Pendaftaran Campaign",
  "campaign_request_offer_success": "BUAT LINK",
  "campaign_request_policy_first": "Harap baca informasi dengan cermat sebelum menyelesaikan versi pendaftaran dari brands. <br> (Dengan mengikuti program ini berarti setuju dengan seluruh peraturan yang berlaku): <br/> <br/>",
  "campaign_request_policy_second": "1. Jumlah peserta untuk Campaign ini terbatas, silakan baca persyaratan campaign dengan jelas dan teliti sebelum mendaftar untuk program dari brands berikut. <br/> 2. Anda perlu mengunggah konten yang sesuai dengan rentang waktu yang disetujui dan konten tersebut harus telah disetujui oleh brands. <br/> 3. Dengan insentif CPC, Anda perlu melampirkan link produk yang ditentukan oleh brands dengan menggunakan generate tool dari Ecomobi di konten yang dimaksud. Untuk Kol Tiktok, perlu untuk memastikan link produk telah dibuat melalui sistem Ecomobi dan dilampirkan di halaman bio atau Ecomobi landing page. Dilarang menghapus atau atur private mode untuk konten Campaign yang dimaksud selama 3 bulan setelah post (kecuali ada alasan yang sesuai dan beri arahan oleh KOL/Publisher manager tentang kasus Anda). <br/> 4. Jika KOL tidak menyelesaikan request selama batas waktu komitmen, KOL bertanggung jawab untuk membayar semua biaya yang dikeluarkan, termasuk nilai produk/voucher, biaya kartu, biaya pengiriman dan biaya distribusi kelahiran lainnya (jika ada). <br/> 5. Posting Anda dapat digunakan untuk tujuan promosi oleh brands, jika ada biaya penggunaan konten selain dari campaign, harap diinfokan. <br/> 6. Dilarang menjual kembali produk/voucher exclusive, mentransfer atau membatalkan pendaftaran selama campaign.",
  "campaign_request_promotion_kit": "Mengirim membutuhkan",
  "campaign_reset": "Buat lagi",
  "campaign_sample_product": "Sampel produk",
  "campaign_search_no_campaign": "Hasil tidak ditemukan",
  "campaign_send_quest_confirm_button": "Konfirmasi",
  "campaign_send_request_confirm": "Saya membaca semua informasi dengan jelas. Saya ingin mengkonfirmasi pendaftaran produk sampel, dan memberikan informasi sesuai dengan komitmen.",
  "campaign_send_request_policy": "Harap baca informasi dengan teliti sebelum menyelesaikan pendaftaran ke brands. <br> (Dengan mengikuti program ini berarti setuju dengan seluruh peraturan yang berlaku): <br/> <br/> 1. Jumlah peserta untuk mengikuti campaign ini terbatas dan harap baca dengan teliti seluruh benefit dan request dari brands. <br/> 2. Anda harus menyelesaikan request campaign sesuai dalam waktu yang disetujui dan mengirim konten atau request sampai disetujui oleh brands. <br/> 3. Dengan insentif CPC, Anda perlu melampirkan link produk yang sesuai request dari brands dengan menggunakan Ecomobi generate link. Untuk Kol Tiktok, perlu untuk memastikan link produk telah dibuat melalui sistem ECOMOBI ke BIO atau halaman yang diarahkan brands. Jangan hapus atau atur mode pribadi untuk konten yang disertakan dalam Campaign selama 3 bulan setelah post (kecuali ada alasan yang sesuai dan diberi tahu  KOL/publisher Manager terlebih dahulu tentang kasus Anda). <br/> 4. Jika KOL tidak menyelesaikan request selama durasi batas waktu komitmen, KOL bertanggung jawab untuk membayar semua biaya yang dikeluarkan, termasuk nilai produk/voucher, biaya kartu, biaya pengiriman dan biaya distribusi kelahiran lainnya (jika ada). <br/> 5. Postingan anda menjadi hak milik brands tidak termasuk untuk monetisasi",
  "campaign_shop_campaign": "Store Campaign",
  "campaign_shop_campaign_breadcumb": "Shop campaign",
  "campaign_shop_campaign_max_input": "Maksimum {{number}} klik unik",
  "campaign_shop_campaign_tooltip": "Link campaign terhubung dengan toko di marketplace (Shopee/Lazada/Tokopedia/dll)",
  "campaign_showing": "Menampilkan {{current}} / {{total}}",
  "campaign_sold_out": "Sudah habis",
  "campaign_time_active": "{{start_date}} - {{end_date}}}",
  "campaign_toastr_failed_promotion_kit": "Maaf! Campaign yang kamu request telah habis",
  "campaign_toastr_success_promotion_kit": "Request telah berhasil dikirimkan ke brands",
  "campaign_toastr_warning_promotion_kit": "{{SucessItem}}/{{{all}} sedang di review oleh brands",
  "campaign_total_campaign_show": "Ya {{number}} Campaign ditemukan",
  "campaign_unlimited_campaign": "Tidak terbatas",
  "campaign_upcoming": "Hampir mulai",
  "campaign_upto": "hingga",
  "campaign_upto_item_campaign": "Hingga",
  "campaign_upto_tooltip": "Upto {{commission}}",
  "campaign_validate_xss_message": "Info yang dimasukkan salah",
  "campaign_view_all_list": "Lihat semua",
  "campaign_web_campaign_breadcumb": "Website Campaign",
  "campaign_website_campaign": "Website Campaign",
  "campaign_website_campaign_tooltip": "Campaign terhubung dengan official website store",
  "cancel": "Batalkan",
  "cancel_create_product": "Apakah Anda ingin membatalkan membuat produk ini?",
  "cancel_edit_product": "Apakah Anda ingin membatalkan mengedit produk?",
  "cannot_read_content_from_clipboard": "Tidak dapat membaca konten dari clipboard",
  "channel_follower": "id_Lượt theo dõi",
  "channel_no_link": "id_Nền tảng không có kênh",
  "channel_not_enough_condition": "id_Nền tảng không đủ điều kiện",
  "check_condition_setting_traffic_source_is_invalid": "id_Liên kết không hợp lệ",
  "check_detail_report": "Lihat laporan detail",
  "check_onair_rule_traffic_source_is_invalid": "id_Liên kết không hợp lệ",
  "choose_a_form_to_post_experience": "id_Chọn hình thức lên bài nghiệm thu",
  "choose_a_form_to_post_experience_description": "id_Chọn kênh bạn sẽ lên bài nghiệm thu. Nếu kênh bạn cọn chưa có thông tin, vui lòng nhập thông tin kênh. Nếu kênh bạn chọn đã có dữ liệu, vui lòng bỏ qua.",
  "choose_another_channel": "id_Vui lòng thêm kênh hoặc chọn nền tảng khác",
  "choose_social": "Pilih sosial",
  "clicks": "Klik",
  "commission": "Komisi",
  "commission_passio": "Komisi",
  "commission_tooltip": "Total komisi muncul dari link affiliate anda",
  "commission_upto": "Komisi hingga",
  "confirm_cancel": "Perubahan yang sudah diatur tidak akan tersimpan",
  "confirm_new_password": "Konfirmasi Password",
  "content_reject_reason": "Isi",
  "conversion_rate_brand_gift": "id_Tỷ lệ chuyển đổi",
  "conversion_rate_tooltip_brand_gift": "id_Tỷ lệ chuyển đổi được tính bằng Số đơn thành công trên tổng lượt xem màn Brand Gift",
  "copied": "Salin",
  "copy_link": "Salin tautan",
  "copy_link_fail": "Copy link gagal",
  "copy_link_success": "Copy link berhasil",
  "course_manager": "Quản lý học viên",
  "course_member": "Tiến độ học viên",
  "cover_image": "Gambar sampul",
  "create_order_label": "Tanggal Diciptakan:",
  "create_product": "Buat Produk",
  "create_product_choose": "Pilih jenis produk yang ingin Anda tambahkan",
  "create_product_successfull": "Sukses membuat produk",
  "create_voucher_title": "Buat kode diskon",
  "delete_product": "Hapus produk",
  "delete_product_success": "Sukses menghapus produk",
  "delete_product_title": "Apakah Anda ingin menghapus produk?",
  "delete_product_upload_title": "Apakah Anda ingin menghapus data produk?",
  "digital_product": "Produk Digital",
  "digital_product_home": "Halaman Beranda",
  "digital_product_order_detail": "Detil order",
  "digital_product_order_report": "No Order Produk",
  "digital_product_overview": "keseluruhan",
  "digital_product_report": "Laporan Produk Digital",
  "digital_product_ss_payment_history": "Riwayat pembayaran",
  "digital_product_total_income": "Total pendapatan",
  "digital_product_total_income_tooltip": "Total uang (sebelum pemotongan pajak jika ada) yang akan Anda terima setelah mengambil total pendapatan dikurangi total pengeluaran dalam waktu yang Anda udah pilih.",
  "digital_product_total_products_sold": "Total produk telah dijual",
  "digital_products": "Produk Digital",
  "donated": "memberikan",
  "donation": "Donasi",
  "done": "selesai",
  "dont_have_gift": "Tidak ada Gift dalam saat ini",
  "dont_have_order": "Tidak ada order dalam saat ini",
  "down_to_bottom_label": "Down to the bottom",
  "dp_add_product": "Buat Produk",
  "dp_all": "Semua",
  "dp_avantage_course_product": "Keuntungan Produk Kursus di Passio:",
  "dp_buyer": "Pembeli",
  "dp_cancel_delete": "Membatalkan",
  "dp_cat_course": "Kursus",
  "dp_cat_course_tutorial": "Kursus",
  "dp_cat_exclusive_ebook": "Ebook/Dokumen",
  "dp_cat_online_consultion": "Konsultasi online 1-1",
  "dp_cat_photo": "Gambar",
  "dp_cat_random_product": "Produk apapun",
  "dp_cat_ticket_account": "Tiket/Akun",
  "dp_cat_video": "Video eksklusif",
  "dp_close_dialog": "Menutup",
  "dp_confirm_delete": "Konfirmasi hapus",
  "dp_content_cant_download": "Fitur ini hanya didukung di situs web",
  "dp_continue_editing": "Terus tambahkan produk",
  "dp_course_avantage_1": "Adil dan digunakan untuk banyak penggemar",
  "dp_course_avantage_2": "Buat pelajaran dengan cepat",
  "dp_course_avantage_3": "Batasi pengunduhan kuliah",
  "dp_course_avantage_4": "Mudah melacak pesanan, pendapatan",
  "dp_default_title_block": "Produk",
  "dp_delele_items": "Penghapusan produk",
  "dp_deleting_warning": "Apakah Anda yakin ingin menghapus produk ini?",
  "dp_edit_escape": "Melarikan diri",
  "dp_edit_set_up": "Pengeditan tampilan",
  "dp_error_price": "Harga promosi harus lebih kecil dari harga aslinya",
  "dp_exit_deleting_warning": "Perubahan Anda tidak akan disimpan. Apakah Anda yakin ingin melarikan diri?",
  "dp_exit_editing": "Produk Cancell?",
  "dp_explain_course_product": "Adalah solusi untuk pencipta, penggemar dapat mengajar dan belajar online untuk membantu menghemat biaya dan menghemat waktu",
  "dp_home": "Halaman Beranda",
  "dp_income": "Penghasilan",
  "dp_income_tooltip": "Jumlah sale Digital Produk",
  "dp_intro_content_1": "Bergantung pada kekuatan dan keinginan Anda, Passio menyediakan berbagai produk digital seperti foto, video, kursus online, ebook, tautan, ...",
  "dp_intro_content_2": "Dengan kurang dari 3 menit, Anda dapat mengonfigurasi dan menjual produk digital baru. Selain itu, setelah pembeli yang berhasil, Tho Tho, produk akan segera dikirim ke email mereka, membantu menyederhanakan pengiriman dan menghemat waktu",
  "dp_intro_content_title": "Produk digital dipahami sebagai produk online, tidak dipahami, berisi konten yang berguna untuk pengguna. Contoh: Video, foto, ebook, kursus online, ..",
  "dp_intro_title_1": "Beragam jenis produk untuk Anda pilih",
  "dp_intro_title_2": "Mudah dikelola produk dan pesanan dengan beberapa operasi",
  "dp_intruct_content_step_1": "Akses layar produk saya dan klik \"Buat produk\"",
  "dp_intruct_content_step_2": "Pilih jenis produk yang ingin Anda buat",
  "dp_intruct_content_step_3": "Isi informasi produk dan klik \"Simpan Produk\"",
  "dp_intruct_content_step_4": "Untuk mengonfigurasi blok produk digital, akses beranda dan tekan \"Setel Tampilan\" / \"Pengaturan Tampilan\"",
  "dp_intruct_set_up": "Instruksi untuk pengaturan",
  "dp_learn_dp": "Pelajari tentang Produk Digital",
  "dp_list_digital_product": "List",
  "dp_list_order": "Daftar pesanan",
  "dp_list_product": "Daftar",
  "dp_my_products": "Produk saya",
  "dp_order": "Memesan",
  "dp_order_tooltip": "Total Order Produk Digital",
  "dp_original_price": "Biaya",
  "dp_overview": "ringkasan",
  "dp_page_tittle_iframe": "Daftar Produk",
  "dp_page_tittle_setting_iframe": "Digital product",
  "dp_price_sale": "Harga promosi",
  "dp_product": "Produk",
  "dp_product_review": "Pratinjau produk",
  "dp_report": "Laporan",
  "dp_send_request_course_now": "Kirim permintaan sekarang",
  "dp_send_request_course_success": "Permintaan yang berhasil",
  "dp_send_request_course_success_confirm": "Menutup",
  "dp_send_request_course_success_content": "Passio akan menghubungi Anda dalam waktu terpendek jika permintaan disetujui",
  "dp_set_up": "Pengaturan tampilan",
  "dp_set_up_message": "Pilih tombol di bawah untuk beralih ke halaman Passio dan konfigurasikan blok produk digital",
  "dp_show_list_all_orders": "List Orders",
  "dp_step_1": "Langkah 1",
  "dp_step_2": "Langkah 2",
  "dp_step_3": "Langkah 3",
  "dp_step_4": "Langkah 4",
  "dp_system_course_free": "Jika Anda tidak memasukkan \"harga\", \"harga asli\", sistem akan memahami kursus ini gratis",
  "dp_time_buy": "Waktu order",
  "dp_title_cant_download": "File tidak dapat diunduh",
  "dp_title_request_course": "Kursus Pengenalan Produk",
  "dp_waitlist_pending": "Tunggu permintaan persetujuan",
  "dp_what_digital_product": "Apa itu produk digital?",
  "dp_what_is_the_course_product": "Apa kursusnya?",
  "ecm_advertiser_method": "Bentuk iklan",
  "ecm_commission_policy": "Kebijakan Komisi",
  "ecm_cookie_policy": "Kebijakan Cookies",
  "ecm_note": "Catatan penting",
  "ecm_order_recording": "Bagaimana order dihitung",
  "eco_regards": "Salam,",
  "ecomobi_generate_link_tool": "Ecomobi Generate Link Tool",
  "ecomobi_team": "Tim Ecomobi",
  "edit_product": "Mengedit produk",
  "edit_voucher_title": "Ubah Kode Diskon",
  "error": "Error",
  "error_500": "Sistem kelebihan, silakan coba nanti",
  "error_active_account_msg": "Ups! Ada masalah dalam proses aktifkan akun. Silakan hubungi Technical Support",
  "error_backhome": "Kembali ke halaman utama",
  "error_image_size": "Kapasitas gambar melebihi dari yang diizinkan",
  "error_incorrect_image_format": "Format foto salah",
  "error_input_name_required": "Masukkan nama produk",
  "error_input_price_required": "Masukkan harga produk",
  "error_input_required": "Anda belum memasukkan informasi",
  "error_invalid_redirect_url": "Harap masukkan format link dengan benar",
  "error_page_msg": "Halaman tidak ditemukan",
  "error_page_note_found": "Halaman tidak ditemukan",
  "error_pdf_size": "Ebook/PDF Mengunggah Kapasitas File melebihi dari yang diizinkan",
  "error_thumbnail_size": "Kapasitas data foto melebihi dari yang diizinkan",
  "error_thumbnail_size_dp": "Silakan unggah file dengan ukuran maksimal 30MB",
  "error_video_size": "Kapasitas video melebihi dari yang diizinkan",
  "error_zip_size": "Kapasitas file zip melebihi dari yang diizinkan",
  "free_product": "Produk gratis",
  "go_to_top_label": "Go to the top",
  "hide_product": "Sembunyikan produk",
  "hide_product_fail": "Gagal menyembunyikan produk",
  "hide_product_success": "Sukses menyembunyikan produk",
  "history_link_appear": "Riwayat tautan Anda akan muncul di sini",
  "home": "Home",
  "hot_campaigns": "Campaign utama",
  "image": "Foto {{n}}",
  "income": "Pendapatan",
  "income_label": "Pendapatan:",
  "incorrect_file_format": "Format file salah",
  "input_select_all": "Semua",
  "instruc_on_virtual_gift": "👉🏻 Aktifkan fitur ini, Anda akan mengizinkan fans untuk mengirimkan berbagai hadiah. Dan hadiah tersebut nantinya bisa Anda tukar dengan uang. Aktifkan fitur ini sekarang!",
  "kol_edit_theme": "Perbaiki tema",
  "label.promotion_kit_all": "Semua",
  "label.promotion_kit_cash": "Bonus",
  "label.promotion_kit_cpc": "Hadiah klik Exclusive",
  "label.promotion_kit_product": "Sampel produk",
  "label.promotion_kit_voucher": "Kode diskon",
  "label_special_kol": "Khusus: Bagi Influencer baru (pemula),",
  "last_note_condition": "*Jika terjadi perselisihan, keputusan Ecomobi adalah final.",
  "link_channel": "id_Link nền tảng",
  "link_history": "Riwayat link",
  "link_refer_friends": "Tautan untuk teman referral:",
  "link_video": "Link Video",
  "link_youtube_invalid": "Tautan tidak valid",
  "link_youtube_note": "Masukkan link video di sini",
  "list_service": "List Service",
  "make_money_with_passio": "Menghasilkan uang dengan Passio! Yayyy",
  "make_money_with_passio_content": "Mulai jalankan Campaign dengan Passio sekarang untuk meningkatkan pendapatanmu!",
  "menu": "Menu",
  "message_from_seller": "Pesan yang Anda Kirim",
  "message_from_you": "Pesan dari Anda",
  "message_from_you_placeholder": "Isi konten yang Anda ingin kirim ke pembayar di sini. Itu bisa berupa tautan rahasia untuk pelanggan, kata sandi untuk membuka file, informasi ruang zoom, ... atau bahkan video khusus. \r\nIni bisa diisi dengan link bagi pembeli untuk mengakses kata sandi untuk membuka file, info zoom room, ... atau bahkan video.\r\nDan jangan lupa untuk berterima kasih kepada pelanggan karena mendukung produk/layanan Anda!\"",
  "move_down_label": "Move down",
  "move_to_label": "Move to",
  "move_up_label": "Move up",
  "my_empty_products_content": "Tambahkan produk kreatif Anda untuk mulai menghasilkan pendapatan dengan Ecomobi ya!",
  "my_empty_products_title": "Mulailah membangun toko Anda! Yayyy",
  "my_page": "My Passio",
  "my_request": "My request",
  "my_request_acceptance": "Penerimaan",
  "my_request_close": "Tutup",
  "my_request_delivered": "Telah menerima barang",
  "my_request_delivery_confirm_note": "Konfirmasi telah menerima sampel produk dan tidak memerlukan pengembalian produk",
  "my_request_error_file_size": "Ukuran gambar maksimum 5 MB",
  "my_request_error_file_type": "Persyaratan Format Gambar: JPeg, JPG, PNG, GIF",
  "my_request_error_required": "Harap berikan informasi ini",
  "my_request_error_url_match": "Harap masukkan format link dengan benar",
  "my_request_feedback": "Feedback",
  "my_request_feedback_placeholder": "Tolong kabari kami jika Anda tidak menerima produk sampel atau Anda mengalami masalah dengan produk sampel ini.",
  "my_request_link_input_placeholder": "Masukkan link penerimaan",
  "my_request_max_result_item": "Setiap kali hanya bisa mengirimkan maksimal 20 tes untuk 1 paket preferensial",
  "my_request_not_exist": "Permintaan ini tidak ada",
  "my_request_reason": "Alasan",
  "my_request_reason_label": "Evaluasi Penerimaan",
  "my_request_save_fail": "Ups! Ada masalah dalam pemrosesan data. Silakan coba lagi nanti!",
  "my_request_save_successfull": "Sukses menyimpan",
  "my_request_send": "Mengirim",
  "my_request_send_feedback_success": "Feedback Anda telah dikirim",
  "my_request_send_result": "Kirim Hasil",
  "my_request_traffic_placeholder": "Komunikasi",
  "my_request_watch_all": "Lihat semua ({{sum}})",
  "new_password": "Password baru",
  "next": "Next",
  "no": "Tidak",
  "no_campaign": "Tidak ada persyaratan 🤗",
  "no_result": "Tidak ketemu",
  "note_change_condition": "*Ecomobi berhak untuk mengubah peringkat dan format hadiah tanpa pemberitahuan sebelumnya.",
  "notice_bonus_for_user": "PENGUMUMAN BONUS LEVEL BAGI {{publisher_group}}",
  "notification": "Pemberitahuan",
  "notification_back_to_previous": "kembali ke halaman sebelumnya",
  "notification_breadcumb": "Pemberitahuan",
  "notification_campaign": "Kampanye",
  "notification_detail_page_breadcumb": "Detail pemberitahuan",
  "notification_empty_campaign": "Belum ada kampanye",
  "notification_empty_news": "Belum ada kabar",
  "notification_empty_payment": "Belum ada pembayaran",
  "notification_news": "Berita",
  "notification_not_exist": "Notifikasi ini tidak ada",
  "notification_payment": "Pembayaran",
  "notification_read_all": "tandai semua telah dibaca",
  "off_virtual_gift_noti": "Anda telah mematikan fitur Gift dari fans",
  "offer_package": "Paket Promosi",
  "on_virtual_gift_noti": "Sukses mengaktifkan fitur Gift",
  "or": "Atau",
  "order": "Order",
  "order_history": "Riwayat Order",
  "order_id": "Order code",
  "order_passio": "Order",
  "order_payment_fee": "Biaya Pembayaran",
  "order_platform_fee": "Biaya platform",
  "order_response": "Feedback",
  "order_revenue": "Pendapatan",
  "order_tooltip": "Jumlah pesanan telah dipesan melalui link affiliate anda",
  "passio_page": "Passio Page",
  "password_confirmation": "Konfirmasi Password",
  "pay_available_balances": "Saldo tersedia",
  "payment_agree": "Setuju",
  "payment_button_request_pay": "Request Withdraw",
  "payment_create_request_failed": "Gagal",
  "payment_create_request_success": "Selesai",
  "payment_created_request_payment": "Request Withdraw",
  "payment_info_bank_account_name_not_same": "Masukkan nama lengkap yang sama dengan nama di KTP",
  "payment_info_description_file_card": "Lampirkan 2 foto kartu ID Anda termasuk bagian depan dan bagian belakang. Minta Mengunggah gambar yang jelas",
  "payment_info_error_message": "Catatan pembayaran belum berhasil disimpan. Harap perbarui nanti!",
  "payment_info_field_is_required": "Harap masukkan informasi ini",
  "payment_info_full_name": "Nama lengkap",
  "payment_info_id_card": "Masukkan KTP/Paspor",
  "payment_info_place_holder_adress_payment": "Masukkan Alamat Pembayaran Anda",
  "payment_info_placeholder_bank_branch": "Masukkan Cabang Bank",
  "payment_info_placeholder_bank_fullname": "Masukkan nama pemilik akun Anda",
  "payment_info_placeholder_bank_name": "Masukkan Nama Bank penerima",
  "payment_info_placeholder_card_date": "Waktu penerbitan KTP",
  "payment_info_placeholder_family_card_name": "Masukkan nama kartu keluarga",
  "payment_info_placeholder_family_card_number": "Masukkan nomor kartu keluarga",
  "payment_info_placeholder_id_card": "Dikeluarkan oleh",
  "payment_info_placeholder_number_bank_account": "Masukkan nomor rekening bank",
  "payment_info_placeholder_personal_tax": "Masukkan Kode Pajak Pribadi",
  "payment_info_placeholder_postal_code": "Masukkan Kode Pos Anda",
  "payment_info_please_enter_at_most_characters": "Harap masukkan karakter maksimum {{n}}",
  "payment_info_please_enter_number": "Harap masukkan hanya digit (0-9)",
  "payment_info_success_message": "Catatan pembayaran telah berhasil diperbarui. Harap tunggu otentikasi dalam 7 hari.",
  "payment_info_title_card_back": "Bagian belakang KTP",
  "payment_info_title_card_front": "Bagian depan KTP",
  "payment_info_title_upload_image": "Unggah gambar",
  "payment_infor_payment_data": "Info Pembayaran",
  "payment_infor_personal_infor": "Informasi pribadi",
  "payment_menu": "Payment",
  "payment_message_not_update_information": "Akun Anda masih kurang informasi pribadi atau info pembayaran yang belum lengkap. Harap diisi lengkap infonya agar dapat melakukan proses withdraw!",
  "payment_message_note": "Catatan: Kami perlu 7 hari kerja untuk proses verifikasi info pembayaran",
  "payment_message_withdraw_failed": "Request withdraw gagal. Harap coba lagi nanti!",
  "payment_message_withdraw_sending": "Request withdraw sedang diproses. Silakan tunggu beberapa saat...",
  "payment_message_withdraw_success": "Request withdraw telah berhasil dibuat. Perkiraan tanggal pembayaran: {{date}}.",
  "payment_pay_available_balances": "Saldo yang tersedia",
  "payment_pay_minimum_drawable_balance": "Minpay: {{money}}",
  "payment_pending_message": "Request withdraw sedang menunggu approval",
  "payment_support_text": "Kami bisa bantu membayar semua pendapatan di akun dalam 1 transaksi.",
  "payment_title": "Pembayaran",
  "payment_total_money": "Jumlah",
  "payment_update_information": "Update informasi",
  "payment_withdraw": "Tarik Saldo",
  "payment_withdraw_to": "Withdraw",
  "pending": "Tertunda",
  "pending_notify_msg_my_request": "Anda akan memiliki kesempatan untuk menerima paket preferensial tambahan jika brands menyetujui",
  "placeholder_reject_content": "Masukkan alasan Anda menolak permintaan ini",
  "placeholder_response_virtual_gift": "Silakan kirim pesan balasan kepada pemberi gift ini",
  "platform": "Platform",
  "please_enter_1_in_2_fields": "id_Vui lòng cung cấp 1 in 2 thông tin này",
  "please_enter_correct_link": "Masukkan tautan {{traffic_source}} Anda dengan benar",
  "please_using_letters_a_z_and_number_0_9": "Harap hanya masukkan huruf (a-z) dan nomor (0-9)",
  "price": "Harga",
  "price_label": "Harga",
  "product": "Produk",
  "product_description": "Deskripsi Produk",
  "product_description_placeholder": "Jelaskan konten layanan/produk yang Anda berikan.",
  "product_name": "Nama produk",
  "product_review_photo": "Preview foto produk",
  "product_select_upload_type_suggest": "Anda klik 1 dari 2 jenis produk berikut 👇👇👇",
  "product_type": "Tipe produk",
  "product_upload_choose": "Unggah produk apa pun",
  "product_upload_file_suggest": "Anda dapat menjual produk apa pun sebagai file digital (foto, video, ebook, zip, ...). Biarkan kreativitas Anda melambung!",
  "product_upload_url_suggest": "Ini adalah produk berupa konsultasi (Cicking) langsung via aplikasi video call seperti Zoom, Google Meet, dll. Goods! ✍🏻",
  "product_url_choose": "Redirect URL",
  "profile": "Halaman pribadi",
  "profile_account_infomation": "Info Akun",
  "profile_account_name": "Nama pemilik rekening",
  "profile_account_number": "Nomor rekening",
  "profile_address": "Alamat",
  "profile_bank": "Nama bank",
  "profile_bank_branch": "Cabang bank",
  "profile_change_password": "Ubah Password",
  "profile_change_password_fail": "Kata sandi belum diubah. Tolong lakukan nanti.",
  "profile_change_password_note": "Anda harus memilih Password yang kuat dan tidak digunakan di tempat lain",
  "profile_change_password_success": "kata sandi anda berhasil diubah",
  "profile_change_phone_number_note": "Harap gunakan nomor telepon Anda sendiri untuk mendapatkan bantuan secara langsung.",
  "profile_confirm": "Konfirmasikan",
  "profile_confirm_password_error": "Berbeda dengan kata sandi baru!",
  "profile_date_of_birth": "Tanggal lahir",
  "profile_description_verify_success": "Jika Anda ingin berubah informasi pembayaran, silakan hubungi staff kami",
  "profile_description_verify_warning": "Waktu verifikasi maksimum selama 7 hari",
  "profile_email": "Email",
  "profile_error_channel_format": "Harap masukkan format tautan {{channel}} yang benar",
  "profile_error_duplicate_channel": "Akun telah ada",
  "profile_error_max_length": "Harap masukkan maksimum {{max}} karakter",
  "profile_error_min_length": "Harap masukkan minimal {{min}} karakter",
  "profile_error_number_mobile": "Harap masukkan hanya digit (0-9)",
  "profile_family_card_name": "Nama kartu keluarga",
  "profile_family_card_number": "Nomor Kartu Keluarga",
  "profile_field_required": "Harap masukkan informasi ini",
  "profile_file_card": "Foto KTP",
  "profile_file_family_card_image": "Gambar kartu keluarga",
  "profile_file_tax_id_image": "Gambar foto NPWP",
  "profile_followers_required": "Silakan masukkan jumlah pengikut",
  "profile_full_name": "Nama lengkap",
  "profile_full_name_suggest": "Masukkan nama lengkap yang sama dengan nama di KTP",
  "profile_gender": "Jenis Kelamin",
  "profile_gender_female": "Perempuan",
  "profile_gender_male": "Laki-laki",
  "profile_gender_other": "Lainnya",
  "profile_id_number": "No KTP / Kartu Identitas",
  "profile_id_number_created_at": "Tanggal Penerbitan KTP",
  "profile_id_number_place": "Tempat Penerbitan KTP",
  "profile_image_id": "Foto KTP",
  "profile_image_id_suggest": "Lampirkan 2 foto kartu ID Anda: bagian depan, bagian belakang. Minta upload gambar yang jelas.",
  "profile_industry": "Kategori",
  "profile_industry_placeholder": "Pilih Kategori Favorit Anda",
  "profile_information": "Informasi",
  "profile_input_facebook_placeholder": "Masukkan link channel Facebook Anda",
  "profile_input_instagram_placeholder": "Masukkan link channel Instagram Anda",
  "profile_input_lemon8_placeholder": "Masukkan saluran Lemon8 Anda",
  "profile_input_line_placeholder": "Masukkan link channel Anda",
  "profile_input_tiktok_placeholder": "Masukkan link channel Tiktok Anda",
  "profile_input_twitter_placeholder": "Masukkan link channel Twitter Anda",
  "profile_input_youtube_placeholder": "Masukkan link channel YouTube Anda",
  "profile_marital_status": "Status Perkawinan",
  "profile_matrial_married": "Kawin",
  "profile_matrial_other": "Lainnya",
  "profile_matrial_single": "Belum Kawin",
  "profile_new_password_error": "Kata sandi baru tidak bisa seperti kata sandi lama",
  "profile_new_phone_number": "Nomor handphone baru",
  "profile_old_password_error": "Kata sandi ini tidak benar",
  "profile_password": "Password",
  "profile_payment_verification_status": "Status verify pembayaran",
  "profile_person_info_fail_message": "Catatan pribadi belum berhasil disimpan. Harap perbarui nanti!",
  "profile_person_info_success_message": "Catatan kwitansi Anda telah berhasil dikonfirmasi.",
  "profile_personal_tax_code": "Nomor Pokok Wajib Pajak (NPWP)",
  "profile_personal_tax_code_had": "Yes",
  "profile_personal_tax_code_had_not": "No",
  "profile_personal_tax_code_helper": null,
  "profile_personal_tax_code_link": "here.",
  "profile_personal_tax_code_suggest": "en_Bạn có thể tra cứu mã số thuế cá nhân",
  "profile_phone_number": "No Handphone",
  "profile_postal_code": "kode Pos",
  "profile_save_success": "Anda telah memperbarui informasi yang berhasil",
  "profile_social_channel_followers": "Jumlah followers",
  "profile_social_channel_label": "Social platform link",
  "profile_social_channel_placeholder": "Enter quantity",
  "profile_social_media_channel": "Sosial Media Anda",
  "profile_social_media_channel_note": "Harap tambahkan setidaknya 1 link channel jaringan sosial Anda",
  "profile_tab_account": "Akun",
  "profile_tab_payment_info": "Informasi Pembayaran",
  "profile_tab_user_info": "Informasi Pribadi",
  "profile_update_mobile_success": "Nomor handphone telah berhasil di update",
  "profile_upload_avatar_success": "Avatar berhasil diperbarui",
  "profile_username": "Username",
  "profile_verify_incomplete": "Belum selesai",
  "profile_verify_not_complete": "Belum selesai",
  "profile_verify_not_provided": "Tidak tersedia",
  "profile_verify_pending": "Sedang diverify",
  "profile_verify_success": "Sudah verify",
  "profile_verify_waiting": "Mohon tunggu verify",
  "proflie_update_mobile_fail": "Nomor handphone gagal di update, coba lagi nanti",
  "public_product": "Penerbitan produk",
  "public_product_note": "👉🏻 Nyalakan fitur ini, produk digital Anda akan ditampilkan di My Passio.",
  "quick_response": "Feedback segera",
  "random_product": "Buat produk apa pun",
  "rank_bonus": "Bonus Rank",
  "rank_info": "Rank Info",
  "read_less": "Sembunyikan",
  "read_more": "Lihat lainnya",
  "reason": "Alasan",
  "receive_gift_from_customers": "Terima gift dari fans mu 😊",
  "receiver_address": "Alamat penerima",
  "receiver_full_name": "Nama lengkap penerima",
  "receiver_phone": "No Telpon penerima",
  "recent_gift": "Hadiah Terbaru",
  "recent_order": "Order terbaru",
  "redirect_url_note": "Masukkan link yang akan digunakan untuk mengakses ke konten yang Anda jual. E.g: Google drive, video YouTube atau undangan zoom, ...",
  "redirect_url_placeholder": "URL ke redirect pembeli Anda setelah melakukan pembelian",
  "referral": "Undang Teman",
  "referral_achievement": "Pencapaian",
  "referral_bonus_amount": "Bonus",
  "referral_bonus_approved": "disetujui",
  "referral_bonus_pending": "tertunda",
  "referral_conditions": "Syarat",
  "referral_friend": "Teman Anda",
  "referral_join_passio": "Bergabunglah Passio dengan {{name}}",
  "referral_method": "Metode",
  "referral_save_code": "Simpan",
  "referral_timeline": "Waktu berlaku",
  "referral_tooltip_bonus_amount": "Total bonus Anda telah dikonfirmasikan",
  "referral_tooltip_fixed_bonus": "Jumlah bonus",
  "referral_tooltip_total": "Jumlah total orang yang direferral telah dikonfirmasikan",
  "referral_total": "Total orang direferral",
  "referral_total_bonus_amount": "Total Bonus",
  "referral_update_success": "Kode Referral telah diperbarui",
  "register_select_channel": "Traffic Source",
  "reject_reason": "Alasan penolakan",
  "rejected": "Ditolak",
  "rejected_notify_msg_my_request": "Halo, terima kasih telah mendaftar untuk kampanye! Saat ini profil Anda tidak terlalu cocok dengan merek dan produk. Sampai jumpa di kampanye berikutnya! Semoga harimu menyenangkan!",
  "reload": "Muat ulang",
  "report": "Laporan",
  "report_affiliate": "Affiliate",
  "report_affiliate_breadcrumbs": "Laporan Affiliate",
  "report_affiliate_general": "Laporan Umum",
  "report_affiliate_general_orders": "Orders",
  "report_by_link": "Laporan berdasarkan link",
  "report_by_sub": "Laporan oleh Sub",
  "report_commission": "Komisi",
  "report_digital_product_breadcrumbs": "Laporan Digital Produk",
  "report_general_report": "Laporan Umum",
  "report_virtual_gift_breadcrumbs": "Laporan Gift",
  "request_has_expired": "Permintaan yang sudah lewat",
  "required_field_code": "Silakan masukkan Kode Referral",
  "reset": "Reset",
  "responsed": "Dibalas",
  "results": "Hasil",
  "reviewed": "Tidak tercapai",
  "reviewed_multi_result_infinity_end_date_notify_msg_my_request": "Anda memiliki {{count}} yang tidak sesuai syarat. Silakan kirim penerimaan segera untuk menyelesaikan campaign!",
  "reviewed_multi_result_notify_msg_my_request": "Anda memiliki {{count}} hasil yang tidak memenuhi syarat. Harap kirim ulang sebelum {{end_date}} untuk menyelesaikan kampanye!",
  "reviewed_one_result_infinity_end_date_notify_msg_my_request": "Hasil Anda tidak memenuhi syarat. Silakan kirim tes kembali segera untuk menyelesaikan kampanye!",
  "reviewed_one_result_notify_msg_my_request": "Hasil Anda tidak memenuhi syarat. Harap kirim kembali tes sebelum {{end_date}} untuk menyelesaikan kampanye!",
  "sale_amount": "Jumlah Sales",
  "save_channel": "Menyimpan",
  "save_product": "Simpan produk",
  "save_successfull": "Sukses menyimpan",
  "select_date": "id_Chọn thời gian",
  "select_date_range": "Pilih periode",
  "selected_date_must_be_less_than_current_date": "Tanggal yang dipilih harus sebelum dari tanggal saat ini",
  "send_acceptance": "Kirim ke penerimaan",
  "send_feedback": "Feedback segera",
  "send_request": "kirim permintaan",
  "setting_dp_add": "Lagi",
  "setting_dp_cancle": "Membatalkan",
  "shouout_empty_2_text": "Silakan klik \"Buat kode\" untuk menambahkan yang baru",
  "shouout_empty_text": "Anda saat ini tidak memiliki persyaratan",
  "shouout_explore_now": "Jelajahi sekarang",
  "shouout_text_start_create": "Mulailah membuat teriakan",
  "shout_out": "Shoutout",
  "shoutout_about_service_description": "Video Shoutout adalah layanan yang memungkinkan penggemar untuk mengirim ke persyaratan pencipta untuk video yang dipersonalisasi untuk acara -acara tertentu seperti ulang tahun, kelulusan, selamat, ... Pencipta akan menanggapi persyaratan tersebut dengan satu dengan satu dengan satu dengan satu. Video. Video.",
  "shoutout_about_service_title": "Pengantar Layanan",
  "shoutout_accept": "Mengonfirmasi",
  "shoutout_accepted": "Dibuat",
  "shoutout_answer": "Bagaimana menjawab",
  "shoutout_answer_method_description": "Jika Anda menerima permintaan penggemar pencipta, Anda perlu membalas dengan video",
  "shoutout_answer_method_title": "Metode jawaban",
  "shoutout_breadcrumb": "Video Shoutout",
  "shoutout_btn_content": "Konten tombol",
  "shoutout_button_back": "Kembali",
  "shoutout_button_share": "Membagikan",
  "shoutout_cancel": "Batalkan",
  "shoutout_cancel_upload": "Membatalkan",
  "shoutout_change_file": "Ubah foto/video",
  "shoutout_change_file_video": "Ubah video",
  "shoutout_confirm_cancle": "Tidak",
  "shoutout_confirm_no": "Tidak",
  "shoutout_confirm_request_warning": "Setelah menekan video \"dengan\" akan dikirim ke penggemar. Apakah Anda yakin tindakan ini?",
  "shoutout_confirm_yes": "Memiliki",
  "shoutout_content_step_1": "Pilih \"Balas\" atau \"Tolak\" untuk menanggapi Fans Anda",
  "shoutout_content_step_2": "Rekam langsung dari HP Anda atau unggah video untuk menjawab permintaan,durasi video maksimal 90 detik",
  "shoutout_content_step_3": "Klik \"Konfirmasi\" maka video Anda akan dikirim dan tidak dapat diedit.",
  "shoutout_continue_editing": "Lanjutkan mengedit",
  "shoutout_copy_link_fail": "Salin Kegagalan Tautan",
  "shoutout_copy_link_success": "Salin tautan dengan sukses",
  "shoutout_create_request": "Buat segera",
  "shoutout_create_shoutout_btn": "Mulailah membuat teriakan",
  "shoutout_decline": "Tolak",
  "shoutout_description": "Konten Pendahuluan",
  "shoutout_description_modal_upload": "Harap unggah atau rekam video untuk menjawab permintaan penggemar Anda",
  "shoutout_description_placeholder": "Masukkan konten Pendahuluan",
  "shoutout_description_request": "Konten diperlukan",
  "shoutout_download_success": "Unggah video ke Success",
  "shoutout_edit": "Edit",
  "shoutout_edit_btn": "Edit Shoutout",
  "shoutout_error_only_video_type": "Harap unggah ke file sebagai salah satu format MP4, MOV",
  "shoutout_error_price_required": "Masukkan Harga",
  "shoutout_error_upload": "Terjadi kesalahan, gagal mengupload",
  "shoutout_error_video_required": "Upload gambar/video",
  "shoutout_error_video_size": "Upload gambar/video hingga 500MB",
  "shoutout_error_video_type": "Silahkan upload file dengan salah satu format JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC, MP4, MOV",
  "shoutout_escape": "Keluar",
  "shoutout_establish": "Setel sekarang",
  "shoutout_exit_deleting_warning": "Perubahan yang sudah dilakukan tidak akan tersimpan. Yakin keluar?",
  "shoutout_exit_editing": "Keluar mode edit?",
  "shoutout_file_intro": "PENDAHULUAN FOTO/VIDEO",
  "shoutout_file_intro_note": "Unggah gambar atau video untuk menghubungi penggemar untuk menggunakan video Shoutout dari Anda",
  "shoutout_file_upload_note": "Untuk menjawab penggemar, Anda perlu mengunggah video",
  "shoutout_home": "Halaman Beranda",
  "shoutout_home_title": "Home",
  "shoutout_how_to_answer": "Bagaimana menjawab",
  "shoutout_intro_content_image_build": "Suatu bentuk dukungan Anda berinteraksi lebih dalam dan lebih dekat ke file penggemar super Anda",
  "shoutout_intro_content_image_gift": "Fitur bantuan penggemar dapat mengirim permintaan kepada Anda untuk membuat video untuk acara -acara khusus",
  "shoutout_intro_content_image_private": "Hak untuk menerima atau menolak untuk membuat video berasal dari Anda",
  "shoutout_intro_content_image_request": "Melalui Passio, penggemar Anda dapat mengirimi Anda persyaratan video untuk acara -acara khusus: ulang tahun, selamat, peringatan, dorongan video, inspirasi ... dengan biaya tertentu yang dirancang sendiri.",
  "shoutout_intro_content_image_share": "Anda dan penggemar dapat berbagi video yang bermakna ini dengan orang -orang melalui situs jejaring sosial.",
  "shoutout_intro_content_image_video": "Anda memiliki 7 hari untuk memutuskan atau menolak permintaan penggemar. Jika Anda menolak untuk melakukannya, Ecomobi akan mengembalikan semua uang yang dibayar kepada pemohon dalam waktu 30 hari.",
  "shoutout_intro_fail": "Kegagalan",
  "shoutout_intro_title_gift": "Bagaimana cara kerja video teriakan?",
  "shoutout_intro_title_image_build": "Membangun komunitas penggemar \"besar\"",
  "shoutout_intro_title_image_gift": "Hadiah dengan tanda Anda sendiri",
  "shoutout_intro_title_image_private": "Pastikan privasi",
  "shoutout_intro_title_image_request": "Menerima persyaratan dari penggemar",
  "shoutout_intro_title_image_share": "Berbagi momen yang bermakna.",
  "shoutout_intro_title_image_video": "Membuat video",
  "shoutout_introduce": "Memperkenalkan",
  "shoutout_learn_shoutout": "Pelajari tentang Video Shoutout",
  "shoutout_learn_tod": "Pelajari tentang Kebenaran atau Dare",
  "shoutout_list_request_title": "List Request",
  "shoutout_loading_service": "Mengunduh ...",
  "shoutout_loading_service_title": "Video Shoutout",
  "shoutout_loading_upload": "Mengunduh ...",
  "shoutout_new": "Baru",
  "shoutout_no_file_selected": "Tidak ada foto/video yang dipilih",
  "shoutout_no_file_video_selected": "Tidak ada video yang dipilih",
  "shoutout_order_code": "Kode permintaan",
  "shoutout_participate_description": "Pencipta dapat memilih untuk membuat atau menolak persyaratan penggemar",
  "shoutout_participate_title": "Hak untuk bergabung dengan Anda",
  "shoutout_price": "Harga",
  "shoutout_price_free": "Gratis",
  "shoutout_price_placeholder": "Masukkan harga",
  "shoutout_processing": "Video sedang diproses untuk dikirim ke penggemar",
  "shoutout_reject": "Menolak",
  "shoutout_reject_cancle": "Menutup",
  "shoutout_reject_failed": "Menolak untuk gagal",
  "shoutout_reject_request": "Menolak permintaannya?",
  "shoutout_reject_request_warning": "Apakah Anda yakin ingin menolak permintaan ini? Tindakan tidak akan bisa melakukannya",
  "shoutout_reject_success": "Menolak permintaan",
  "shoutout_reply": "Membalas",
  "shoutout_reply_error_msg": "Umpan balik gagal",
  "shoutout_reply_success_msg": "Selamat, mengirim video ke penggemar Anda",
  "shoutout_request": "Permintaan",
  "shoutout_request_detail": "Detail diperlukan",
  "shoutout_request_reject_confirm": "Mengonfirmasi",
  "shoutout_response_description": "Setelah penggemar mengirimkan permintaan, Creator memiliki 7 hari untuk membuat video. Setelah waktu ini, jumlah permintaan itu akan dikembalikan ke penggemar",
  "shoutout_response_title": "7 hari tanggapan",
  "shoutout_save": "Simpan",
  "shoutout_save_video_success": "Konfigurasi video teriakan sukses",
  "shoutout_select_file": "Pilih foto/video",
  "shoutout_select_file_video": "Pilih Video",
  "shoutout_select_video_upload": "Pilih Video",
  "shoutout_share": "Membagikan",
  "shoutout_start": "Mulai Siapkan Video Shoutout",
  "shoutout_start_message": "Atur video teriakan untuk menerima permintaan penggemar",
  "shoutout_status_request": "Persyaratan",
  "shoutout_step_1": "Langkah 1",
  "shoutout_step_2": "Langkah 2",
  "shoutout_step_3": "Langkah 3",
  "shoutout_text_from": "Dari",
  "shoutout_text_to": "Tiba",
  "shoutout_time_created": "Penciptaan waktu",
  "shoutout_time_remain": "{{day}} days left",
  "shoutout_time_remain_status": "Waktu yang tersisa",
  "shoutout_time_remain_title": "Waktu yang tersisa",
  "shoutout_title_modal_upload": "Jawab permintaan",
  "shoutout_type_advice": "Nasihat",
  "shoutout_type_anniversary": "Acara khusus",
  "shoutout_type_birthday": "Hari ulang tahun",
  "shoutout_type_mobilize": "Memotivasi",
  "shoutout_type_other": "Acara lainnya",
  "shoutout_type_question": "Pertanyaan",
  "shoutout_type_support": "Dukungan: JPG, JPEG, GIF, PNG, BMP, Webp, Heif, HEIC, MP4, MOV hingga 500MB",
  "shoutout_type_video_support": "Dukungan: MP4, pindahkan hingga 500MB",
  "shoutout_type_vocative_adik": "Adik",
  "shoutout_type_vocative_anda": "Anda",
  "shoutout_type_vocative_aunt": "Merindukan",
  "shoutout_type_vocative_bapak": "Bapak",
  "shoutout_type_vocative_big_uncle": "Paman",
  "shoutout_type_vocative_bro": "Anda",
  "shoutout_type_vocative_friend": "Teman",
  "shoutout_type_vocative_grandfather": "Kakek",
  "shoutout_type_vocative_grandmother": "Nenek",
  "shoutout_type_vocative_he": "Kakak",
  "shoutout_type_vocative_he_him": "He/Him",
  "shoutout_type_vocative_ibu": "Ibu",
  "shoutout_type_vocative_kakak": "Kakak",
  "shoutout_type_vocative_paman": "Paman",
  "shoutout_type_vocative_she": "Kakak perempuan",
  "shoutout_type_vocative_she_her": "She/Her",
  "shoutout_type_vocative_tante": "Tante",
  "shoutout_type_vocative_thai_1": "คุณ",
  "shoutout_type_vocative_thai_2": "พี่ชาย",
  "shoutout_type_vocative_thai_3": "พี่สาว",
  "shoutout_type_vocative_thai_4": "น้อง",
  "shoutout_type_vocative_thai_5": "น้า",
  "shoutout_type_vocative_thai_6": "อา",
  "shoutout_type_vocative_thai_7": "ลุง",
  "shoutout_type_vocative_thai_8": "ยาย",
  "shoutout_type_vocative_thai_9": "ปู่",
  "shoutout_type_vocative_they_them": "They/Them",
  "shoutout_type_vocative_uncle": "Paman",
  "shoutout_type_vocative_you": "You",
  "shoutout_upload_cancel": "Melarikan diri",
  "shoutout_upload_continute": "Lanjutkan mengedit",
  "shoutout_video_required": "กรุณากรุณาวิดีโอ",
  "shoutout_view_detail": "Lihat detailnya",
  "shoutout_warning_cancel_upload": "Pengeditan Anda tidak akan disimpan. Apakah Anda yakin ingin melarikan diri?",
  "shoutout_warning_title_cancel_upload": "Escape Editing?",
  "shoutout_your_feedback": "Umpan Balik Anda",
  "ss_account_total": "Total",
  "ss_account_total_not_sold": "Tersisa",
  "ss_account_total_sold": "Terjual",
  "ss_add_photo_product_success": "Berhasil menyimpan",
  "ss_add_voucher_success": "Tambahkan kode diskon baru dengan sukses",
  "ss_builder_digital_product_free_label": "Gratis",
  "ss_builder_passio_page_ready": "Passio Page is ready",
  "ss_builder_passio_page_ready_description": "Passio page is ready to use and replace the old Landing Page version",
  "ss_builder_start_use_passio_page": "Start using Passio Page now",
  "ss_builder_to_new_version": "Go to Passio Page",
  "ss_button_title_default_donation": "Berikan dukungan ke {{name}}!",
  "ss_buyer": "Pembeli",
  "ss_call_api_error": "มี ข้อ ผิดพลาด โปรดลอง อีก ครั้ง ภาย หลัง",
  "ss_cancel": "Batalkan",
  "ss_category_add_product_label": "Tambah produk",
  "ss_course_info_preview_button": "Silakan isi informasi \"Pengaturan Kursus\" & \"Kuliah\" untuk melihat pratinjau konten kursus",
  "ss_course_manager_complete_date": "Ngày hoàn thành",
  "ss_course_manager_last_date": "Ngày học gần nhất",
  "ss_course_manager_list_empty": "Chưa có học viên nào",
  "ss_course_manager_start_date": "Ngày bắt đầu",
  "ss_course_manager_status_course_complete": "Hoàn thành",
  "ss_course_manager_status_course_learing": "Đang học",
  "ss_course_manager_status_course_not_learn": "Chưa vào học",
  "ss_course_manager_title_generality": "Tổng quát",
  "ss_course_manager_title_list": "Danh sách",
  "ss_course_preview_button": "Pratinjau",
  "ss_customer_donation_name": "Donasi",
  "ss_customer_list_empty": "Belum ada Fans",
  "ss_customer_order_success": "Sukses",
  "ss_customer_placeholder_search": "Username, Email, No Handphone",
  "ss_customer_price_free": "Gratis",
  "ss_customer_title": "List Fans",
  "ss_customer_title_detail_customer": "Info Fans",
  "ss_customer_title_list_purchase": "Produk dibeli",
  "ss_challenge": "Dare",
  "ss_click_to_detail_note": "Anda klik 1 dari 2 jenis produk berikut👇👇👇",
  "ss_close": "Mengerti",
  "ss_content_success_TOD": "Fitur Truth Or Dare sudah aktif!",
  "ss_count_gift_giving": "Gift Diterima",
  "ss_digital_product": "Digital Product",
  "ss_discover_now": "Coba sekarang",
  "ss_donation_answer": "Pelajari tentang Donasi",
  "ss_donation_content_helper_1": "Untuk menambah sumber pendapatan, kreator dapat menggunakan fitur ini untuk mengajak fans dan followers mendukung dengan bantuan donasi secara langsung sesuai dengan nominal yang mereka inginkan!\nAjakan dukungan Donasi ini bisa kamu tampilkan di akhir video / postingan mu.",
  "ss_donation_content_helper_2": "Kreator dapat mengatur jumlah minimal donasi yang fansnya bisa kirimkan.\nFans atau viewers mu dapat memberikan jumlah donasi sesuai dengan batas minimum yang kamu atur nantinya.",
  "ss_donation_content_helper_3": "Passio tidak memungut biaya sama sekali untuk fitur Donasi ini, kamu hanya terkena potongan media pembayaran / payment gateway maksimal 5% dari total donasi yang diterima sehingga 95% tetap akan menjadi pendapatanmu.",
  "ss_donation_content_intro_1": "Donasi adalah fitur dukungan khusus untuk kreator, yang memungkinkan fans untuk memberikan donasi langsung ke kreator yang mereka idolakan. Dengan bantuan Donasi fans dan penonton lainnya secara langsung membantu Kreator untuk dapat membuat lebih banyak konten yang berkualitas kedepannya!",
  "ss_donation_content_intro_2": "Seluruh pendapatan donasi akan dapat di cairkan ke akun rekening bank langsung sehingga Kreator memiliki sumber pendapatan tambahan dan dapat memaksimalkan monetization dari personal brandingnya. Terlebih lagi fitur Donasi aktif kapan saja dan tersedia untuk siapa saja!",
  "ss_donation_currency_shortcut": "Rp",
  "ss_donation_edit": "Edit",
  "ss_donation_establish": "Mulai Aktifkan Donasi",
  "ss_donation_home_title": "Donation",
  "ss_donation_intro_button_submit": "Mulai sekarang",
  "ss_donation_intro_button_title_default": "Berikan dukungan ke {{name}}!",
  "ss_donation_intro_invitation_default": "Apa kamu menyukai {{name}}?",
  "ss_donation_intro_message_default": "Terima kasih atas dukungan Anda ke {{name}}. Semoga kita memiliki lebih banyak kesempatan untuk berinteraksi satu sama lain!",
  "ss_donation_learn_service": "Tentang Donasi",
  "ss_donation_list_request_title": "List Donasi",
  "ss_donation_setting_btn_continue": "Lanjutkan",
  "ss_donation_setting_btn_donation_title": "Atur tombol Donasi",
  "ss_donation_setting_btn_title_placeholder": "Donasi sekarang",
  "ss_donation_setting_btn_title_tooltip": "Maksimum 40 karakter",
  "ss_donation_setting_cover_image": "Gambar sampul",
  "ss_edit_voucher_success": "Memodifikasi kode diskon yang berhasil",
  "ss_exclusive_passio_badge": "Ekslusif",
  "ss_exit_add_warning": "Perubahan Anda tidak akan disimpan. Apakah Anda yakin ingin melarikan diri?",
  "ss_intro_plaform_fee": "Platform fee:",
  "ss_intro_plaform_fee_free": "Free",
  "ss_intro_plaform_fee_from": "from",
  "ss_intro_plaform_fee_to": "to",
  "ss_label_end_date_code": "Hingga saat ini",
  "ss_label_name_voucher": "Nama diskon",
  "ss_label_quantity": "Penggunaan",
  "ss_label_quantity_left": "Nomor yang tersisa",
  "ss_label_quantity_used": "Jumlah penggunaan",
  "ss_label_start_date_code": "Sejak",
  "ss_label_voucher_amount_discount": "Jumlahnya berkurang",
  "ss_label_voucher_code": "Kode diskon",
  "ss_label_voucher_date": "Tanggal kadaluarsa",
  "ss_label_voucher_max_discount": "Pengurangan maksimum",
  "ss_label_voucher_percent_discount": "Pengurangan persentase",
  "ss_label_voucher_quantity": "Jumlah penggunaan",
  "ss_label_voucher_type": "Diskon",
  "ss_list_empty_content": "Untuk menambahkan, silakan tekan tombol \"Buat Produk\" di bagian atas",
  "ss_list_ticket_account": "List Akun",
  "ss_message_default_donation": "Terima kasih atas dukungan Anda ke {{name}}. Semoga kita memiliki lebih banyak kesempatan untuk berinteraksi satu sama lain!",
  "ss_modal_preview_button_back": "Kembali",
  "ss_modal_preview_button_donation": "Donasi",
  "ss_modal_preview_button_submit": "Mengunggah",
  "ss_modal_preview_message_note_placeholder": "Kirim Kata Cinta ke Creator✍🏻",
  "ss_modal_preview_submit_success": "Selamat Anda telah mengaktifkan fitur Donasi!",
  "ss_more_about_virtual_gift": "Pelajari tentang Virtual Gift",
  "ss_no": "Tidak",
  "ss_no_data": "Tidak ada data",
  "ss_no_data_text": "Aktifkan fitur ini untuk meningkatkan pendapatanmu",
  "ss_off": "Matikan",
  "ss_on": "Setuju",
  "ss_order_code": "Kode pesanan",
  "ss_order_date": "Waktu order",
  "ss_photo_title_create": "Tambah produk",
  "ss_placeholder_input_code_voucher": "PASSIO123",
  "ss_placeholder_input_max_ammount_voucher": "Masukkan harga",
  "ss_placeholder_input_name_voucher": "Penjualan 12/12",
  "ss_placeholder_input_percent_voucher": "Masukkan persentasenya",
  "ss_placeholder_input_price_ammount_voucher": "Masukkan harga",
  "ss_placeholder_input_quantity_voucher": "Masukkan berapa kali",
  "ss_product_account_required": "Please upload the list",
  "ss_product_list_empty_title": "Tidak ada produk",
  "ss_report_store_order_commission_value": "Is the amount of commission shared for the Creator to support the sale of this order",
  "ss_product_name": "Nama produk",
  "ss_product_success_off_donation": "Fitur Gift telah di non-aktifkan",
  "ss_product_success_off_virtual_gift": "Fitur gift telah di non-aktifkan",
  "ss_product_success_on_donation": "Fitur Gift telah aktif",
  "ss_product_success_on_virtual_gift": "Sukses mengaktifkan fitur gift!",
  "ss_push_TOD": "Unggah",
  "ss_report_conversion_rate_brand_gift": "Tingkat konversi",
  "ss_report_total_order_brand_gift": "Jumlah order",
  "ss_report_total_order_income_brand_gift": "Jumlah merek dengan pesanan",
  "ss_report_total_order_income_tooltip_brand_gift": "Jumlah merek yang dihasilkan setidaknya 1 pesanan",
  "ss_report_total_order_tooltip_brand_gift": "Jumlah pesanan dari Fan",
  "ss_report_total_view_tooltip_brand_gift": "Jumlah kunjungan Fan ke Brand Gift",
  "ss_report_vg_income_tooltip": "Pendapatan Virtual Gift",
  "ss_report_vg_order_tooltip": "Gift orders yang diterima Anda",
  "ss_report_vg_show_list_all_orders": "List Orders",
  "ss_response_customer": "Kirim umpan balik ke kepada orang yang memberi Anda hadiah ini",
  "ss_save": "Simpan",
  "ss_sell_photo_btn_save": "Simpan",
  "ss_sell_photo_btn_selectd": "Pilih",
  "ss_sell_photo_button_cancel_upload": "Batalkan",
  "ss_sell_photo_cancel_add": "Batalkan",
  "ss_sell_photo_cancel_add_warning": "Tutup",
  "ss_sell_photo_cancel_btn_delete": "Batalkan",
  "ss_sell_photo_confirm_btn_delete": "Konfirmasi hapus",
  "ss_sell_photo_confirm_btn_warning_cancel": "Tutup",
  "ss_sell_photo_continute_add": "Konfirmasi",
  "ss_sell_photo_cover_photo_upload": "Cover gambar",
  "ss_sell_photo_description_delete": "Gambar akan dihapus dari list",
  "ss_sell_photo_description_label": "Deskripsi",
  "ss_sell_photo_description_waring_cancel": "Hapus gambar?",
  "ss_sell_photo_empty_description": "Untuk menambahkan gambar produk, pilih menu \"Upload Gambar\" diatas",
  "ss_sell_photo_empty_title": "Belum ada gambar produk",
  "ss_sell_photo_error_description_required": "Silahkan isi deskripsi produk",
  "ss_sell_photo_error_name_required": "Masukkan nama produk",
  "ss_sell_photo_error_original_price_required": "Masukkan harga produk asli",
  "ss_sell_photo_exit_add_text": "Perubahan belum disimpan",
  "ss_sell_photo_exit_add_warning": "Yakin untuk membatalkan?",
  "ss_sell_photo_exit_upload_text": "Apakah Anda ingin membatalkan?",
  "ss_sell_photo_exit_upload_warning": "Semua data tidak akan disimpan",
  "ss_sell_photo_image_is_processing": "Gambar sedang di proses, harap menunggu",
  "ss_sell_photo_intro_photo_upload": "Gambar pengenalan (hingga 4 gambar)",
  "ss_sell_photo_label_image_selected": "Gambar dipilih",
  "ss_sell_photo_list_image_title_popup": "List gambar",
  "ss_sell_photo_max_photo": "Silahkan upload hingga 50 gambar per produk",
  "ss_sell_photo_max_size_upload_photo": "Silahkan upload dengan maksimum file 20MB",
  "ss_sell_photo_message_label": "Pesanmu",
  "ss_sell_photo_name_photo": "Nama produk",
  "ss_sell_photo_placeholder_input_description": "Masukkan konten",
  "ss_sell_photo_placeholder_input_message": "Masukkan konten ekslusif untuk fans mu disini (1 link untuk pembeli, file untuk membuka password atau lisensi, akses meeting Zoom dan lainnya)",
  "ss_sell_photo_placeholder_input_name_photo": "Masukkan konten",
  "ss_sell_photo_placeholder_input_price": "Masukkan jumlah",
  "ss_sell_photo_price_discount_label": "Harga Promo",
  "ss_sell_photo_price_label": "Harga",
  "ss_sell_photo_price_original_label": "Harga Asli",
  "ss_sell_photo_required_photo": "Silahkan upload gambar produk",
  "ss_sell_photo_required_photo_cover": "Silahkan upload Cover gambar",
  "ss_sell_photo_required_photo_intro": "Silahkan upload gambar pengenalan",
  "ss_sell_photo_text_button_list_image": "List gambar",
  "ss_sell_photo_text_button_uncheck": "Batal",
  "ss_sell_photo_text_button_upload_image": "Upload Gambar",
  "ss_sell_photo_text_des_image_size": "Maksimum ukuran file: 20MB/gambar",
  "ss_sell_photo_text_des_image_type": "Format gambar : jpg, jpeg, png, bmp",
  "ss_sell_photo_title_delete": "Hapus gambar",
  "ss_sell_photo_title_list": "List",
  "ss_sell_photo_title_photo": "Gambar produk",
  "ss_sell_photo_title_waring_cancel": "Perubahan belum disimpan",
  "ss_sell_photo_type_price_charge": "Dibayar",
  "ss_sell_photo_type_price_free": "Gratis",
  "ss_sell_photo_uploading_text": "Dalam proses",
  "ss_service_introduction": "Perkenalkan Layanan",
  "ss_service_intruct": "Instruksi untuk pengaturan",
  "ss_service_make_money": "Cari duit dengan Virtual Gift",
  "ss_service_make_money_text": "Semakin banyak hadiah virtual yang kamu terima dari fans, semakin banyak juga pendapatan yang kamu akan terima!\nTerlebih lagi fitur Virtual Gift ini dapat aktif kapan saja dan menjangkau semua fans diseluruh internet!",
  "ss_setting_TOD_background_tooltip": "Pilih gambar latar belakang dengan rasio 3: 2 dan kapasitas maksimum 4 MB",
  "ss_setting_TOD_field_image_background": "Tambahkan wallpaper",
  "ss_setting_TOD_field_image_background_bottom": "Gambar latar belakang",
  "ss_setting_TOD_field_invitation_tooltip": "Konten penawaran terbatas maksimum 60 karakter.",
  "ss_setting_TOD_field_welcome_tooltip": "Kalimat ajakan untuk penggemar yang memberi tantangan otomatis terisi di sistem.",
  "ss_setting_TOD_title": "Pengaturan",
  "ss_setting_btn_donation_title": "Atur tombol Donasi",
  "ss_setting_btn_donation_title_placeholder": "Donasi sekarang",
  "ss_setting_btn_donation_title_tooltip": "Maksimum 40 karakter",
  "ss_setting_donation_field_image_background": "Background",
  "ss_setting_donation_field_image_background_tooltip": "Pilih gambar background dengan rasio 3: 2 dan kapasitas maksimum 4 MB",
  "ss_setting_donation_field_image_cover": "foto sampul",
  "ss_setting_donation_field_max_price": "Jumlah maksimum",
  "ss_setting_donation_field_max_price_placeholder": "Jumlah maksimum yang diterima dari fans",
  "ss_setting_donation_field_max_price_suggest": "Jumlah uang maksimum tidak lebih rendah dari jumlah minimum dan tidak lebih tinggi dari",
  "ss_setting_donation_field_message_thank_you": "Terima kasih",
  "ss_setting_donation_field_message_thank_you_placeholder": "Anda perlu ucapkan terima kasih kepada fans Anda",
  "ss_setting_donation_field_message_thank_you_placeholder_focus_after": ". Saya akan terus membuat banyak konten menarik bagi kalian semua.",
  "ss_setting_donation_field_message_thank_you_placeholder_focus_before": "Terima kasih atas dukunganmu",
  "ss_setting_donation_field_message_thank_you_tooltip": "Maksimum 300 karakter untuk ucapan terimakasih",
  "ss_setting_donation_field_price": "Jumlah minimum",
  "ss_setting_donation_field_price_placeholder": "Jumlah minimum yang diterima dari fans",
  "ss_setting_donation_field_price_suggest": "Jumlah uang minimum tidak kurang dari Rp 2.000  dan tidak lebih tinggi dari Rp 9.999.999.999",
  "ss_setting_donation_field_price_suggest1": "Jumlah maksimum uang tidak lebih rendah dari",
  "ss_setting_donation_field_price_suggest2": "dan tidak lebih tinggi dari",
  "ss_setting_donation_field_welcome": "Ajak fansmu untuk berdonasi",
  "ss_setting_donation_field_welcome_placeholder": "Masukkan kalimat ajakan untuk berdonasi",
  "ss_setting_donation_field_welcome_tooltip": "Maksimum 60 karakter untuk pesan ajakan berdonasi",
  "ss_setting_donation_title": "Pengaturan untuk menerima Donasi",
  "ss_showing": "Tampilkan",
  "ss_store_add_new": "Tambahkan yang baru",
  "ss_store_add_new_course_section": "Tambah postingan baru",
  "ss_store_author_name_place_holder": "Masukkan Nama Penulis",
  "ss_store_author_name_title": "Nama Penulis",
  "ss_store_commission_value": "Commission value",
  "ss_store_confirm_change_donation": "Nyalakan fitur ini untuk menerima donasi dan matikan fitur gift. Apa kamu yakin?",
  "ss_store_confirm_off_donation": "Apakah Anda ingin mematikan fitur donasi?",
  "ss_store_confirm_off_virtual_gift": "Apakah Anda ingin mematikan fitur gift?",
  "ss_store_course_content_group_title": "Pelajaran",
  "ss_store_course_description_label": "Deskripsi singkat tentang kursus",
  "ss_store_course_document_title": "Dokumen pembelajaran",
  "ss_store_course_faq_group_title": "Pertanyaan yang sering ketemu",
  "ss_store_course_faq_title": "Pertanyaan yang sering ketemu",
  "ss_store_course_info_bar_group_title": "Buat kursus",
  "ss_store_course_info_bar_title": "Buat kursus",
  "ss_store_course_original_price_title": "Harga Original Kursus",
  "ss_store_course_price": "Harga kursus",
  "ss_store_course_price_group_title": "Harga kursus",
  "ss_store_course_price_title": "Harga",
  "ss_store_course_section_data_error": "Data kurang atau tidak valid. Silakan periksa lagi",
  "ss_store_course_section_title": "Buat pelajaran",
  "ss_store_course_sell_price_title": "Harga",
  "ss_store_course_thumbnail_label": "Gambar kursus",
  "ss_store_course_title_label": "Judul kursus",
  "ss_store_course_value_description": "Menunjukkan hasil yang pelajar bisa dapatkan disini yang akan membantu pelajar dengan cepat memahami nilai kursus dan apakah kursusnya sesuai dengan mereka.",
  "ss_store_course_value_title": "Hasil yang pelajar bisa dapatkan",
  "ss_store_course_video_warn": "Video memiliki resolusi minimum 720p dan ukuran maksimum 1GB",
  "ss_store_create_course_material": "Buat materi pembelajaran",
  "ss_store_create_course_section": "Buat pelajaran",
  "ss_store_create_digital_course": "Buat kursus",
  "ss_store_data_required": "Silakan isi informasi",
  "ss_store_default_answer_1": "Apakah kursus ini cocok untuk pemula",
  "ss_store_default_answer_2": "Anda dapat mempelajari kursus ini selamanya dan di mana saja",
  "ss_store_default_answer_3": "Iya. Anda akan ada grup untuk berinteraksi dengan pengajar.",
  "ss_store_default_question_1": "Siapa cocok dengan kursus ini?",
  "ss_store_default_question_2": "Berapa lama saya bisa mempelajari kursus ini?",
  "ss_store_default_question_3": "Ketika saya memiliki pertanyaan, Apakah saya bisa tanya dengan pengajar?",
  "ss_store_delete_file": "Hapus video",
  "ss_store_demo_video_title": "Video promosi kursus",
  "ss_store_discount_price": "Discount price",
  "ss_store_edit_digital_course": "Pengeditan kursus",
  "ss_store_error_save_course_section": "Update gagal, coba lagi nanti.",
  "ss_store_error_update_document": "Update gagal, coba lagi nanti.",
  "ss_store_faq_answer": "Jawaban",
  "ss_store_faq_answer_max_length": "Harap masukkan karakter maksimum {{n}} untuk jawabannya",
  "ss_store_faq_field_require": "Harap masukkan nilai",
  "ss_store_faq_question": "Pertanyaan",
  "ss_store_faq_question_max_length": "Harap masukkan karakter maksimum {{n}} untuk pertanyaan",
  "ss_store_field_required": "Bagian ini tidak boleh kosong",
  "ss_store_item_inprogress": "Harap melengkapi form informasi",
  "ss_store_max_character": "Maksimum {{n}} karakter",
  "ss_store_max_value": "Nilai maksimum adalah {{n}}",
  "ss_store_min_value_or_zero": "Nilai lebih dari 0 dan lebih dari atau sama dengan 1000",
  "ss_store_momo_wallet": "Momo Wallet - E-Wallet",
  "ss_store_not_enough_course_resource": "Kursus Anda akan muncul jika telah dimasukkan informasi yang cukup",
  "ss_store_order_buyer_message": "Pesan pembeli",
  "ss_store_order_income": "Jumlah penjualan",
  "ss_store_order_payment_fee": "Biaya Pembayaran",
  "ss_store_order_payment_fee_tooltip": "Ini adalah biaya yang dikumpulkan oleh payment gateway secara otomatis pada setiap transaksi. Biaya tersebut tergantung pada partner pembayaran dan metode pembayaran.",
  "ss_store_order_platform_fee": "Biaya platform",
  "ss_store_order_platform_fee_tooltip": "Adalah biaya dihitung berdasarkan persentase dari pendapatan yang dihasilkan ketika pengguna berhasil pemesanan. Persentase dapat beda tergantung jenis produk.",
  "ss_store_order_revenue": "Pendapatan",
  "ss_store_order_revenue_tooltip": "Adalah jumlah (sebelum pemotongan pajak jika ada) yang akan Anda terima setelah mengambil pendapatan dikurangi biaya.",
  "ss_store_origin_larger_than_price": "Harga Original harus lebih tinggi atau sama dengan harga produk",
  "ss_store_original_price": "Original price",
  "ss_store_quit_page_content": "Apakah Anda ingin keluar?",
  "ss_store_quit_page_title": "Data tidak akan disimpan.",
  "ss_store_received_donation": "Donasi",
  "ss_store_received_donation_message": "Nyalakan fitur ini, Anda akan mengizinkan seluruh fans untuk mengirim gift kepada Anda. Coba fitur yang menarik ini!",
  "ss_store_received_virtual_gift": "Terima Gift",
  "ss_store_received_virtual_gift_message": "Nyalakan fitur gift dan matikan fitur donasi. Apa kamu yakin?",
  "ss_store_replace_file": "Ubah video",
  "ss_store_save_course_error": "Kursus gagal disimpan, coba lagi atau hubungi Technical Support",
  "ss_store_save_course_success": "Berhasil menyimpan kursus",
  "ss_store_save_error": "Update gagal, coba lagi nanti.",
  "ss_store_save_questions_error": "Update gagal, coba lagi nanti.",
  "ss_store_save_questions_successfull": "Update berhasil",
  "ss_store_save_successfull": "Update berhasil",
  "ss_store_section_default_title": "Pelajaran 1: Pengenalan awal",
  "ss_store_section_group_default_title": "Bagian 1: Pengenalan awal",
  "ss_store_setup_the_course": "Buat kursus",
  "ss_store_teacher_info_title": "Informasi Pengajar",
  "ss_store_thumbnails_description": "Ukuran gambar maksimum 30MB",
  "ss_store_update_document_successfully": "Update berhasil",
  "ss_store_update_section_successfully": "Update berhasil",
  "ss_store_upload_avatar": "Upload avatar",
  "ss_store_upload_fail_message": "Gagal mengunggah",
  "ss_store_upload_video": "Video",
  "ss_store_use_text": "Dokumen",
  "ss_store_validate_url": "Format link salah. Anda harus memasukkan link video YouTube",
  "ss_store_video_description": "Apakah Anda tahu video iklan kursus akan membantu meningkatkan pendaftaran pelajar 5 kali lipat? Membuat dan mengunggah video Anda",
  "ss_store_youtube_demo_video_url_place_holder": "Masukkan link YouTube yang termasuk konten kursus.",
  "ss_success_label": "Success",
  "ss_time_draw_content_box_1": "Tanggal order dibuat",
  "ss_time_draw_content_box_2": "Hari D+7",
  "ss_time_draw_content_box_3": "Hari D",
  "ss_time_draw_content_box_4": "7 hari setelah order berhasil dibuat, komisi akan ditambahkan ke saldo tersedia",
  "ss_time_picker": "Time",
  "ss_title_time_draw": "Waktu penarikan",
  "ss_tod_reply_add_video_image_after": "Video",
  "ss_tod_reply_add_video_image_before": "Menambahkan foto/",
  "ss_tod_reply_helper_add_video": "Untuk melakukan tantangan, Anda perlu mengunggah minimal1 video",
  "ss_tod_reply_img_incorrect_type": "Pilih file dalam bentuk salah satu format berikut JPG, JPEG, GIF, PNG, BMP, Webp, Heif, Heic",
  "ss_tod_reply_input_alias": "Balas konten",
  "ss_tod_reply_input_placeholder": "Jujur menjawab pertanyaan dari fan Anda!",
  "ss_tod_reply_placeholder_content_dare": "Perkenalkan secara singkat atau kirim pesan ke fan Anda di sini!",
  "ss_tod_reply_reply_submit": "Kirim jawaban",
  "ss_tod_reply_reply_take_challenge": "Melakukan tantangan",
  "ss_tod_reply_tooltip_max_size_video": "Kapasitas maksimum untuk video adalah 10GB",
  "ss_tod_reply_tooltip_max_size_video_image": "Kapasitas maksimum untuk gambar adalah 4MB, untuk video adalah 10GB",
  "ss_tod_reply_video_img_incorrect_size": "Gambar atau video melebihi kapasitas yang diizinkan.",
  "ss_tod_reply_video_incorrect_size": "Video melebihi kapasitas yang diizinkan",
  "ss_tod_reply_video_incorrect_type": "Pilih file dengan format MP4 atau MOV",
  "ss_tod_reply_wait_upload_video": "Mohon tunggu sedikit lagi!",
  "ss_tod_report_detail": "id_Chi tiết báo cáo",
  "ss_tod_response": "Cara merespon",
  "ss_tod_setting_background": "Foto background",
  "ss_tod_setting_background_tooltip": "Pilih gambar latar belakang dengan rasio 3: 2 dan kapasitas maksimum 4 MB",
  "ss_tod_setting_button_confirm_exit": "Ya/Keluar",
  "ss_tod_setting_content_success": "Fitur Truth Or Dare sudah aktif!",
  "ss_tod_setting_dare_title": "Kreator merespons dengan minimal 1 video",
  "ss_tod_setting_exit": "Batalkan",
  "ss_tod_setting_field_invitation_tooltip": "Konten penawaran terbatas maksimum 60 karakter.",
  "ss_tod_setting_field_welcome_tooltip": "Kalimat ajakan untuk penggemar yang memberi tantangan otomatis terisi di sistem.",
  "ss_tod_setting_helper_service_1": "Kreator memiliki waktu hingga 15 hari untuk menanggapi atau menjawab tantangan Anda.",
  "ss_tod_setting_helper_service_2": "Jika tantangan atau pertanyaan Anda tidak menerima tanggapan, jumlah yang disumbangkan akan dikembalikan kepada Anda dalam waktu maksimal 30 hari.",
  "ss_tod_setting_invitation": "Penawaran konten untuk melakukan tantangan",
  "ss_tod_setting_invitation_placeholder": "Masukkan Konten Undang Penggemar untuk Bergabung",
  "ss_tod_setting_message_success": "Anda telah berhasil membuka fitur Truth Or Dare.",
  "ss_tod_setting_min_price_dare_alias": "Jumlah minimum yang dibayarkan penggemar untuk satu Dare",
  "ss_tod_setting_min_price_dare_example": "Contoh: 100.000",
  "ss_tod_setting_min_price_dare_note_v2": "Pembayaran minimum adalah {{price}}",
  "ss_tod_setting_preview": "Lanjut",
  "ss_tod_setting_push": "Unggah",
  "ss_tod_setting_success_TOD": "Sukses",
  "ss_tod_setting_text_edit": "Edit",
  "ss_tod_setting_title_service_1": "Waktu merespon",
  "ss_tod_setting_title_service_2": "Waktu pengembalian dana",
  "ss_tod_setting_tooltip_min_price_dare": "Jika Anda tidak memasukkannya, sistem akan secara otomatis mengisi kolom ini dengan nilai default",
  "ss_tod_setting_truth_title": "Kreator membalas dengan pesan teks",
  "ss_tod_setting_txt_btn": "Ajak penggemar untuk memberi tantangan",
  "ss_tod_setting_warning_text": "Pengaturan Anda belum disimpan. Apakah Anda masih ingin membatalkan?",
  "ss_tod_setup": "Atur sekarang",
  "ss_tod_start": "Atur Truth or Dare",
  "ss_tod_status_alias_new": "Baru",
  "ss_tod_status_alias_pending": "Pending",
  "ss_tod_the_number_of_times_fans_senttod": "id_Số lượt Fan gửi Truth or Dare",
  "ss_tod_title_guide_1": "Waktu respon",
  "ss_tod_title_guide_2": "Hak untuk memilih tantangan",
  "ss_tod_title_guide_3": "Cara merespon",
  "ss_tod_title_intro_1": "Waktu respon",
  "ss_tod_title_intro_2": "Hak untuk memilih tantangan",
  "ss_tod_title_intro_3": "Cara merespon",
  "ss_tod_tod_no": "id_Số TOD",
  "ss_tod_tooltip_min_price_dare": "Jika Anda tidak memasukkannya, sistem akan secara otomatis mengisi kolom ini dengan nilai default",
  "ss_tod_truth": "Truth",
  "ss_tod_truth_title": "Kreator dapat membalas dengan pesan",
  "ss_tod_view": "id_Lượt xem",
  "ss_total_products_sold": "Jumlah produk terjual",
  "ss_txt_btn_TOD": "Ajak penggemar untuk memberi tantangan",
  "ss_upload_file": "Upload",
  "ss_validate_image_post_max_size": "Ukuran gambar berlebihan",
  "ss_video_processing_error": "Terjadi kesalahan saat memproses, harap coba lagi",
  "ss_video_processing_text": "Video sedang diproses. Proses ini mungkin memakan waktu",
  "ss_virtual_gift_home_order_revenue": "Perkiraan pendapatan aplikasi setelah dikurangi biaya",
  "ss_virtual_gift_home_payment_des": "Saat ini Passio sudah terintegrasi dengan beragam metode pembayaran baik lokal dan internasional sehingga lebih mempermudah fans untuk berinteraksi dan mendukung kreator mereka.",
  "ss_virtual_gift_home_payment_title": "Bagaimana fans dan penonton bisa memberikan Virtual Gift?",
  "ss_virtual_gift_home_refund_des": "Kreator akan menerima 85% dari nilai Virtual Gift!\n10% sisanya akan dikenakan sebagai biaya platform Passio dan 5% lainnya digunakan untuk biaya payment gateway.",
  "ss_virtual_gift_home_refund_title": "Berapa banyak pendapatan dari Virtual Gift yang akan diterima?",
  "ss_virtual_gift_intro_report": "Virtual Gift",
  "ss_virtual_gift_intro_service_feature": "Memperkenalkan Virtual Gift",
  "ss_virtual_gift_intro_service_feature_text": "Virtual Gift adalah fitur terbaru dari Passio. Dengan fitur ini memungkinkan fans untuk memberikan hadiah virtual yang ikonik serta pesan yang bisa di kustomisasi untuk Kreator favorit mereka. Virtual Gift nantinya dapat diuangkan dan bisa menjadi sumber pendapatan Kreator.",
  "ss_virtual_gift_intro_service_make_money": "Cari duit dengan Virtual Gift",
  "ss_virtual_gift_intro_service_make_money_text": "Semakin banyak hadiah virtual yang kamu terima dari fans, semakin banyak juga pendapatan yang kamu akan terima!\nTerlebih lagi fitur Virtual Gift ini dapat aktif kapan saja dan menjangkau semua fans diseluruh internet!",
  "ss_virtual_gift_report_dont_have_gift": "Tidak ada Gift dalam saat ini",
  "ss_virtual_gift_report_message_from_seller": "Pesan yang Anda Kirim",
  "ss_virtual_gift_report_message_response_failed": "Gagal mengirim feedback",
  "ss_virtual_gift_report_message_response_successful": "Sukses mengirim feedback",
  "ss_virtual_gift_report_method_payment_atm": "Debit / ATM - Transfer Uang {{param}} bank",
  "ss_virtual_gift_report_method_payment_credit_card": "Kartu kredit/ {{param}} internasional",
  "ss_virtual_gift_report_overview": "Ringkasan",
  "ss_virtual_gift_report_payment_history": "Riwayat pembayaran",
  "ss_virtual_gift_report_payment_method": "Metode Pembayaran",
  "ss_virtual_gift_report_phone_number": "Nomor telepon",
  "ss_virtual_gift_report_ss_showing": "Tampilkan",
  "ss_virtual_gift_report_store_momo_wallet": "Momo Wallet - E-Wallet",
  "ss_virtual_gift_report_store_order_buyer_message": "Pesan pembeli",
  "ss_virtual_gift_report_store_order_giver_message": "Pesan dari fans",
  "ss_virtual_gift_report_store_order_payment_fee_tooltip": "Ini adalah biaya yang dikumpulkan oleh payment gateway secara otomatis pada setiap transaksi. Biaya tersebut tergantung pada partner pembayaran dan metode pembayaran.",
  "ss_virtual_gift_report_store_order_platform_fee_tooltip": "Adalah biaya dihitung berdasarkan persentase dari pendapatan yang dihasilkan ketika pengguna berhasil pemesanan. Persentase dapat beda tergantung jenis produk.",
  "ss_virtual_gift_report_store_order_revenue_tooltip": "Adalah jumlah (sebelum pemotongan pajak jika ada) yang akan Anda terima setelah mengambil pendapatan dikurangi biaya.",
  "ss_virtual_gift_report_store_order_total_income": "Pendapatan = Harga asli / Harga promosi - Jumlah diskon (jika ada)",
  "ss_virtual_gift_report_vg_buyer": "Pemberi",
  "ss_virtual_gift_report_vg_error_upload": "Opps! Ada masalah dalam proses pengunduhan data. Silakan coba untuk memuat ulang halaman lagi!",
  "ss_virtual_gift_report_vg_product": "Gift",
  "ss_virtual_gift_report_vg_time_buy": "Waktu mengirim gift",
  "ss_voucher_add_button_label": "Tambahkan produk",
  "ss_voucher_add_product_btn": "Lagi",
  "ss_voucher_add_product_warning_cancel": "Melarikan diri",
  "ss_voucher_add_product_warning_confirm": "Terus tambahkan produk",
  "ss_voucher_btn_add": "Menyimpan",
  "ss_voucher_cancel_add": "Membatalkan",
  "ss_voucher_cancel_add_product_btn": "Membatalkan",
  "ss_voucher_cancel_add_warning": "Melarikan diri",
  "ss_voucher_continute_add": "Terus tambahkan baru",
  "ss_voucher_copy_error": "Salin kode yang gagal",
  "ss_voucher_copy_success": "Salin kode dengan sukses",
  "ss_voucher_creator": "Kode diskon Creator",
  "ss_voucher_datepicker_title": "Hari",
  "ss_voucher_error_code_required": "Harap masukkan nama kode diskon",
  "ss_voucher_error_end_date_soon_current_date": "Waktu akhir harus lebih lambat dari waktu mulai",
  "ss_voucher_error_input_discount_amount_required": "Harap masukkan diskon",
  "ss_voucher_error_input_percent_discount_required": "Harap masukkan diskon",
  "ss_voucher_error_name_existed": "Kode diskon telah ada",
  "ss_voucher_error_name_min_length": "Harap masukkan setidaknya {{min}} karakter",
  "ss_voucher_error_name_required": "Harap masukkan kode diskon",
  "ss_voucher_error_quantity_less_than_current": "Tidak bisa mengurangi angka penggunaan dari kode diskon yang aktif",
  "ss_voucher_error_quantity_required": "Masukkan batas maksimum penggunaan kode diskon",
  "ss_voucher_error_start_date_soon_current_date": "Waktu mulai harus lebih lambat dari waktu saat ini",
  "ss_voucher_exit_add": "Membatalkan ?",
  "ss_voucher_exit_deleting_warning": "Perubahan Anda tidak akan disimpan. Apakah Anda yakin ingin melarikan diri?",
  "ss_voucher_label_appled": "Terpilih",
  "ss_voucher_label_apply_all": "Oleskan semua produk",
  "ss_voucher_label_date": "Kedaluwarsa:",
  "ss_voucher_label_left": "digunakan",
  "ss_voucher_label_total": "Total",
  "ss_voucher_list_apply": "Aplikasi Produk",
  "ss_voucher_no_quantity": "No Limit",
  "ss_voucher_passio": "Kode diskon PASSIO",
  "ss_voucher_placeholder_input_search": "Nama produk",
  "ss_voucher_search_button_label": "Mencari",
  "ss_voucher_timepicker_title": "Waktu",
  "ss_voucher_title_select_product": "Tambahkan produk",
  "ss_voucher_turn_off_cancle_text": "Membatalkan",
  "ss_voucher_turn_off_confirm_cancel": "Akhir",
  "ss_voucher_turn_off_warning_content": "Apakah Anda pasti akan mengakhiri kode diskon ini? Tindakan tidak dapat dilakukan",
  "ss_voucher_turn_off_warning_title": "Akhir diskon",
  "ss_voucher_type_money": "Menurut uang",
  "ss_voucher_type_money_label": "Menurut harganya",
  "ss_voucher_type_percent": "Persentase",
  "ss_voucher_type_percent_label": "Persentase",
  "ss_voucher_warning_edit": "Produk Cancell?",
  "start_date_must_be_less_than_end_date": "Tanggal mulai harus sebelum dari tanggal akhir",
  "start_making_money": "Mulai hasilkan uang",
  "status": "Status",
  "store_order_giver_message": "Pesan dari fans",
  "store_order_total_income": "adalah pendapatan dari order",
  "sub1_empty_data": "Tidak ada data",
  "sub_account": "Sub akun",
  "system_error": "Sistem bermasalah",
  "tab_list_voucher_all": "Semua",
  "tab_list_voucher_end": "Akhir",
  "tab_list_voucher_running": "Terjadi",
  "tab_list_voucher_upcoming": "Mendatang",
  "text_custom_range": "sesuaikan periode waktu",
  "text_last_month": "Bulan yang lalu",
  "text_last_n_day": "{{n}} hari terkini",
  "text_last_week": "Minggu lalu",
  "text_this_month": "Bulan ini",
  "text_this_quarter": "Kuartal ini",
  "text_this_week": "Minggu ini",
  "text_today": "Hari ini",
  "text_yesterday": "Kemarin",
  "ticket_cancel": "Cancel",
  "ticket_choose_file": "Select file",
  "ticket_choose_file_template": "Choose the template file that's right for you",
  "ticket_confirm": "Confirm",
  "ticket_delete_row": "Delete records?",
  "ticket_delete_row_content": "Delete the record here if you have used/sold it elsewhere",
  "ticket_delete_row_success": "Successfully deleted record",
  "ticket_download_template": "Download template file",
  "ticket_error_first_column": " Unable to upload file. Each row in the first column cannot be blank",
  "ticket_error_length_text": " Unable to upload file. Column name maximum 30 characters, Data field maximum 255 characters",
  "ticket_instruct_template": "Choose a template file suitable for the product you want to sell. The system supports from 1 column of data to 5 columns of data. For example: If you sell ChatGPT accounts, you will choose a 2-column file.",
  "ticket_list_template": "List of template files",
  "ticket_list_ticket_account_note": "Click “upload” to download the list of products you want to sell.",
  "ticket_no_file_select": "No files have been selected",
  "ticket_product_account_max_size_upload_file": "Select a file with a maximum capacity of 3aMB",
  "ticket_product_account_type_upload_file": "Format allows xls, xlsx",
  "ticket_row": "Column",
  "ticket_support_file_upload": "Support: xls, xlsx, maximum: 3MB",
  "ticket_text_template": "File template",
  "ticket_text_upload_file": "Upload an xls or xlsx file to update the list",
  "ticket_upload": "Upload",
  "ticket_upload_list": "Upload list",
  "ticket_upload_success": "Upload list successfully",
  "tickets_upload_list": "Upload list",
  "time_picker": "Time",
  "tod_file_format_incorrect": "Format file tidak sesuai",
  "tod_helper_add_video": "Untuk melakukan tantangan, Anda perlu mengunggah minimal1 video",
  "tod_img_incorrect_type": "Pilih file dalam bentuk salah satu format berikut JPG, JPEG, GIF, PNG, BMP, Webp, Heif, Heic",
  "tod_list_challenge_label": "Daftar Tantangan",
  "tod_msg_required_video": "Anda perlu mengirim video untuk membuat tantangan",
  "tod_notification": "Klik untuk melihat detail dari setiap tantangan untuk menerima atau menolak",
  "tod_perform": "Melakukan",
  "tod_placeholder_content_dare": "Perkenalkan secara singkat atau kirim pesan ke fan Anda di sini!",
  "tod_private": "Pribadi",
  "tod_private_text": "Tidak publik",
  "tod_public_text": "Publik",
  "tod_reply": "Menjawab",
  "tod_reply_input_alias": "Balas konten",
  "tod_reply_input_placeholder": "Jujur menjawab pertanyaan dari fan Anda!",
  "tod_reply_submit": "Kirim jawaban",
  "tod_reply_take_challenge": "Melakukan tantangan",
  "tod_share_challenge": "Chia sẻ thử thách",
  "tod_share_link": "Sebarkan tautan",
  "tod_start_message": "Tambah fitur ini ke Passio Page mu dan tingkatkan interaksi dengan fansmu!",
  "tod_status_alias_accepted": "Dibuat",
  "tod_status_alias_completed": "Selesai!",
  "tod_status_alias_expired": "Kedaluwarsa",
  "tod_status_alias_new": "Baru",
  "tod_status_alias_refund": "Mengembalikan",
  "tod_status_alias_wait": "Menunggu",
  "tod_status_private": "Status pribadi",
  "tod_status_private_des": "Fan Anda tidak akan melihat tantangan dalam mode ini",
  "tod_status_private_reject": "Anda menolak tantangan",
  "tod_time_remain": "Waktu yang tersisa",
  "tod_time_sending": "Waktu kirim",
  "tod_tooltip_max_size_video": "Kapasitas maksimum untuk video adalah 10GB",
  "tod_tooltip_max_size_video_image": "Kapasitas maksimum untuk gambar adalah 4MB, untuk video adalah 10GB",
  "tod_video_format_incorrect": "Format video tidak sesuai",
  "tod_video_img_incorrect_size": "Gambar atau video melebihi kapasitas yang diizinkan.",
  "tod_video_incorrect_size": "Video melebihi kapasitas yang diizinkan",
  "tod_video_incorrect_type": "Pilih file dengan format MP4 atau MOV",
  "tod_video_over_size": "Kapasitas video melebihi peraturan yang diizinkan",
  "tod_view_detail": "Lihat detailnya",
  "tod_wait_upload_video": "Mohon tunggu sedikit lagi!",
  "tod_warning_reject_tod": "Setelah menolak, Anda tidak dapat melakukan tantangan ini.",
  "tooltip_list_tod": "Klik Dare (Tantangan) untuk melihat detail dan memutuskan untuk menerima atau menolak.",
  "total": "Total",
  "total_commission": "Total komisi",
  "total_donation": "dukungan",
  "total_order_brand_gift": "id_Số đơn hàng",
  "total_order_income_brand_gift": "id_Số brand có đơn",
  "total_order_income_tooltip_brand_gift": "id_Số brand phát sinh tối thiểu 1 đơn",
  "total_order_tod": "tantangan",
  "total_order_tooltip_brand_gift": "id_Số đơn mua hàng từ Fan",
  "total_search_report_by_subs": "Ada {{number}} hasil yang ditemukan",
  "total_view_brand_gift": "id_Lượt xem",
  "total_view_tooltip_brand_gift": "id_Số lượt Fan vào xem mục Brand Gift",
  "traffic_link_is_duplicated": "id_Liên kết bị trùng lặp",
  "traffic_link_is_empty": "id_Nền tảng không có kênh",
  "truth_or_dare": "Truth or dare",
  "truth_or_dare_detail": "Detail tantangan",
  "try_upload_again": "Coba lagi",
  "tt_estimate_approved_commission": "Dihitung berdasarkan rata-rata approve rate dari Campaign",
  "undefined": null,
  "unhide_product": "Tikdak sembunyi",
  "unhide_product_fail": "Produk masih bisa dilihat",
  "unhide_product_success": "Produk tidak sembunyi lagi",
  "unique_click_tooltip": "Jumlah pengunjung dari banyak perangkat mengklik link affiliate per hari",
  "unique_clicks": "Jumlah Klik",
  "update_referral_error": "Gagal pembaruan Kode Referral",
  "upload_fail": "Gagal mengunggah",
  "upload_file_exceed_limit": "Harap pilih foto yang lebih kecil dari 8000x8000 px",
  "upload_failed": "Unggah kegagalan",
  "upload_product": "Upload produk",
  "uploading": "Mengunggah ...",
  "user_guide": "Tutorial",
  "user_information": "Informasi Akun",
  "uv_commission": "Komisi UV",
  "vg_buyer": "Pemberi",
  "vg_error_upload": "Opps! Ada masalah dalam proses pengunduhan data. Silakan coba untuk memuat ulang halaman lagi!",
  "vg_income_tooltip": "Pendapatan Virtual Gift",
  "vg_order_tooltip": "Gift orders yang diterima Anda",
  "vg_product": "Gift",
  "vg_show_list_all_orders": "List Orders",
  "vg_time_buy": "Waktu mengirim gift",
  "video_shoutout": "Video Shoutout",
  "voucher_create_new_button": "Kode",
  "voucher_list": "Daftar",
  "voucher_list_title": "Buat kode diskon",
  "voucher_title": "Kode diskon",
  "yes": "Ya/Iya"
} 
} 
export default id