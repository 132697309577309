const  en =  {
 translation: {
  "6_40_characters_are_allowed": "Please enter from 6 to 40 characters",
  "The_interval_must_be_less_than_days": "The interval must be less than {{max}} days",
  "accepted": "Reached",
  "action_has_not_been_performed": "This action has not been done. Please try again",
  "add": "More",
  "add_channel": "en_Thêm kênh",
  "add_cover_image": "Add cover image",
  "add_image": "Add image {{n}}",
  "add_new_product": "Create products",
  "add_product": "Add product",
  "add_store_new_section_group": "Add new items",
  "atc_config": "Config",
  "atc_confirm": "Confirm",
  "atc_pause": "Pause",
  "atc_start": "Start",
  "atc_title": "Set up auto crawl",
  "campaign_detail_page_breadcumb": "Detail",
  "campaign_drawer_base_commission": "The commission can be received, when customers click to your Affiliate link and complete payment for any orders on E -commerce platforms/ website",
  "campaign_drawer_brand_commission": "The commission can be received, when customers click to your Affiliate link and complete payment for any orders of any Brands on E -commerce platforms/ website",
  "campaign_drawer_exclusive_commission": "The commission can be received, when customers click to your Affiliate link and complete payment for any orders of any Brands on E -commerce platforms/ website (Exclusive with Ecomobi)",
  "campaign_ecommerce_campaign": "E -commerce Campaign",
  "campaign_ecommerce_campaign_breadcumb": "E -commerce Campaign",
  "campaign_ecommerce_campaign_tooltip": "Affiliate Marketing campaign links to the E-commerce platforms (Shopee/ Lazada/...)",
  "campaign_empty_campaign_content": "Hurry up 👉Click  \"See all\" to discover 🙌 🙌 🙌",
  "campaign_empty_campaign_title": "many attractive campaigns are waiting for you!",
  "campaign_exclusive_commission": "Exclusive commissions",
  "campaign_filter": "Filter",
  "campaign_filter_Indonesia": "Indonesia",
  "campaign_filter_Lazada": "Lazada",
  "campaign_filter_Malaysia": "Malaysia",
  "campaign_filter_Philippines": "Philippines",
  "campaign_filter_Shopee": "Shopee",
  "campaign_filter_Singapore": "Singapore",
  "campaign_filter_Thailand": "Thailand",
  "campaign_filter_Vietnam": "Vietnam",
  "campaign_filter_find_campaign": "Search for campaigns ...",
  "campaign_filter_label.promotion_kit_all": "All product",
  "campaign_filter_label.promotion_kit_cash": "Bonus",
  "campaign_filter_label.promotion_kit_cpc": "Exclusive click bonus",
  "campaign_filter_label.promotion_kit_product": "Sample products",
  "campaign_filter_label.promotion_kit_voucher": "Discount code",
  "campaign_filter_text": "Filter:",
  "campaign_for_membership": "en_Dành cho thành viên",
  "campaign_for_membership_tooltip": "en_Chiến dịch tiếp thị dành riêng cho các Creators đã đăng ký gói Pro",
  "campaign_gift": "Promotion Kit",
  "campaign_gift_exclusive_click": "Exclusive click bonus",
  "campaign_giif_commission": "Requirements for receiving promotion kit",
  "campaign_has_not_config_setting_onair_rules": "en_Liên kết không hợp lệ",
  "campaign_inventory_number": "{{number}} slots left",
  "campaign_list": "Campaign list",
  "campaign_max_address_input": "Please enter maximum {{word}} characters",
  "campaign_max_character": "Maximum {{word}} characters",
  "campaign_max_phone_input": "invalid phone number",
  "campaign_monopoly": "Exclusive",
  "campaign_no_campaign": "No campaign found",
  "campaign_not_exist": "This campaign does not exist",
  "campaign_noti_is_pending_campaign": "Your campaign request is waiting for approval",
  "campaign_number_per_unique_click": "{{number}} {{currency}} / unique Click",
  "campaign_other": "Other",
  "campaign_policy": "Rules",
  "campaign_promotion": "Promotion",
  "campaign_promotion_kit_field_required": "Please enter this information",
  "campaign_promotion_kit_max_selected": "en_Chọn tối đa {amount} ưu đãi",
  "campaign_promotion_kit_notification": "You are not qualified for this offer package. Please check the qualifications, and contact support if you need assistance",
  "campaign_promotion_kit_selected": "en_Đã chọn",
  "campaign_receive_gift_from_brand": "Choose gifts from the brand",
  "campaign_receive_info_sample_product": "Receiving information about sample products👇👇",
  "campaign_redirect_link_shop": "View brand",
  "campaign_request_offer_pending": "Pending",
  "campaign_request_offer_reject": "Campaign registration",
  "campaign_request_offer_success": "GENLINK",
  "campaign_request_policy_first": "Please read carefully the following terms before sending request to receive promotion kit from Brands. You can not continue unless you agree with these",
  "campaign_request_policy_second": "1. Due to the limited number of registrations, please read the campaign requirements carefully before registering to have a chance of receiving benefits from the Brand.\r\n2. Upload enough number of required posts within the time range and submit through the system to complete the campaign.\r\n3. With CPC (Cost per Click) promotion, you are required to attach the correct product links generated through the Ecomobi system. With Tiktok channel, make sure to attach the product link in the bio or Ecomobi Landing Page\r\n4. Do not delete or set posts privately within 3 months (unless there is a reasonable exception and must notify the Ecomobi support team in advance).\r\n5. If KOL fails to properly and fully complete the requirements before the commitment timeline, KOL is responsible for paying ALL fees incurred, including but not limited: item/voucher value, booking fee, shipping fee and other expenses (if any).\r\n6. Do not resell, transfer or cancel products/vouchers during the campaign period",
  "campaign_request_promotion_kit": "Request",
  "campaign_reset": "Reset",
  "campaign_sample_product": "Sample products",
  "campaign_search_no_campaign": "No result is found",
  "campaign_send_quest_confirm_button": "Confirm",
  "campaign_send_request_confirm": "I have read comprehensively the above information. I confirm to register for a promotion kit and provide information as committed",
  "campaign_send_request_policy": "Please read carefully the following terms before sending request (You can not continue unless you agree with these):\n\n1. Due to the limited number of registrations, please read the campaign requirements carefully before registering to have a chance of receiving benefits from the Brand.\n2. Upload enough number of required posts within the time range and submit through the system to complete the campaign.\n3. With CPC (Cost per Click) promotion, you are required to attach the correct product links generated through the Ecomobi system. With Tiktok channel, make sure to attach the product link in the bio or Ecomobi Landing Page\n4. Do not delete or set posts privately within 3 months (unless there is a reasonable exception and must notify the Ecomobi support team in advance).\n5. If KOL fails to properly and fully complete the requirements before the commitment timeline, KOL is responsible for paying ALL fees incurred, including but not limited: item/voucher value, booking fee, shipping fee and other expenses (if any).\n6. Do not resell, transfer or cancel products/vouchers during the campaign period",
  "campaign_shop_campaign": "Shop campaign",
  "campaign_shop_campaign_breadcumb": "Shop campaign",
  "campaign_shop_campaign_max_input": "Maximum {{number}} unique click",
  "campaign_shop_campaign_tooltip": "Affiliate Marketing campaign links to the Brands store on E-commerce platforms (shop on Shopee/ Lazada/ etc)",
  "campaign_showing": "Showing {{current}} / {{total}}",
  "campaign_sold_out": "Sold out",
  "campaign_time_active": "{{start_date}} - {{end_date}}}",
  "campaign_toastr_failed_promotion_kit": "Oops! The promotion you request is no longer available",
  "campaign_toastr_success_promotion_kit": "Your request has been sent to the Brand for approval",
  "campaign_toastr_warning_promotion_kit": "{{sucessitem}}/{{{all}} request has been sent to the brand for approval",
  "campaign_total_campaign_show": "Found {{number}} campaign",
  "campaign_unlimited_campaign": "Unlimited",
  "campaign_upcoming": "Upcoming",
  "campaign_upto": "up to",
  "campaign_upto_item_campaign": "Up to",
  "campaign_upto_tooltip": "UPTO {{Commission}}",
  "campaign_validate_xss_message": "Invalid information",
  "campaign_view_all_list": "See all",
  "campaign_web_campaign_breadcumb": "Website campaign",
  "campaign_website_campaign": "Website campaign",
  "campaign_website_campaign_tooltip": "Affiliate Marketing campaign links to the Brand Website",
  "cancel": "Cancel",
  "cancel_create_product": "Are you sure to discard adding new products?",
  "cancel_edit_product": "Are you sure to discard editing?",
  "cannot_read_content_from_clipboard": "Cannot read content from clipboard",
  "channel_follower": "en_Lượt theo dõi",
  "channel_no_link": "en_Nền tảng không có kênh",
  "channel_not_enough_condition": "en_Nền tảng không đủ điều kiện",
  "check_condition_setting_traffic_source_is_invalid": "en_Liên kết không hợp lệ",
  "check_detail_report": "Check detailed reports",
  "check_onair_rule_traffic_source_is_invalid": "en_Liên kết không hợp lệ",
  "choose_a_form_to_post_experience": "en_Chọn hình thức lên bài nghiệm thu",
  "choose_a_form_to_post_experience_description": "en_Chọn kênh bạn sẽ lên bài nghiệm thu. Nếu kênh bạn cọn chưa có thông tin, vui lòng nhập thông tin kênh. Nếu kênh bạn chọn đã có dữ liệu, vui lòng bỏ qua.",
  "choose_another_channel": "en_Vui lòng thêm kênh hoặc chọn nền tảng khác",
  "choose_social": "Choose your main channel",
  "clicks": "Clicks",
  "commission": "Commission",
  "commission_passio": "Commission",
  "commission_tooltip": "Total commission arises from your affiliate links",
  "commission_upto": "Commision up to",
  "confirm_cancel": "Changes you made may not be saved.",
  "confirm_new_password": "Confirm the new password",
  "content_reject_reason": "Content",
  "conversion_rate_brand_gift": "Conversion rate",
  "conversion_rate_tooltip_brand_gift": "Conversion rate of total success order/Visits to Brand Gift",
  "copied": "Copied",
  "copy_link": "Copy Link",
  "copy_link_fail": "Copy the link failed",
  "copy_link_success": "Copy the link successfully",
  "course_manager": "Quản lý học viên",
  "course_member": "Tiến độ học viên",
  "cover_image": "Cover image",
  "create_order_label": "Created date :",
  "create_product": "Create products",
  "create_product_choose": "Choose the type of product you want to add",
  "create_product_successfull": "Product added successfully",
  "create_voucher_title": "Create discount codes",
  "delete_product": "Delete Product",
  "delete_product_success": "Delete products successfully",
  "delete_product_title": "Are you sure to delete this product?",
  "delete_product_upload_title": "Are you sure to delete product data?",
  "digital_product": "Digital Product",
  "digital_product_home": "Home page",
  "digital_product_order_detail": "Details of order",
  "digital_product_order_report": "Digital Product Order",
  "digital_product_overview": "Overview",
  "digital_product_report": "Digital Product",
  "digital_product_ss_payment_history": "Payment history",
  "digital_product_total_income": "Total income",
  "digital_product_total_income_tooltip": "total income",
  "digital_product_total_products_sold": "Total products sold",
  "digital_products": "Digital product",
  "donated": "given one",
  "donation": "Donation",
  "done": "Done",
  "dont_have_gift": "There are no gifts during this time",
  "dont_have_order": "No orders during this time",
  "down_to_bottom_label": "Down to the bottom",
  "dp_add_product": "Create products",
  "dp_all": "All",
  "dp_avantage_course_product": "Advantages of course products at Passio:",
  "dp_buyer": "Buyer",
  "dp_cancel_delete": "Cancel",
  "dp_cat_course": "Course / Tutorial",
  "dp_cat_course_tutorial": "Course / Tutorial",
  "dp_cat_exclusive_ebook": "Ebook/Document",
  "dp_cat_online_consultion": "Online consulting 1-1",
  "dp_cat_photo": "Image",
  "dp_cat_random_product": "Products any",
  "dp_cat_ticket_account": "Ticket/ Account",
  "dp_cat_video": "Exclusive video",
  "dp_close_dialog": "Close",
  "dp_confirm_delete": "Confirm delete",
  "dp_content_cant_download": "This feature is only supported on the website",
  "dp_continue_editing": "Continue to add products",
  "dp_course_avantage_1": "Fair and used for many fans",
  "dp_course_avantage_2": "Create course quickly",
  "dp_course_avantage_3": "Limit downloading lectures",
  "dp_course_avantage_4": "Easily track orders, income",
  "dp_default_title_block": "Products",
  "dp_delele_items": "Product deletion",
  "dp_deleting_warning": "Are you sure you want to delete this product?",
  "dp_edit_escape": "Escape",
  "dp_edit_set_up": "Display editing",
  "dp_error_price": "Promotional price must be smaller than the original price",
  "dp_exit_deleting_warning": "Your changes will not be saved. Are you sure you want to escape?",
  "dp_exit_editing": "Cancell product?",
  "dp_explain_course_product": "Is a solution for Creator, fans can teach and learn online to help save costs and save time",
  "dp_home": "Home page",
  "dp_income": "Income",
  "dp_income_tooltip": "Sales of product sales",
  "dp_intro_content_1": "Depending on your strengths and desires, Passio provides a full range of digital products such as photos, videos, online courses, ebooks, links, ...",
  "dp_intro_content_2": "With less than 3 minutes, you can configure and sell a new digital product. Moreover, after the successful buyer Thanh Tho, the product will be sent immediately to their email, helping to simplify shipping and save time",
  "dp_intro_content_title": "Digital products are understood as online products, not grasped, containing useful content for users. Example: Video, photos, ebook, online course, ..",
  "dp_intro_title_1": "Diverse types of products for you to choose",
  "dp_intro_title_2": "Easy to manage products and orders with a few operations",
  "dp_intruct_content_step_1": "Access my product screen and click \"Create a product\"",
  "dp_intruct_content_step_2": "Choose the type of product you want to create",
  "dp_intruct_content_step_3": "Fill in the product information and click \"Save the product\"",
  "dp_intruct_content_step_4": "To configure digital product blocks, access homepage and press \"Set display\" / \"Display settings\"",
  "dp_intruct_set_up": "Instructions for setup",
  "dp_learn_dp": "Learn about digital products",
  "dp_list_digital_product": "List",
  "dp_list_order": "List of orders",
  "dp_list_product": "List",
  "dp_my_products": "My product",
  "dp_order": "Order",
  "dp_order_tooltip": "Product order number",
  "dp_original_price": "Cost",
  "dp_overview": "Overview",
  "dp_page_tittle_iframe": "List of products",
  "dp_page_tittle_setting_iframe": "Digital product",
  "dp_price_sale": "Promotional price",
  "dp_product": "Product",
  "dp_product_review": "Preview the product",
  "dp_report": "Report",
  "dp_send_request_course_now": "Send the request now",
  "dp_send_request_course_success": "Successful request",
  "dp_send_request_course_success_confirm": "Close",
  "dp_send_request_course_success_content": "PASSIO will contact you in the shortest time if the request is approved",
  "dp_set_up": "Setting display",
  "dp_set_up_message": "Select the button below to switch to Passio Page and configure the digital product block",
  "dp_show_list_all_orders": "List of orders",
  "dp_step_1": "Step 1",
  "dp_step_2": "Step 2",
  "dp_step_3": "Step 3",
  "dp_step_4": "Step 4",
  "dp_system_course_free": "If you do not enter \"price\", \"original price\", the system will understand this course is free",
  "dp_time_buy": "Order Time",
  "dp_title_cant_download": " File could not be downloaded",
  "dp_title_request_course": "Product introduction course",
  "dp_waitlist_pending": "Wait for approval request",
  "dp_what_digital_product": "What is digital product?",
  "dp_what_is_the_course_product": "What is the course?",
  "ecm_advertiser_method": "Forms of advertising",
  "ecm_commission_policy": "Commission policy",
  "ecm_cookie_policy": "Cookies rule",
  "ecm_note": "Important notes",
  "ecm_order_recording": "How to record orders",
  "eco_regards": "Best regard,",
  "ecomobi_generate_link_tool": "Ecomobi Link Generator",
  "ecomobi_team": "Ecomobi SSP Team",
  "edit_product": "Edit Product",
  "edit_voucher_title": "Modify discount codes",
  "error": "Error",
  "error_500": "System Overload. Please try again later",
  "error_active_account_msg": "Oops! There is a problem during account activation. Please contact the support department",
  "error_backhome": "Go back to the main page",
  "error_image_size": "Image exceeds allowed size",
  "error_incorrect_image_format": "Incorrect image format",
  "error_input_name_required": "Please enter the product name",
  "error_input_price_required": "Please enter the product price",
  "error_input_required": "You have not input the information",
  "error_invalid_redirect_url": "Please enter correct URL format",
  "error_page_msg": "No page found",
  "error_page_note_found": "Page not found",
  "error_pdf_size": "Ebook/PDF exceeds allowed size",
  "error_thumbnail_size": "Image exceeds allowed size",
  "error_thumbnail_size_dp": "Please upload a file with a maximum size of 30MB",
  "error_video_size": "Video exceeds allowed size",
  "error_zip_size": "Zip file exceeds allowed size",
  "free_product": "Free product",
  "go_to_top_label": "Go to the top",
  "hide_product": "Hide Product",
  "hide_product_fail": "Fail to hide products",
  "hide_product_success": "Hide products successfully",
  "history_link_appear": "You can see your link history here",
  "home": "Home",
  "hot_campaigns": "Hot campaign",
  "image": "Image {{n}}",
  "income": "Revenue",
  "income_label": "Revenue",
  "incorrect_file_format": "Incorrect format",
  "input_select_all": "All",
  "instruc_on_virtual_gift": "👉🏻 By enabling this feature, you can receive gifts from your supporters. Gifts can be exchanged for money. Try this feature now!",
  "kol_edit_theme": "Repair theme",
  "label.promotion_kit_all": "All product",
  "label.promotion_kit_cash": "Bonus",
  "label.promotion_kit_cpc": "Exclusive click bonus",
  "label.promotion_kit_product": "Sample products",
  "label.promotion_kit_voucher": "Discount code",
  "label_special_kol": "For new KOLs (newbies):",
  "last_note_condition": "* Under urgent circumstances, Ecomobi's decision is the final judgment.",
  "link_channel": "en_Link nền tảng",
  "link_history": "Link history",
  "link_refer_friends": "Link to refer friends:",
  "link_video": "Enter your channel link",
  "link_youtube_invalid": "Invalid link",
  "link_youtube_note": "Enter the video link where you plan to place short links.",
  "list_service": "List Service",
  "make_money_with_passio": "Make Money With Passio! Yayyy",
  "make_money_with_passio_content": "Join the campaigns with Passio now to create more income!",
  "menu": "Menu",
  "message_from_seller": "The message you sent",
  "message_from_you": "Message from you",
  "message_from_you_placeholder": "Input any exclusive content just for customers who ordered and paid. (E.g: a secret link for your customer, password for files/documents, Zoom password, etc). Don't forget to send thanks to the customers who paid for your product/service😊",
  "move_down_label": "Move down",
  "move_to_label": "Move to",
  "move_up_label": "Move up",
  "my_empty_products_content": "Add your products to generate income with Ecomobi!",
  "my_empty_products_title": "Let's build your own store! Yayyy",
  "my_page": "My Passio",
  "my_request": "My request",
  "my_request_acceptance": "Acceptance",
  "my_request_close": "Close",
  "my_request_delivered": "Has received the goods",
  "my_request_delivery_confirm_note": "I confirmed I received the sample product. Will not request to return the goods after I confirm.",
  "my_request_error_file_size": "Maximum image size is 5 MB",
  "my_request_error_file_type": "Required Image formats: JPEG, JPG, PNG, GIF",
  "my_request_error_required": "Please provide this information",
  "my_request_error_url_match": "Please enter the URL format correctly",
  "my_request_feedback": "Feedback",
  "my_request_feedback_placeholder": null,
  "my_request_link_input_placeholder": "Enter the acceptance link",
  "my_request_max_result_item": "Each time only sends a maximum of 20 tests for 1 preferential package",
  "my_request_not_exist": "This request does not exist",
  "my_request_reason": "Reason",
  "my_request_reason_label": "Acceptance evaluation",
  "my_request_save_fail": "Oops! There is a problem during data processing. Please try again later!",
  "my_request_save_successfull": "Save successfully",
  "my_request_send": "To send",
  "my_request_send_feedback_success": "Your review has been sent",
  "my_request_send_result": "Send results",
  "my_request_traffic_placeholder": "Communication",
  "my_request_watch_all": "See all ({{sum}})",
  "new_password": "A new password",
  "next": "Next",
  "no": "No",
  "no_campaign": "No requirement 🤗",
  "no_result": "No result is found",
  "note_change_condition": "*Ecomobi has an authorization to adjust rank conditions and rewards of the program at any moment without notification.",
  "notice_bonus_for_user": "ANNOUNCEMENT OF BONUS LEVELS FOR {{publisher_group}}",
  "notification": "Notifications",
  "notification_back_to_previous": "Back to previous page",
  "notification_breadcumb": "Notification",
  "notification_campaign": "Campaign",
  "notification_detail_page_breadcumb": "Notification detail",
  "notification_empty_campaign": "No campaign yet",
  "notification_empty_news": "No news yet",
  "notification_empty_payment": "No payment yet",
  "notification_news": "News",
  "notification_not_exist": "This notification does not exist",
  "notification_payment": "Payment",
  "notification_read_all": "Mark all as read",
  "off_virtual_gift_noti": "You have disabled the Gift feature.",
  "offer_package": "Promotion Kit",
  "on_virtual_gift_noti": "You have successfully enabled the Gift feature.",
  "or": "Or",
  "order": "Order",
  "order_history": "Order history",
  "order_id": "Order Code",
  "order_passio": "Order",
  "order_payment_fee": "Payment Fee",
  "order_platform_fee": "Platform Fee",
  "order_response": "Response",
  "order_revenue": "Income",
  "order_tooltip": "Number of orders has been purchased via your affiliate link",
  "passio_page": "Passio Page",
  "password_confirmation": "Confirm password",
  "pay_available_balances": "Available balances",
  "payment_agree": "Agree",
  "payment_button_request_pay": "Create a requirement to withdraw",
  "payment_create_request_failed": "Error requirements",
  "payment_create_request_success": "Complete",
  "payment_created_request_payment": "Create payment requirements",
  "payment_info_bank_account_name_not_same": "Enter Fullname that match your Account Name and information in ID/Passport",
  "payment_info_description_file_card": "Input photos of your ID card: Front and back. High-quality images are required.",
  "payment_info_error_message": "Payment record has not been saved. Please update later!",
  "payment_info_field_is_required": "Please enter this information",
  "payment_info_full_name": "Full name",
  "payment_info_id_card": "Identity Card Number",
  "payment_info_place_holder_adress_payment": "Enter payment address",
  "payment_info_placeholder_bank_branch": "Enter bank branch",
  "payment_info_placeholder_bank_fullname": "Enter account name",
  "payment_info_placeholder_bank_name": "Enter bank name",
  "payment_info_placeholder_card_date": "Date of issue",
  "payment_info_placeholder_family_card_name": "Enter your family card name",
  "payment_info_placeholder_family_card_number": "Enter family card number",
  "payment_info_placeholder_id_card": "Issued by",
  "payment_info_placeholder_number_bank_account": "Enter account number",
  "payment_info_placeholder_personal_tax": "Enter personal tax code",
  "payment_info_placeholder_postal_code": "Enter your postal code",
  "payment_info_please_enter_at_most_characters": "Please enter maximum {{n}} characters",
  "payment_info_please_enter_number": "Please enter numeric characters only (0-9)",
  "payment_info_success_message": "Payment record has been updated successfully. Please wait for authentication in 7 days.",
  "payment_info_title_card_back": "Identity Card Backside",
  "payment_info_title_card_front": "Identity Card Frontside",
  "payment_info_title_upload_image": "Upload photo",
  "payment_infor_payment_data": "Payment profile",
  "payment_infor_personal_infor": "Personal information",
  "payment_menu": "Payment",
  "payment_message_not_update_information": "Your account has not been provided with full personal information or unprocessed payment documents. Please update for payment!",
  "payment_message_note": "Note: Authentication time for payment records is about 7 days",
  "payment_message_withdraw_failed": "Payment requirements have not been successful. Please create later!",
  "payment_message_withdraw_sending": "Payment requirements are being processed. Please wait a moment...",
  "payment_message_withdraw_success": "Payment requirements have been successfully created. The expected payment date is: {{date}}.",
  "payment_pay_available_balances": "Available balances",
  "payment_pay_minimum_drawable_balance": "Minimum balance: {{money}}",
  "payment_pending_message": "You are having a payment request waiting for approval.",
  "payment_support_text": "We can support payment of all money in the account at 1 transaction.",
  "payment_title": "Payment",
  "payment_total_money": "Amount of money",
  "payment_update_information": "Update information",
  "payment_withdraw": "Withdraw money",
  "payment_withdraw_to": "Withdraw money",
  "pending": "Pending",
  "pending_notify_msg_my_request": "You will have the opportunity to receive additional packages if approved by the brand",
  "placeholder_reject_content": "Enter the reason you refuse this request",
  "placeholder_response_virtual_gift": "Send your response to this gift giver",
  "platform": "Platform",
  "please_enter_1_in_2_fields": "en_Vui lòng cung cấp 1 in 2 thông tin này",
  "please_enter_correct_link": "Please enter your {{traffic_source}} link correctly",
  "please_using_letters_a_z_and_number_0_9": "Please only enter the letter (a-z) and number (0-9)",
  "price": "Price",
  "price_label": "Price:",
  "product": "Product",
  "product_description": "Product Description",
  "product_description_placeholder": "Please describe the content of the service/product that you provide.",
  "product_name": "Product name",
  "product_review_photo": "Preview photo of the product",
  "product_select_upload_type_suggest": "Choose 1 of the following 2 products 👇👇👇",
  "product_type": "Product type",
  "product_upload_choose": "Upload any product",
  "product_upload_file_suggest": "You can sell any product in the form of digital file (photo, video, ebook, zip files, etc). Set your creativity free! ✍🏻",
  "product_upload_url_suggest": "This product consists of consulting or coaching via online video communication apps such as Zoom or Google Meet, etc. You should give your customer the most detailed information about your services. ✍🏻",
  "product_url_choose": "Redirect URL",
  "profile": "Personal page",
  "profile_account_infomation": "account information",
  "profile_account_name": "Account Name",
  "profile_account_number": "Account Number",
  "profile_address": "Payment Address",
  "profile_bank": "Bank Name",
  "profile_bank_branch": "Branch",
  "profile_change_password": "Change the password",
  "profile_change_password_fail": "The password has not been changed. Please try again later.",
  "profile_change_password_note": "You should choose to set a strong password but not used anywhere else",
  "profile_change_password_success": "You have successfully changed your password",
  "profile_change_phone_number_note": "Please use your own phone number for the best support.",
  "profile_confirm": "Confirm",
  "profile_confirm_password_error": "Did not match with the new password",
  "profile_date_of_birth": "Date of birth",
  "profile_description_verify_success": "If you wish to change your payment info, please contact support.",
  "profile_description_verify_warning": "The verification process takes a maximum of 7 working days",
  "profile_email": "Email",
  "profile_error_channel_format": "Please enter the link format {{channel}}",
  "profile_error_duplicate_channel": "The channel has existed",
  "profile_error_max_length": "Please enter maximum {{max}} characters",
  "profile_error_min_length": "Please enter at least {{min}} characters",
  "profile_error_number_mobile": "Please only enter the digit (0-9)",
  "profile_family_card_name": "Family Card Name",
  "profile_family_card_number": "Family Card Number",
  "profile_field_required": "Please enter this information",
  "profile_file_card": "Photo of your ID",
  "profile_file_family_card_image": "Family Card Image",
  "profile_file_tax_id_image": "Tax ID Image",
  "profile_followers_required": "Please enter the number of followers",
  "profile_full_name": "Fullname",
  "profile_full_name_suggest": "Enter Fullname that match your Account Name and information in ID/Passport",
  "profile_gender": "Sex",
  "profile_gender_female": "Female",
  "profile_gender_male": "Male",
  "profile_gender_other": "Other",
  "profile_id_number": "Identity Card Number",
  "profile_id_number_created_at": "Date Of Issue",
  "profile_id_number_place": "Issue By",
  "profile_image_id": "Upload image ID",
  "profile_image_id_suggest": "Attach 2 photos of your ID card: the front, the back. Request uploading clear images, details",
  "profile_industry": "Industry",
  "profile_industry_placeholder": "Choose your favorite industries",
  "profile_information": "Information",
  "profile_input_facebook_placeholder": "Enter your facebook channel",
  "profile_input_instagram_placeholder": "Enter your Instagram channel",
  "profile_input_lemon8_placeholder": "Enter your lemon8 channel",
  "profile_input_line_placeholder": "Enter your line channel",
  "profile_input_tiktok_placeholder": "Enter your tiktok channel",
  "profile_input_twitter_placeholder": "Enter your Twitter channel",
  "profile_input_youtube_placeholder": "Enter your YouTube channel",
  "profile_marital_status": "Marriage status",
  "profile_matrial_married": "Married",
  "profile_matrial_other": "Other",
  "profile_matrial_single": "Single",
  "profile_new_password_error": "New password cannot match with your current password",
  "profile_new_phone_number": "New phone number",
  "profile_old_password_error": "The current password is incorrect",
  "profile_password": "Password",
  "profile_payment_verification_status": "Payment authentication status",
  "profile_person_info_fail_message": "Personal records have not been saved successfully. Please update later!",
  "profile_person_info_success_message": "Your receipt record has been confirmed successfully.",
  "profile_personal_tax_code": "Personal Tax Code",
  "profile_personal_tax_code_had": "Yes",
  "profile_personal_tax_code_had_not": "No",
  "profile_personal_tax_code_helper": null,
  "profile_personal_tax_code_link": "here.",
  "profile_personal_tax_code_suggest": "en_Bạn có thể tra cứu mã số thuế cá nhân",
  "profile_phone_number": "Phone number",
  "profile_postal_code": "Postal Code",
  "profile_save_success": "You have updated successful information",
  "profile_social_channel_followers": "Number of Followers",
  "profile_social_channel_label": "Social platform link",
  "profile_social_channel_placeholder": "Enter quantity",
  "profile_social_media_channel": "Social network channel",
  "profile_social_media_channel_note": "Please add at least 1 social network channel",
  "profile_tab_account": "Account",
  "profile_tab_payment_info": "Billing Information",
  "profile_tab_user_info": "Personal information",
  "profile_update_mobile_success": "The phone number has been successfully updated",
  "profile_upload_avatar_success": "Cập nhật ảnh đại diện thành công",
  "profile_username": "User name",
  "profile_verify_incomplete": "Incompleted",
  "profile_verify_not_complete": "Unfinished",
  "profile_verify_not_provided": "Not provided",
  "profile_verify_pending": "Authentic",
  "profile_verify_success": "Authenticated",
  "profile_verify_waiting": "Waiting for verification",
  "proflie_update_mobile_fail": "The phone number has not been updated. Please do it later.",
  "public_product": "Product publishing",
  "public_product_note": "👉🏻 Turn this feature on, your product will be displayed in the store.",
  "quick_response": "Quick response",
  "random_product": "Create any product",
  "rank_bonus": "Rank Bonus",
  "rank_info": "Rank Info",
  "read_less": "Show less",
  "read_more": "Read more",
  "reason": "Reason",
  "receive_gift_from_customers": "Receive gifts from fans😊",
  "receiver_address": "Delivery address",
  "receiver_full_name": "Name of recipient",
  "receiver_phone": "Phone number of recipient",
  "recent_gift": "Recent gifts",
  "recent_order": "Recent order",
  "redirect_url_note": "Provide a publicly available link to the content you are selling such as: A Google Drive, A Youtube Video or A Zoom Invitation, etc",
  "redirect_url_placeholder": "URL to the redirect your buyers after purchase",
  "referral": "Invite Friends",
  "referral_achievement": "Achievements",
  "referral_bonus_amount": "Bonus",
  "referral_bonus_approved": "Approved",
  "referral_bonus_pending": "Pending",
  "referral_conditions": "Condition",
  "referral_friend": "Friend",
  "referral_join_passio": "Join Passio with {{name}}",
  "referral_method": "Method",
  "referral_save_code": "Save",
  "referral_timeline": "Application time",
  "referral_tooltip_bonus_amount": "Total bonus has been validated",
  "referral_tooltip_fixed_bonus": "Fixed bonus amount",
  "referral_tooltip_total": "The total number of referred people has been validated",
  "referral_total": "The total number of referred people",
  "referral_total_bonus_amount": "Total bonus",
  "referral_update_success": "Referral code has been updated",
  "register_select_channel": "Traffic Source",
  "reject_reason": "The reason for refusal",
  "rejected": "Rejected",
  "rejected_notify_msg_my_request": "Hello, thank you for signing up for the campaign! Currently your profile is not really a good match for the brand and product. See you in the next campaigns! Wish you a good day!",
  "reload": "Reload",
  "report": "Report",
  "report_affiliate": "Affiliate",
  "report_affiliate_breadcrumbs": "Affiliate report",
  "report_affiliate_general": "General Report",
  "report_affiliate_general_orders": "Orders",
  "report_by_link": "Report by link",
  "report_by_sub": "Report by Sub",
  "report_commission": "Commission",
  "report_digital_product_breadcrumbs": "Product report number",
  "report_general_report": "General report",
  "report_virtual_gift_breadcrumbs": "Gift report",
  "request_has_expired": "Overdue request",
  "required_field_code": "Please enter the introduction code please",
  "reset": "Reset",
  "responsed": "Responded",
  "results": "Result",
  "reviewed": "Not reached",
  "reviewed_multi_result_infinity_end_date_notify_msg_my_request": "You have {{count}} results that do not meet campaign's requirement. Kindly submit soon to complete the campaign!",
  "reviewed_multi_result_notify_msg_my_request": "You have {{count}} results that do not meet campaign's requirement. Kindly submit before {{end_date}} to complete the campaign!",
  "reviewed_one_result_infinity_end_date_notify_msg_my_request": "Your result does not meet campaign's requirement. Please submit another qualified to complete the campaign!",
  "reviewed_one_result_notify_msg_my_request": "Your result does not meet campaign's requirement. Please submit another qualified before {{end_date}} to complete the campaign!",
  "sale_amount": "Sale Amount",
  "save_channel": "Save",
  "save_product": "Save product",
  "save_successfull": "Changes saved successfully",
  "select_date": "en_Chọn thời gian",
  "select_date_range": "Choose time range",
  "selected_date_must_be_less_than_current_date": "Selected date be less than curent date",
  "send_acceptance": "Submit to acceptance",
  "send_feedback": "Response now",
  "send_request": "Send request",
  "setting_dp_add": "Add",
  "setting_dp_cancle": "Cancel",
  "shouout_empty_2_text": "Please click \"Create code\" to add new",
  "shouout_empty_text": "You currently have no requirements",
  "shouout_explore_now": "Explore now",
  "shouout_text_start_create": "Start creating shoutout",
  "shout_out": "Shoutout",
  "shoutout_about_service_description": "Video Shoutout is a service that allows fans to send to Creator requirements for a personalized video for specific occasions such as birthday, graduation, congratulations, ... Creator will respond to those requirements with one with one with one with one with one. video",
  "shoutout_about_service_title": "Introduction to the service",
  "shoutout_accept": "Confirm",
  "shoutout_accepted": "Made",
  "shoutout_answer": "How to answer",
  "shoutout_answer_method_description": "If you accept the request of creator fans, you need to reply with a video",
  "shoutout_answer_method_title": "Answer method",
  "shoutout_breadcrumb": "Video shoutout",
  "shoutout_btn_content": "Button content",
  "shoutout_button_back": "Come back",
  "shoutout_button_share": "Share",
  "shoutout_cancel": "Cancel",
  "shoutout_cancel_upload": "Cancel",
  "shoutout_change_file": "Change photos/videos",
  "shoutout_change_file_video": "Change video",
  "shoutout_confirm_cancle": "Are not",
  "shoutout_confirm_no": "Are not",
  "shoutout_confirm_request_warning": "After pressing \"Yes\" videos will be sent to fans. Are you sure this action?",
  "shoutout_confirm_yes": "Yes",
  "shoutout_content_step_1": "Choose \"Accept\" or \"Decline\" to respond to your Fan's requests",
  "shoutout_content_step_2": "Upload a video, or record a video from your smartphone, the video length should be no more than 90 seconds.",
  "shoutout_content_step_3": "Tap \"Confirm\". Your video will be sent and cannot be edited.",
  "shoutout_continue_editing": "Continue editing",
  "shoutout_copy_link_fail": "Copy link failure",
  "shoutout_copy_link_success": "Copy the link successfully",
  "shoutout_create_request": "Create immediately",
  "shoutout_create_shoutout_btn": "Start creating shoutout",
  "shoutout_decline": "Refuse",
  "shoutout_description": "Introduction content",
  "shoutout_description_modal_upload": "Please upload or shoot a video to answer your fan request",
  "shoutout_description_placeholder": "Enter the introduction content",
  "shoutout_description_request": "Content required",
  "shoutout_download_success": "Upload video successfully",
  "shoutout_edit": "Edit",
  "shoutout_edit_btn": "Edit shoutout",
  "shoutout_error_only_video_type": "Please upload to the file as one of the MP4, MOV formats",
  "shoutout_error_price_required": "Please enter the price",
  "shoutout_error_upload": "Error occurs, uploaded failure",
  "shoutout_error_video_required": "Please upload photos/videos",
  "shoutout_error_video_size": "Please upload images/ videos up to 500MB",
  "shoutout_error_video_type": "Please upload to the file as one of JPG, JPEG, GIF, PNG, BMP, Webp, HEIC, HEIC, HEIC, MP4, MOV",
  "shoutout_escape": "Escape",
  "shoutout_establish": "Set now",
  "shoutout_exit_deleting_warning": "Your edits will not be saved. Are you sure you want to escape?",
  "shoutout_exit_editing": "Escape editing?",
  "shoutout_file_intro": "Photo/video introduction",
  "shoutout_file_intro_note": "Upload images or videos to call fans to use the shoutout video from you",
  "shoutout_file_upload_note": "To answer fans, you need to upload a video",
  "shoutout_home": "Home page",
  "shoutout_home_title": "Home",
  "shoutout_how_to_answer": "How to answer",
  "shoutout_intro_content_image_build": "Service supports you interact closerly to your super big fan",
  "shoutout_intro_content_image_gift": "Feature that allows fans sending you request to make video in special occasions",
  "shoutout_intro_content_image_private": "Creator has the right to accept or refuse challenges",
  "shoutout_intro_content_image_request": "Through Passio, your fans can send you video requirements for special occasions: birthday, congratulations, anniversaries, video encouragement, inspiration ... with a certain fee Designed by yourself.",
  "shoutout_intro_content_image_share": "You and fans can share these meaningful videos with people through social networking sites.",
  "shoutout_intro_content_image_video": "You have 7 days to decide or reject fans' requests. If you refuse to do it, Ecomobi will refund all the paid money to the requester within 30 days.",
  "shoutout_intro_fail": "Failure",
  "shoutout_intro_title_gift": "How does the shoutout video work?",
  "shoutout_intro_title_image_build": "Building a \"big\" fan community",
  "shoutout_intro_title_image_gift": "Gifts with your own marks",
  "shoutout_intro_title_image_private": "Privately",
  "shoutout_intro_title_image_request": "Receive requirements from fans",
  "shoutout_intro_title_image_share": "Share meaningful moments.",
  "shoutout_intro_title_image_video": "Make video",
  "shoutout_introduce": "Introduce",
  "shoutout_learn_shoutout": "Learn about video shoutout",
  "shoutout_learn_tod": "Learn about truth or dare",
  "shoutout_list_request_title": "Request list",
  "shoutout_loading_service": "Downloading ...",
  "shoutout_loading_service_title": "Video shoutout",
  "shoutout_loading_upload": "Downloading ...",
  "shoutout_new": "New",
  "shoutout_no_file_selected": "No photos/videos have been selected",
  "shoutout_no_file_video_selected": "No video has been selected",
  "shoutout_order_code": "Request code",
  "shoutout_participate_description": "Creator can choose to make or reject fans' requirements",
  "shoutout_participate_title": "The right to join you",
  "shoutout_price": "Price",
  "shoutout_price_free": "Free of charge",
  "shoutout_price_placeholder": "Enter the price",
  "shoutout_processing": "Video is being processed to send to fans",
  "shoutout_reject": "Refuse",
  "shoutout_reject_cancle": "Close",
  "shoutout_reject_failed": "Refuse to fail",
  "shoutout_reject_request": "Refuse the request?",
  "shoutout_reject_request_warning": "Are you sure you want to refuse this request? The action will not be able to do it",
  "shoutout_reject_success": "Refused a request",
  "shoutout_reply": "Reply",
  "shoutout_reply_error_msg": "Feedback failed",
  "shoutout_reply_success_msg": "Congratulations, sent videos to your fans",
  "shoutout_request": "Request",
  "shoutout_request_detail": "Details required",
  "shoutout_request_reject_confirm": "Confirm",
  "shoutout_response_description": "After the fans submitted the request, Creator had 7 days to make the video. After this time, the amount of that request will be returned to fans",
  "shoutout_response_title": "7 days of response",
  "shoutout_save": "Save",
  "shoutout_save_video_success": "Successful shoutout video configuration",
  "shoutout_select_file": "Select photos/videos",
  "shoutout_select_file_video": "Select Video",
  "shoutout_select_video_upload": "Select Video",
  "shoutout_share": "Share",
  "shoutout_start": "Start set up video shoutout",
  "shoutout_start_message": "Set a shoutout video to receive a fan request",
  "shoutout_status_request": "Requirement",
  "shoutout_step_1": "Step 1",
  "shoutout_step_2": "Step 2",
  "shoutout_step_3": "Step 3",
  "shoutout_text_from": "From",
  "shoutout_text_to": "Arrive",
  "shoutout_time_created": "Time creation",
  "shoutout_time_remain": "{{day}} days left",
  "shoutout_time_remain_status": "Time remaining",
  "shoutout_time_remain_title": "Time remaining",
  "shoutout_title_modal_upload": "Answer the request",
  "shoutout_type_advice": "Advice",
  "shoutout_type_anniversary": "Special occasion",
  "shoutout_type_birthday": "Birthday",
  "shoutout_type_mobilize": "Encourage",
  "shoutout_type_other": "Other occasions",
  "shoutout_type_question": "Question",
  "shoutout_type_support": "Support: JPG, JPEG, GIF, PNG, BMP, Webp, HEIF, HEIC, MP4, MOV up to 500MB",
  "shoutout_type_video_support": "Support: MP4, MOV up to 500MB",
  "shoutout_type_vocative_adik": "Adik",
  "shoutout_type_vocative_anda": "Anda",
  "shoutout_type_vocative_aunt": "Miss",
  "shoutout_type_vocative_bapak": "Bapak",
  "shoutout_type_vocative_big_uncle": "Uncle",
  "shoutout_type_vocative_bro": "You",
  "shoutout_type_vocative_friend": "Friend",
  "shoutout_type_vocative_grandfather": "Grandfather",
  "shoutout_type_vocative_grandmother": "Grandma",
  "shoutout_type_vocative_he": "Older brother",
  "shoutout_type_vocative_he_him": "He/Him",
  "shoutout_type_vocative_ibu": "Ibu",
  "shoutout_type_vocative_kakak": "Kakak",
  "shoutout_type_vocative_paman": "Paman",
  "shoutout_type_vocative_she": "Older sister",
  "shoutout_type_vocative_she_her": "She/Her",
  "shoutout_type_vocative_tante": "Tante",
  "shoutout_type_vocative_thai_1": "คุณ",
  "shoutout_type_vocative_thai_2": "พี่ชาย",
  "shoutout_type_vocative_thai_3": "พี่สาว",
  "shoutout_type_vocative_thai_4": "น้อง",
  "shoutout_type_vocative_thai_5": "น้า",
  "shoutout_type_vocative_thai_6": "อา",
  "shoutout_type_vocative_thai_7": "ลุง",
  "shoutout_type_vocative_thai_8": "ยาย",
  "shoutout_type_vocative_thai_9": "ปู่",
  "shoutout_type_vocative_they_them": "They/Them",
  "shoutout_type_vocative_uncle": "Uncle",
  "shoutout_type_vocative_you": "You",
  "shoutout_upload_cancel": "Escape",
  "shoutout_upload_continute": "Continue editing",
  "shoutout_video_required": "Please upload the video",
  "shoutout_view_detail": "See details",
  "shoutout_warning_cancel_upload": "Your edits will not be saved. Are you sure you want to escape?",
  "shoutout_warning_title_cancel_upload": "Escape editing?",
  "shoutout_your_feedback": "Your feedback",
  "ss_account_total": "Total",
  "ss_account_total_not_sold": "Remaining",
  "ss_account_total_sold": "Sold",
  "ss_add_photo_product_success": "Saved successfully",
  "ss_add_voucher_success": "Add new discount code successfully",
  "ss_builder_digital_product_free_label": "Free of charge",
  "ss_builder_passio_page_ready": "Passio Page is ready",
  "ss_builder_passio_page_ready_description": "Passio page is ready to use and replace the old Landing Page version",
  "ss_builder_start_use_passio_page": "Start using Passio Page now",
  "ss_builder_to_new_version": "Go to Passio Page",
  "ss_button_title_default_donation": "Make a donation to {{name}}",
  "ss_buyer": "Buyer",
  "ss_call_api_error": "There have been errors, please try again later",
  "ss_cancel": "Cancel",
  "ss_category_add_product_label": "Add Product",
  "ss_course_info_preview_button": "Please fill in the information \"Course Setup\" & \"Lecture\" to preview the course content",
  "ss_course_manager_complete_date": "Ngày hoàn thành",
  "ss_course_manager_last_date": "Ngày học gần nhất",
  "ss_course_manager_list_empty": "Chưa có học viên nào",
  "ss_course_manager_start_date": "Ngày bắt đầu",
  "ss_course_manager_status_course_complete": "Hoàn thành",
  "ss_course_manager_status_course_learing": "Đang học",
  "ss_course_manager_status_course_not_learn": "Chưa vào học",
  "ss_course_manager_title_generality": "Tổng quát",
  "ss_course_manager_title_list": "Danh sách",
  "ss_course_preview_button": "Preview",
  "ss_customer_donation_name": "Donation",
  "ss_customer_list_empty": "No Customers yet",
  "ss_customer_order_success": "Success",
  "ss_customer_placeholder_search": "Username, Email, Phone number",
  "ss_customer_price_free": "Free",
  "ss_customer_title": "Customer List",
  "ss_customer_title_detail_customer": "Customer Information",
  "ss_customer_title_list_purchase": "Purchased product",
  "ss_challenge": "Challenge",
  "ss_click_to_detail_note": "Let's choose one of the following tow products 👇👇👇",
  "ss_close": "Got it",
  "ss_content_success_TOD": "You have successfully installed Truth or Dare feature.",
  "ss_count_gift_giving": "Gift Giving",
  "ss_digital_product": "Digital Product",
  "ss_discover_now": "Start Now",
  "ss_donation_answer": "Learn more about Donation",
  "ss_donation_content_helper_1": "To increase income, all creators can use the Donation feature to call on fans and viewers to donate directly to them with a specific amount of money!\nDonation calls can be placed at the end of the videos/posts.",
  "ss_donation_content_helper_2": "Creators can set the minimum amount for donations that their fans can make.\nTheir fans can voluntarily donate to them as long as the donation is not lower than the minimum amount set by creators.",
  "ss_donation_content_helper_3": "Passio frees all the platform fees for creators to use the Donation feature.\nCreators only have to pay 5% of the donation for the payment gateway fee and keep all the remaining 95%",
  "ss_donation_content_intro_1": "Donation is a creator-support feature, which calls on fans and viewers to donate directly to their favorite creators. With a certain amount of money donated, fans and viewers can encourage creators to produce more high-quality content!",
  "ss_donation_content_intro_2": "All fan donations will be converted to cash so that creators can earn more & optimize the benefits they could gain from their personal branding or their content. \nEspecially, creators can receive donations anywhere & anytime, even while sleeping!",
  "ss_donation_currency_shortcut": "₱",
  "ss_donation_edit": "Edit",
  "ss_donation_establish": "Start setting up Donation",
  "ss_donation_home_title": "Donation",
  "ss_donation_intro_button_submit": "Start Now",
  "ss_donation_intro_button_title_default": "Make a donation to {{name}}",
  "ss_donation_intro_invitation_default": "How much do you love {{name}}?",
  "ss_donation_intro_message_default": "Thank you for your donation to {{name}}. This would contribute a lot to the improvement of my upcoming content.",
  "ss_donation_learn_service": "About Donation",
  "ss_donation_list_request_title": "Donation list",
  "ss_donation_setting_btn_continue": "Continue",
  "ss_donation_setting_btn_donation_title": "Invitation Settings",
  "ss_donation_setting_btn_title_placeholder": "Donate now",
  "ss_donation_setting_btn_title_tooltip": "Input a maximum of 40 characters",
  "ss_donation_setting_cover_image": "Cover image",
  "ss_edit_voucher_success": "Modify successful discount codes",
  "ss_exclusive_passio_badge": "Exclusive",
  "ss_exit_add_warning": "Your changes will not be saved. Are you sure you want to escape?",
  "ss_intro_plaform_fee": "Platform fee:",
  "ss_intro_plaform_fee_free": "Free",
  "ss_intro_plaform_fee_from": "from",
  "ss_intro_plaform_fee_to": "to",
  "ss_label_end_date_code": "To date",
  "ss_label_name_voucher": "Discount name",
  "ss_label_quantity": "Usage",
  "ss_label_quantity_left": "The remaining number",
  "ss_label_quantity_used": "Number of use",
  "ss_label_start_date_code": "Since",
  "ss_label_voucher_amount_discount": "The amount decreases",
  "ss_label_voucher_code": "Discount code",
  "ss_label_voucher_date": "Expiry date",
  "ss_label_voucher_max_discount": "Maximum reduction",
  "ss_label_voucher_percent_discount": "Percentage reduction",
  "ss_label_voucher_quantity": "Number of use",
  "ss_label_voucher_type": "Type discount",
  "ss_list_empty_content": "To add, please press the \"Create product\" button on the top",
  "ss_list_ticket_account": "Ticket/Account List",
  "ss_message_default_donation": "Thank you for your donation to {{name}}. This would contribute a lot to the improvement of my upcoming content.",
  "ss_modal_preview_button_back": "Return",
  "ss_modal_preview_button_donation": "Donate",
  "ss_modal_preview_button_submit": "Publish",
  "ss_modal_preview_message_note_placeholder": "Send your cute messages to the creator ✍🏻",
  "ss_modal_preview_submit_success": "You have successfully enabled Donation feature",
  "ss_more_about_virtual_gift": "Learn more about Virtual Gift",
  "ss_no": "No",
  "ss_no_data": "No data",
  "ss_no_data_text": "Establish services below to get more orders",
  "ss_off": "Disable",
  "ss_on": "Enable",
  "ss_order_code": "Order code",
  "ss_order_date": "Order time",
  "ss_photo_title_create": "Add Product",
  "ss_placeholder_input_code_voucher": "PASSIO123",
  "ss_placeholder_input_max_ammount_voucher": "Enter the price",
  "ss_placeholder_input_name_voucher": "Sale 12/12",
  "ss_placeholder_input_percent_voucher": "Enter the percentage",
  "ss_placeholder_input_price_ammount_voucher": "Enter the price",
  "ss_placeholder_input_quantity_voucher": "Enter the number of times",
  "ss_product_account_required": "Please upload the list",
  "ss_product_list_empty_title": "No product",
  "ss_report_store_order_commission_value": "Is the amount of commission shared for the Creator to support the sale of this order",
  "ss_product_name": "Product name",
  "ss_product_success_off_donation": "You have disabled the Donation feature.",
  "ss_product_success_off_virtual_gift": "You have disabled the Gift feature.",
  "ss_product_success_on_donation": "You have successfully enabled the Donation feature.",
  "ss_product_success_on_virtual_gift": "You have successfully enabled the Gift feature.",
  "ss_push_TOD": "Publish",
  "ss_report_conversion_rate_brand_gift": "Conversion rate",
  "ss_report_total_order_brand_gift": "Orders",
  "ss_report_total_order_income_brand_gift": "Number of brands with orders",
  "ss_report_total_order_income_tooltip_brand_gift": "Number of brands generated at least 1 order",
  "ss_report_total_order_tooltip_brand_gift": "Orders by fan",
  "ss_report_total_view_tooltip_brand_gift": "Fan visits to Brand Gift",
  "ss_report_vg_income_tooltip": "Gift revenue you receive",
  "ss_report_vg_order_tooltip": "Gift orders you receive",
  "ss_report_vg_show_list_all_orders": "List of orders",
  "ss_response_customer": "Send your response to this gift giver",
  "ss_save": "Save",
  "ss_sell_photo_btn_save": "Save",
  "ss_sell_photo_btn_selectd": "Select",
  "ss_sell_photo_button_cancel_upload": "Cancel",
  "ss_sell_photo_cancel_add": "Cancel",
  "ss_sell_photo_cancel_add_warning": "Close",
  "ss_sell_photo_cancel_btn_delete": "Cancel",
  "ss_sell_photo_confirm_btn_delete": "Confirm Deletion",
  "ss_sell_photo_confirm_btn_warning_cancel": "Close",
  "ss_sell_photo_continute_add": "Confirmation",
  "ss_sell_photo_cover_photo_upload": "Cover Image",
  "ss_sell_photo_description_delete": "Images will be permanently deleted from the library.",
  "ss_sell_photo_description_label": "Description",
  "ss_sell_photo_description_waring_cancel": "Are you sure you want to cancel?",
  "ss_sell_photo_empty_description": "To add product images, please press the \"Upload Image\" button above.",
  "ss_sell_photo_empty_title": "No product images yet.",
  "ss_sell_photo_error_description_required": "Please provide a product description",
  "ss_sell_photo_error_name_required": "Please enter the product name",
  "ss_sell_photo_error_original_price_required": "Please enter the original product price.",
  "ss_sell_photo_exit_add_text": "Changes have not been saved.",
  "ss_sell_photo_exit_add_warning": "Are you sure you want to cancel?",
  "ss_sell_photo_exit_upload_text": "Do you want to cancel ?",
  "ss_sell_photo_exit_upload_warning": "All data will not be saved",
  "ss_sell_photo_image_is_processing": "Images are being processed, please wait for a moment.",
  "ss_sell_photo_intro_photo_upload": "Introduction Images (Up to 4 images)",
  "ss_sell_photo_label_image_selected": "Selected Image(s)",
  "ss_sell_photo_list_image_title_popup": "Image List",
  "ss_sell_photo_max_photo": "Please upload up to 50 images per product.",
  "ss_sell_photo_max_size_upload_photo": "Please upload images with a maximum size of 20MB.",
  "ss_sell_photo_message_label": "Your Message",
  "ss_sell_photo_name_photo": "Product Name",
  "ss_sell_photo_placeholder_input_description": "Enter content",
  "ss_sell_photo_placeholder_input_message": "Please enter any content you want exclusively for fans here (1 secret link for customers, file unlock password, Zoom access password, etc.).",
  "ss_sell_photo_placeholder_input_name_photo": "Enter content",
  "ss_sell_photo_placeholder_input_price": "Enter the amount",
  "ss_sell_photo_price_discount_label": "Promotional Price",
  "ss_sell_photo_price_label": "Price",
  "ss_sell_photo_price_original_label": "Original Price",
  "ss_sell_photo_required_photo": "Please upload product images",
  "ss_sell_photo_required_photo_cover": "Please upload a cover image",
  "ss_sell_photo_required_photo_intro": "Please upload introduction images",
  "ss_sell_photo_text_button_list_image": "Image List",
  "ss_sell_photo_text_button_uncheck": "Cancel",
  "ss_sell_photo_text_button_upload_image": "Upload Image",
  "ss_sell_photo_text_des_image_size": "Maximum file size: 20MB/image",
  "ss_sell_photo_text_des_image_type": "Supported formats: jpg, jpeg, png, bmp",
  "ss_sell_photo_title_delete": "Delete Image",
  "ss_sell_photo_title_list": "List",
  "ss_sell_photo_title_photo": "Product Images",
  "ss_sell_photo_title_waring_cancel": "Changes have not been saved.",
  "ss_sell_photo_type_price_charge": "Paid",
  "ss_sell_photo_type_price_free": "Free",
  "ss_sell_photo_uploading_text": "Processing",
  "ss_service_introduction": "Service introduction",
  "ss_service_intruct": "Instructions for setup",
  "ss_service_make_money": "Earn money from Virtual Gift",
  "ss_service_make_money_text": "The more gifts you receive from supporters, the more money you can earn!\nEspecially, you can receive virtual gifts anywhere & anytime, even while you’re sleeping!",
  "ss_setting_TOD_background_tooltip": "Select wallpaper in 3:2 aspect ratio and maximum size of 4 MB",
  "ss_setting_TOD_field_image_background": "Add wallpaper",
  "ss_setting_TOD_field_image_background_bottom": "Background image",
  "ss_setting_TOD_field_invitation_tooltip": "Character limit is 60.",
  "ss_setting_TOD_field_welcome_tooltip": "The invitation will be filled automatically if you leave it blank",
  "ss_setting_TOD_title": "Settings",
  "ss_setting_btn_donation_title": "Invitation Settings",
  "ss_setting_btn_donation_title_placeholder": "Ủng hộ ngay",
  "ss_setting_btn_donation_title_tooltip": "Input a maximum of 40 characters",
  "ss_setting_donation_field_image_background": "Background",
  "ss_setting_donation_field_image_background_tooltip": "Select background photo in 3:2 aspect ratio and maximum size of 4 MB",
  "ss_setting_donation_field_image_cover": "Cover photo",
  "ss_setting_donation_field_max_price": "The maximum donation amount",
  "ss_setting_donation_field_max_price_placeholder": "The maximum donation amount received from fans",
  "ss_setting_donation_field_max_price_suggest": "The maximum donation amount cannot be less than the minimum donation amount and more than",
  "ss_setting_donation_field_message_thank_you": "Send thanks to your fans",
  "ss_setting_donation_field_message_thank_you_placeholder": "You need to send thanks to your fans",
  "ss_setting_donation_field_message_thank_you_placeholder_focus_after": ". I will keep creating interesting content.",
  "ss_setting_donation_field_message_thank_you_placeholder_focus_before": "Thanks for your support",
  "ss_setting_donation_field_message_thank_you_tooltip": "300 characters maximum",
  "ss_setting_donation_field_price": "Minimum amount received",
  "ss_setting_donation_field_price_placeholder": "The minimum donation amount",
  "ss_setting_donation_field_price_suggest": "The minimum donation amount cannot be less than ₱4.8 and more than ₱23,892,323",
  "ss_setting_donation_field_price_suggest1": "The minimum donation amount cannot be less than",
  "ss_setting_donation_field_price_suggest2": "and more than",
  "ss_setting_donation_field_welcome": "Call to action",
  "ss_setting_donation_field_welcome_placeholder": "Enter your call to action",
  "ss_setting_donation_field_welcome_tooltip": "Character limit is 60.",
  "ss_setting_donation_title": "Donation settings",
  "ss_showing": "Showing",
  "ss_store_add_new": "Add new",
  "ss_store_add_new_course_section": "Add new post",
  "ss_store_author_name_place_holder": "Enter the author's name",
  "ss_store_author_name_title": "The writer's name",
  "ss_store_commission_value": "Commission value",
  "ss_store_confirm_change_donation": "Enable the Donation feature and disable the Gift feature. Are you sure?",
  "ss_store_confirm_off_donation": "Do you want to disable the Donation feature?",
  "ss_store_confirm_off_virtual_gift": "Do you want to disable the Gift feature?",
  "ss_store_course_content_group_title": "Lesson",
  "ss_store_course_description_label": "Short description of the course",
  "ss_store_course_document_title": "Study document",
  "ss_store_course_faq_group_title": "Frequently asked questions",
  "ss_store_course_faq_title": "Frequently asked questions",
  "ss_store_course_info_bar_group_title": "Course Setup",
  "ss_store_course_info_bar_title": "Course Setup",
  "ss_store_course_original_price_title": "Original price course",
  "ss_store_course_price": "Course price",
  "ss_store_course_price_group_title": "Course price",
  "ss_store_course_price_title": "Price",
  "ss_store_course_section_data_error": "Projected or invalid. Please check again",
  "ss_store_course_section_title": "Create lectures",
  "ss_store_course_sell_price_title": "Price",
  "ss_store_course_thumbnail_label": "Course image",
  "ss_store_course_title_label": "Course title",
  "ss_store_course_value_description": "Clarify the value that students will achieve here will help learners quickly understand the value of the course and the appropriate to the course themselves.",
  "ss_store_course_value_title": "The value of students received",
  "ss_store_course_video_warn": "Video has a minimum resolution of 720p and maximum size of 1GB",
  "ss_store_create_course_material": "Create learning materials",
  "ss_store_create_course_section": "Create lectures",
  "ss_store_create_digital_course": "Create a course",
  "ss_store_data_required": "Please fill in information",
  "ss_store_default_answer_1": "The course is suitable for Newbie",
  "ss_store_default_answer_2": "You can study this course forever, anywhere",
  "ss_store_default_answer_3": "Have. There will be a separate group for you to exchange with lecturers.",
  "ss_store_default_question_1": "Who is this course suitable for?",
  "ss_store_default_question_2": "How long can I study this course?",
  "ss_store_default_question_3": "When I have questions, can I talk to the lecturer?",
  "ss_store_delete_file": "Delete video",
  "ss_store_demo_video_title": "Course promotional video",
  "ss_store_discount_price": "Discount price",
  "ss_store_edit_digital_course": "Course editing",
  "ss_store_error_save_course_section": "Update failure, please try again later.",
  "ss_store_error_update_document": "Update failure, please try again later.",
  "ss_store_faq_answer": "Reply",
  "ss_store_faq_answer_max_length": "Please enter maximum {{n}} characters for the answer",
  "ss_store_faq_field_require": "Please enter the full value",
  "ss_store_faq_question": "Question",
  "ss_store_faq_question_max_length": "Please enter maximum {{n}} characters for questions",
  "ss_store_field_required": "This school is required",
  "ss_store_item_inprogress": "Please complete information",
  "ss_store_max_character": "Please enter the most {{n}} character",
  "ss_store_max_value": "The maximum value is {{n}}",
  "ss_store_min_value_or_zero": "The value is 0 or greater than or equal to 1000",
  "ss_store_momo_wallet": "Momo Wallet - Electronic wallet",
  "ss_store_not_enough_course_resource": "You need to enter enough information to display the course",
  "ss_store_order_buyer_message": "Buyer's message",
  "ss_store_order_income": "Revenue",
  "ss_store_order_payment_fee": "Payment Fee",
  "ss_store_order_payment_fee_tooltip": "Payment Fee",
  "ss_store_order_platform_fee": "Platform Fee",
  "ss_store_order_platform_fee_tooltip": "Platform Fee",
  "ss_store_order_revenue": "Income",
  "ss_store_order_revenue_tooltip": "Revenue",
  "ss_store_origin_larger_than_price": "The original price must be higher than or equal to the price of the product",
  "ss_store_original_price": "Original price",
  "ss_store_quit_page_content": "Do you want to escape?",
  "ss_store_quit_page_title": "Data will not be saved.",
  "ss_store_received_donation": "Receiving donations",
  "ss_store_received_donation_message": "When enabled, you would allow fans to donate. Try this interesting feature!",
  "ss_store_received_virtual_gift": "Receiving Gifts",
  "ss_store_received_virtual_gift_message": "Enable the Gift feature and disable the Donation feature. Are you sure?",
  "ss_store_replace_file": "Change video",
  "ss_store_save_course_error": "Save the course unsuccessful, please try again or contact technical support",
  "ss_store_save_course_success": "Save the successful course",
  "ss_store_save_error": "Update failure, please try again later.",
  "ss_store_save_questions_error": "Update failure, please try again later.",
  "ss_store_save_questions_successfull": "Update successful",
  "ss_store_save_successfull": "Update successful",
  "ss_store_section_default_title": "Lesson 1: Introduction",
  "ss_store_section_group_default_title": "Part 1: Introduction",
  "ss_store_setup_the_course": "Course Setup",
  "ss_store_teacher_info_title": "Lecturer information",
  "ss_store_thumbnails_description": "Pictures with maximum size 30MB",
  "ss_store_update_document_successfully": "Update successful",
  "ss_store_update_section_successfully": "Update successful",
  "ss_store_upload_avatar": "Upload to avatar",
  "ss_store_upload_fail_message": "Upload failed",
  "ss_store_upload_video": "Video",
  "ss_store_use_text": "Document",
  "ss_store_validate_url": "The URL is incorrect. You need to enter the YouTube video URL",
  "ss_store_video_description": "Did you know the course advertising video helps increase the rate of students registering 5 times? Create and upload your video ”",
  "ss_store_youtube_demo_video_url_place_holder": "Please enter your Youtube URL advertising your course",
  "ss_success_label": "Success",
  "ss_time_draw_content_box_1": "Successful order creation date",
  "ss_time_draw_content_box_2": "Day D+7",
  "ss_time_draw_content_box_3": "Day D",
  "ss_time_draw_content_box_4": "After 7 days from the successful order creation date, commission will be added to the available balance",
  "ss_time_picker": "Time",
  "ss_title_time_draw": "Withdrawal Time",
  "ss_tod_reply_add_video_image_after": "video",
  "ss_tod_reply_add_video_image_before": "Add photos/",
  "ss_tod_reply_helper_add_video": "To take the challenge, you need to upload at least 1 video",
  "ss_tod_reply_img_incorrect_type": "Please choose files in the following formats JPG, JPEG, GIF, PNG, BMP, Webp, HEIF, HEIC",
  "ss_tod_reply_input_alias": "Reply content",
  "ss_tod_reply_input_placeholder": "Give your fans an honest answer!",
  "ss_tod_reply_placeholder_content_dare": "Give a brief introduction of your challenge execution here!",
  "ss_tod_reply_reply_submit": "Submit answer",
  "ss_tod_reply_reply_take_challenge": "Take the challenge",
  "ss_tod_reply_tooltip_max_size_video": "Maximum file size for video is 10GB",
  "ss_tod_reply_tooltip_max_size_video_image": "Maximum file size for image is 4MB, for video is 10GB",
  "ss_tod_reply_video_img_incorrect_size": "Image or video exceeded the file size limit.",
  "ss_tod_reply_video_incorrect_size": "Video exceeds size limit",
  "ss_tod_reply_video_incorrect_type": "Please select a file in MP4 or MOV format",
  "ss_tod_reply_wait_upload_video": "Your Video/Photo is being processed. Please wait.",
  "ss_tod_report_detail": "en_Chi tiết báo cáo",
  "ss_tod_response": "How to Response",
  "ss_tod_setting_background": "Wallpaper",
  "ss_tod_setting_background_tooltip": "Select wallpaper in 3:2 aspect ratio and maximum size of 4 MB",
  "ss_tod_setting_button_confirm_exit": "Exit",
  "ss_tod_setting_content_success": "You have successfully installed Truth or Dare feature.",
  "ss_tod_setting_dare_title": "Creator responds with at least 1 video",
  "ss_tod_setting_exit": "Exit",
  "ss_tod_setting_field_invitation_tooltip": "Character limit is 60.",
  "ss_tod_setting_field_welcome_tooltip": "The invitation will be filled automatically if you leave it blank",
  "ss_tod_setting_helper_service_1": "The Creator has 15 days maximum to respond to your challenge.",
  "ss_tod_setting_helper_service_2": "If you do not receive response for your challenge/question. The donation amount will be refunded to you within 30 days",
  "ss_tod_setting_invitation": "Challenge invitation description",
  "ss_tod_setting_invitation_placeholder": "Enter the content invite fans to join",
  "ss_tod_setting_message_success": "You have successfully installed Truth or Dare feature.",
  "ss_tod_setting_min_price_dare_alias": "The minimum amount fans pay for a Dare",
  "ss_tod_setting_min_price_dare_example": "Example: 100,000",
  "ss_tod_setting_min_price_dare_note_v2": "Minimum payment is {{price}}",
  "ss_tod_setting_preview": "Continue",
  "ss_tod_setting_push": "Publish",
  "ss_tod_setting_success_TOD": "Successful",
  "ss_tod_setting_text_edit": "Edit",
  "ss_tod_setting_title_service_1": "Time of respond",
  "ss_tod_setting_title_service_2": "Refund time",
  "ss_tod_setting_tooltip_min_price_dare": "If you do not enter it, the system will automatically fill this field with the default value",
  "ss_tod_setting_truth_title": "Creator responds with text",
  "ss_tod_setting_txt_btn": "Challenge invitation",
  "ss_tod_setting_warning_text": "Your settings have not been saved. Do you still want to exit?",
  "ss_tod_setup": "Set up now",
  "ss_tod_start": "Set up Truth or Dare",
  "ss_tod_status_alias_new": "New",
  "ss_tod_status_alias_pending": "Pending",
  "ss_tod_the_number_of_times_fans_senttod": "en_Số lượt Fan gửi Truth or Dare",
  "ss_tod_title_guide_1": "Reponse time",
  "ss_tod_title_guide_2": "Your rights to choose",
  "ss_tod_title_guide_3": "How to Response",
  "ss_tod_title_intro_1": "Response time",
  "ss_tod_title_intro_2": "Rights to choose",
  "ss_tod_title_intro_3": "How to Response",
  "ss_tod_tod_no": "en_Số TOD",
  "ss_tod_tooltip_min_price_dare": "If you do not enter it, the system will automatically fill this field with the default value",
  "ss_tod_truth": "Truth",
  "ss_tod_truth_title": "Creators reply by message",
  "ss_tod_view": "en_Lượt xem",
  "ss_total_products_sold": "The total number of sold products",
  "ss_txt_btn_TOD": "Challenge invitation",
  "ss_upload_file": "Upload",
  "ss_validate_image_post_max_size": "Image exceeds size limit",
  "ss_video_processing_error": "There was an error during processing, please try again",
  "ss_video_processing_text": "Video is being processed. This process may take some time",
  "ss_virtual_gift_home_order_revenue": "Estimated earnings from orders after deducting fees",
  "ss_virtual_gift_home_payment_des": "Passio is integrated with many domestic payment gateways as well as various international payment portals so that fans from all over the world can pay to buy gifts for their favorite creators.",
  "ss_virtual_gift_home_payment_title": "How can supporters pay for Virtual gifts?",
  "ss_virtual_gift_home_refund_des": "Creators can keep 85% of the gift value through Virtual Gift Feature! \nThe remaining 15% includes 10% for the Passio's platform fee and 5% for the payment gateways.",
  "ss_virtual_gift_home_refund_title": "How much percent of the money can creators receive from fan gifts?",
  "ss_virtual_gift_intro_report": "Virtual Gift",
  "ss_virtual_gift_intro_service_feature": "Introduction to Virtual Gift",
  "ss_virtual_gift_intro_service_feature_text": "Virtual Gift is a new feature from Passio. Virtual Gift enables fans to send their favorite creators presents in the form of thoughtful messages along with online gifts that can be exchanged for cash in various amounts.",
  "ss_virtual_gift_intro_service_make_money": "Earn money from Virtual Gift",
  "ss_virtual_gift_intro_service_make_money_text": "The more gifts you receive from supporters, the more money you can earn!\nEspecially, you can receive virtual gifts anywhere & anytime, even while you’re sleeping!",
  "ss_virtual_gift_report_dont_have_gift": "There are no gifts during this time",
  "ss_virtual_gift_report_message_from_seller": "The message you sent",
  "ss_virtual_gift_report_message_response_failed": "Response fails to send",
  "ss_virtual_gift_report_message_response_successful": "Response sent successfully",
  "ss_virtual_gift_report_method_payment_atm": "ATM card - Money transfer {{param}} bank",
  "ss_virtual_gift_report_method_payment_credit_card": "Credit card/ {{param}} international debt",
  "ss_virtual_gift_report_overview": "Overview",
  "ss_virtual_gift_report_payment_history": "Payment history",
  "ss_virtual_gift_report_payment_method": "Payment method",
  "ss_virtual_gift_report_phone_number": "Phone number",
  "ss_virtual_gift_report_ss_showing": "Showing",
  "ss_virtual_gift_report_store_momo_wallet": "Momo Wallet - Electronic wallet",
  "ss_virtual_gift_report_store_order_buyer_message": "Buyer's message",
  "ss_virtual_gift_report_store_order_giver_message": "The giver sends you",
  "ss_virtual_gift_report_store_order_payment_fee_tooltip": "Payment Fee",
  "ss_virtual_gift_report_store_order_platform_fee_tooltip": "Platform Fee",
  "ss_virtual_gift_report_store_order_revenue_tooltip": "Revenue",
  "ss_virtual_gift_report_store_order_total_income": "Revenue = Original price / Discount price - Discount amount (if any)",
  "ss_virtual_gift_report_vg_buyer": "Giver",
  "ss_virtual_gift_report_vg_error_upload": "Opps! Error to download. Please try to refresh the page!",
  "ss_virtual_gift_report_vg_product": "Gift",
  "ss_virtual_gift_report_vg_time_buy": "Gift time",
  "ss_voucher_add_button_label": "Add",
  "ss_voucher_add_product_btn": "More",
  "ss_voucher_add_product_warning_cancel": "Escape",
  "ss_voucher_add_product_warning_confirm": "Continue to add products",
  "ss_voucher_btn_add": "Save",
  "ss_voucher_cancel_add": "Cancel",
  "ss_voucher_cancel_add_product_btn": "Cancel",
  "ss_voucher_cancel_add_warning": "Escape",
  "ss_voucher_continute_add": "Continue to add new",
  "ss_voucher_copy_error": "Copy the failed code",
  "ss_voucher_copy_success": "Copy the code successfully",
  "ss_voucher_creator": "Creator discount code",
  "ss_voucher_datepicker_title": "Day",
  "ss_voucher_error_code_required": "Please enter the name of the discount code",
  "ss_voucher_error_end_date_soon_current_date": "The end time must be later than the starting time",
  "ss_voucher_error_input_discount_amount_required": "Please enter the discount",
  "ss_voucher_error_input_percent_discount_required": "Please enter the discount",
  "ss_voucher_error_name_existed": "The discount code has existed",
  "ss_voucher_error_name_min_length": "Please enter at least {{min}} characters",
  "ss_voucher_error_name_required": "Please enter the discount code",
  "ss_voucher_error_quantity_less_than_current": "Unable to decrease the number of uses of currently active discount code",
  "ss_voucher_error_quantity_required": "Please enter the number of uses for discount code",
  "ss_voucher_error_start_date_soon_current_date": "The starting time must be later than the present time",
  "ss_voucher_exit_add": "Cancel ?",
  "ss_voucher_exit_deleting_warning": "Your changes will not be saved. Are you sure you want to escape?",
  "ss_voucher_label_appled": "Selected",
  "ss_voucher_label_apply_all": "Apply all products",
  "ss_voucher_label_date": "Expired:",
  "ss_voucher_label_left": "used",
  "ss_voucher_label_total": "Total",
  "ss_voucher_list_apply": "Products application",
  "ss_voucher_no_quantity": "No Limit",
  "ss_voucher_passio": "Passio discount code",
  "ss_voucher_placeholder_input_search": "Product's name",
  "ss_voucher_search_button_label": "Search",
  "ss_voucher_timepicker_title": "Time",
  "ss_voucher_title_select_product": "Add product",
  "ss_voucher_turn_off_cancle_text": "Cancel",
  "ss_voucher_turn_off_confirm_cancel": "End",
  "ss_voucher_turn_off_warning_content": "Are you sure to end this discount code? The action cannot be done",
  "ss_voucher_turn_off_warning_title": "End of discount",
  "ss_voucher_type_money": "According to the money",
  "ss_voucher_type_money_label": "According to the price",
  "ss_voucher_type_percent": "Percentage",
  "ss_voucher_type_percent_label": "Percentage",
  "ss_voucher_warning_edit": "Cancell product?",
  "start_date_must_be_less_than_end_date": "Start date must be less than end date",
  "start_making_money": "Start monetizing",
  "status": "Status",
  "store_order_giver_message": "The giver sends you",
  "store_order_total_income": "Is the revenue of the order",
  "sub1_empty_data": "No data",
  "sub_account": "Sub account",
  "system_error": "System error",
  "tab_list_voucher_all": "All",
  "tab_list_voucher_end": "End",
  "tab_list_voucher_running": "Happenning",
  "tab_list_voucher_upcoming": "Upcoming",
  "text_custom_range": "Custom Range",
  "text_last_month": "Last month",
  "text_last_n_day": "{{n}} most recent days",
  "text_last_week": "Last week",
  "text_this_month": "This month",
  "text_this_quarter": "This quarter",
  "text_this_week": "This week",
  "text_today": "Today",
  "text_yesterday": "Yesterday",
  "ticket_cancel": "Cancel",
  "ticket_choose_file": "Select file",
  "ticket_choose_file_template": "Choose the template file that's right for you",
  "ticket_confirm": "Confirm",
  "ticket_delete_row": "Delete records?",
  "ticket_delete_row_content": "Delete the record here if you have used/sold it elsewhere",
  "ticket_delete_row_success": "Successfully deleted record",
  "ticket_download_template": "Download template file",
  "ticket_error_first_column": " Unable to upload file. Each row in the first column cannot be blank",
  "ticket_error_length_text": " Unable to upload file. Column name maximum 30 characters, Data field maximum 255 characters",
  "ticket_instruct_template": "Choose a template file suitable for the product you want to sell. The system supports from 1 column of data to 5 columns of data. For example: If you sell ChatGPT accounts, you will choose a 2-column file.",
  "ticket_list_template": "List of template files",
  "ticket_list_ticket_account_note": "Click “upload” to download the list of products you want to sell.",
  "ticket_no_file_select": "No files have been selected",
  "ticket_product_account_max_size_upload_file": "Select a file with a maximum capacity of 3MB",
  "ticket_product_account_type_upload_file": "Format allows xls, xlsx",
  "ticket_row": "Column",
  "ticket_support_file_upload": "Support: xls, xlsx, maximum: 3MB",
  "ticket_text_template": "File template",
  "ticket_text_upload_file": "Upload an xls or xlsx file to update the list",
  "ticket_upload": "Upload",
  "ticket_upload_list": "Upload list",
  "ticket_upload_success": "Upload list successfully",
  "tickets_upload_list": "Upload list",
  "time_picker": "Time",
  "tod_file_format_incorrect": "File format is incorrect",
  "tod_helper_add_video": "To take the challenge, you need to upload at least 1 video",
  "tod_img_incorrect_type": "Please choose files in the following formats JPG, JPEG, GIF, PNG, BMP, Webp, HEIF, HEIC",
  "tod_list_challenge_label": "List of challenges",
  "tod_msg_required_video": "A video is required to take this challenge",
  "tod_notification": "Click on the challenges to see details, and decide to accept or decline.",
  "tod_perform": "Take the Challenge",
  "tod_placeholder_content_dare": "Give a brief introduction of your challenge execution here!",
  "tod_private": "Private",
  "tod_private_text": "Set private",
  "tod_public_text": "Set public",
  "tod_reply": "Answer",
  "tod_reply_input_alias": "Reply content",
  "tod_reply_input_placeholder": "Give your fans an honest answer!",
  "tod_reply_submit": "Submit answer",
  "tod_reply_take_challenge": "Take the challenge",
  "tod_share_challenge": "Share challenge",
  "tod_share_link": "Share link",
  "tod_start_message": "Add to your Passio Page now to interactive with your fan and get more challenges!",
  "tod_status_alias_accepted": "Completed",
  "tod_status_alias_completed": "Completed",
  "tod_status_alias_expired": "Expired",
  "tod_status_alias_new": "New",
  "tod_status_alias_refund": "Refund",
  "tod_status_alias_wait": "Pending",
  "tod_status_private": "Private mode",
  "tod_status_private_des": "Your fans won't see the challenge when this mode is on",
  "tod_status_private_reject": "You declined the challenge",
  "tod_time_remain": "Time remaining",
  "tod_time_sending": "Time",
  "tod_tooltip_max_size_video": "Maximum file size for video is 10GB",
  "tod_tooltip_max_size_video_image": "Maximum file size for image is 4MB, for video is 10GB",
  "tod_video_format_incorrect": "Video format is incorrect",
  "tod_video_img_incorrect_size": "Image or video exceeded the file size limit.",
  "tod_video_incorrect_size": "Video exceeds size limit",
  "tod_video_incorrect_type": "Please select a file in MP4 or MOV format",
  "tod_video_over_size": "Video exceeds size limit",
  "tod_view_detail": "See details",
  "tod_wait_upload_video": "Your Video/Photo is being processed. Please wait.",
  "tod_warning_reject_tod": "You cannot take this challenge if you decline it.",
  "tooltip_list_tod": "Click on the challenges to see details, and decide to accept or decline.",
  "total": "Total",
  "total_commission": "Total commission",
  "total_donation": "Total Donations",
  "total_order_brand_gift": "Orders",
  "total_order_income_brand_gift": "en_Số brand có đơn",
  "total_order_income_tooltip_brand_gift": "en_Số brand phát sinh tối thiểu 1 đơn",
  "total_order_tod": "Truth or Dare",
  "total_order_tooltip_brand_gift": "Orders by fan",
  "total_search_report_by_subs": "There were {{number}} results found",
  "total_view_brand_gift": "Views",
  "total_view_tooltip_brand_gift": "Fan visits to Brand Gift",
  "traffic_link_is_duplicated": "en_Liên kết bị trùng lặp",
  "traffic_link_is_empty": "en_Nền tảng không có kênh",
  "truth_or_dare": "Truth or dare",
  "truth_or_dare_detail": "Challenge details",
  "try_upload_again": "Retry",
  "tt_estimate_approved_commission": "Calculated based on estimated approved rate of each campaign",
  "undefined": null,
  "unhide_product": "Unhide",
  "unhide_product_fail": "Fail to unhide products",
  "unhide_product_success": "Unhide product successfully",
  "unique_click_tooltip": "Number of visitors to your affilate link from any devices per day",
  "unique_clicks": "Unique Click",
  "update_referral_error": "Update the introduction code failure",
  "upload_fail": "Fail in uploading",
  "upload_file_exceed_limit": "Please choose a photo smaller than 8000x8000 px",
  "upload_failed": "Upload failure",
  "upload_product": "Upload product",
  "uploading": "Uploading ...",
  "user_guide": "Guidline",
  "user_information": "account information",
  "uv_commission": "UV Commission",
  "vg_buyer": "Giver",
  "vg_error_upload": "Opps! Error to download. Please try to refresh the page!",
  "vg_income_tooltip": "Gift revenue you receive",
  "vg_order_tooltip": "Gift orders you receive",
  "vg_product": "Gift",
  "vg_show_list_all_orders": "List of orders",
  "vg_time_buy": "Gift time",
  "video_shoutout": "Video Shoutout",
  "voucher_create_new_button": "Create voucher",
  "voucher_list": "List",
  "voucher_list_title": "Create discount codes",
  "voucher_title": "Discount code",
  "yes": "Yes"
} 
} 
export default en