import { bindActionCreators, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { ProductType } from "./models/type";

type DigitalProductStateType = {
  uploadType: number,
  isUploading: boolean,
  mediaType: number,
  mimeType: string,
  loadingDigitalProduct: boolean,
  fromDigitalProduct: string,
  iframeData: any,
  dataIframeChange: any,
  isIframe: boolean,
  isItemsRemove: any,
  isItemsEdit: any,
  isUploadingCourses: boolean,
  isEditingOtherCourse: boolean,
  listVideoProcess: number[],
  isSetting: boolean,
  actionCloseIframe: boolean,
  dataNew: any,
}

const initialState: DigitalProductStateType = {
  uploadType: null,
  isUploading: false,
  mediaType: null,
  mimeType: null,
  loadingDigitalProduct: false,
  fromDigitalProduct: null,
  iframeData: [],
  dataIframeChange: [],
  isIframe: false,
  isItemsRemove: [],
  isItemsEdit: [],
  isUploadingCourses: false,
  isEditingOtherCourse: false,
  listVideoProcess: [],
  isSetting: false,
  actionCloseIframe: false,
  dataNew: null
}

const digitalProductSlice = createSlice({
  name: 'digitalProduct',
  initialState,
  reducers: {
    setUploadType: (state: Draft<DigitalProductStateType>, action: PayloadAction<number>) => {
      state.uploadType = action.payload
    },
    setIsUploading: (state: Draft<DigitalProductStateType>, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload
    },
    setMediaType: (state: Draft<DigitalProductStateType>, action: PayloadAction<number>) => {
      state.mediaType = action.payload
    },
    setMimeType: (state: Draft<DigitalProductStateType>, action: PayloadAction<string>) => {
      state.mimeType = action.payload
    },
    setLoadingDigitalProduct: (state: Draft<DigitalProductStateType>, action: PayloadAction<boolean>) => {
      state.loadingDigitalProduct = action.payload
    },
    setFromDigitalProduct: (state: Draft<DigitalProductStateType>, action: PayloadAction<string>) => {
      state.fromDigitalProduct = action.payload
    },
    setIframeData: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.iframeData = action.payload
    },
    setDataIframeChange: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.dataIframeChange = action.payload
    },
    setIsIframe: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.isIframe = action.payload
    },
    setIsItemsRemove: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.isItemsRemove = action.payload
    },
    setIsItemsEdit: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.isItemsEdit = action.payload
    },
    setIsEditingOtherCourse: (state: Draft<DigitalProductStateType>, action: PayloadAction<boolean>) => {
      state.isEditingOtherCourse = action.payload
    },
    setIsUploadingCourses: (state: Draft<DigitalProductStateType>, action: PayloadAction<boolean>) => {
      state.isUploadingCourses = action.payload
    },
    setListVideoProcess: (state: Draft<DigitalProductStateType>, action: PayloadAction<number[]>) => {
      state.listVideoProcess = action.payload
    },
    setIsSetting: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.isSetting = action.payload
    },
    setActionCloseIframe: (state: Draft<DigitalProductStateType>, action: PayloadAction<boolean>) => {
      state.actionCloseIframe = action.payload
    },
    setDataNew: (state: Draft<DigitalProductStateType>, action: PayloadAction<any>) => {
      state.dataNew = action.payload
    },
  }
})

const digitalProductReducer = digitalProductSlice.reducer

export const digitalProductAction = digitalProductSlice.actions

export const { setLoadingDigitalProduct, setFromDigitalProduct } = digitalProductSlice.actions

export const useDigitalProductActions = () => {
  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(digitalProductAction, dispatch), [dispatch])
}

export default digitalProductReducer