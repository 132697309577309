import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import shareReducer from "@features/share/shareSlice";
import profileSlice from "@features/profile/profileSlice";
import digitalProductReducer from "@features/digital-product/digitalProductSlice";
import shoutoutReducer from "@features/shoutout/shoutoutSilce";
import reportReducer from "@features/report/reportSlice";

const sagaMiddleware = createSagaMiddleware();

export function makeStore() {
  return configureStore({
    reducer: {
      share: shareReducer,
      profile: profileSlice,
      digitalProduct: digitalProductReducer,
      shoutout: shoutoutReducer,
      report: reportReducer
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sagaMiddleware);
    },
  });
}

const store = makeStore();
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;

export default store;
