import {bindActionCreators, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {useDispatch} from 'react-redux'
import {useMemo} from 'react'
import moment, {Moment} from 'moment'
import { getDateRange } from '@features/share/helper/getDateRange';

export interface FilterSelectedInterface {
  campaign?: string
  sub_account?: string
  source?: string
  status?: string
}

interface ReportStateInterface {
  hasFetchGeneralReport: boolean
  isLoadingReport: boolean
  isResetFilter: boolean
  filterOpened: boolean
  filterSelected: FilterSelectedInterface
  date_range?: {
    value: [Moment, Moment],
    key: string
  },
}

const initialState: ReportStateInterface = {
  hasFetchGeneralReport: true,
  isLoadingReport: false,
  isResetFilter: false,
  filterOpened: false,
  filterSelected: {
    campaign: 'all',
    sub_account: 'all',
    source: 'all',
    status: 'all'
  },
  date_range: {
    key: 'this_month',
    value: [moment(getDateRange('this_month').startDate), moment(getDateRange('this_month').endDate)]
  }
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {

    setIsLoadingReport: (state: Draft<ReportStateInterface>, action: PayloadAction<boolean>) => {
      state.isLoadingReport = action.payload
    },
  }
})

const reportReducer = reportSlice.reducer

export const reportActions = reportSlice.actions

export const useReportActions = () => {
  const dispatch = useDispatch()
  return useMemo(() => bindActionCreators(reportActions, dispatch), [dispatch])
}

export default reportReducer
