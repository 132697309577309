export const DATE_FORMAT_DEFAULT = "YYYY-MM-DD";
export const DATE_FORMAT_VN_SLASH = "DD/MM/YYYY";
export const BEFORE_1_DAY = 1;
export const BEFORE_6_DAYS = 6;
export const BEFORE_30_DAYS = 30;

export const DateKeys = {
  KEY_TODAY: "today",
  KEY_YESTERDAY: "yesterday",
  KEY_THIS_WEEK: "this_week",
  KEY_LAST_WEEK: "last_week",
  KEY_THIS_MONTH: "this_month",
  KEY_LAST_7_DAYS: "last_7_day",
  KEY_LAST_30_DAYS: "last_30_day",
  KEY_CUSTOM_RANGE: "custom_range",
} as const;
export type DateTypes = keyof typeof DateKeys;
export type DateValues = typeof DateKeys[DateTypes];

export const FILTER_FROM_DATE = "from_date";
export const FILTER_TO_DATE = "to_date";
export const DEFAULT_MIN_DATE = "2018-01-01";

export const REACT_CALENDAR_NAME = "react_calendar";
export const DIFF_MONTH = 3;
